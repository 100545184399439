import { EElementType } from '@/services/lineage/type';
import { EOverviewType } from '@/typings/overview';
import { Event, Group, Image, Rect, runtime, Text } from '@aloudata/ink';
import { COL_Y_NUM, RECT_LINE_WIDTH } from '../../constants';
import Column from '../../model/Column';
import SQLGraph from '../../model/SQLGraph';
import Table from '../../model/Table';
import { ETableType, sideIconTypeObj } from '../../typings';
import {
  getActiveSqlIdsById,
  getEllipsisStyle,
  getIconType,
  getIconVisible,
  showExploreTipNoSql,
  showExploreTipNotInActiveElement,
} from '../../utils';
import {
  COL_ORDER_HEIGHT,
  COL_ORDER_R,
  COL_ORDER_TEXT_X,
  COL_ORDER_TEXT_Y,
  COL_ORDER_WIDTH,
  COL_ORDER_X,
  COL_TEXT_X,
  COL_TEXT_Y,
  COL_TYPE_ICON_Y,
} from './style';
import ActionIcon, {
  EExpandType,
  EIconPositionType,
} from '@/components/TaskLineageView/components/ActionIcon';
import Loading from '../../components/Loading';
import { vec3 } from 'gl-matrix';
import { IElement } from '../../model/Graph';
import pkIcon from '../../assets/icons/pk.svg';
import fkIcon from '../../assets/icons/fk.svg';

import { EGraphMode } from '../../atoms/overviewAtom';
import Edge from '../../model/Edge';
import { EDirection } from '@/typings';

interface IProps {
  parentNode: Group;
  data: Column;
  parentData: Table;
  num: number;
  context: SQLGraph;
  operatorConfig?: { index: number; lastIndex?: number };
}

class ColumnView {
  props: IProps;

  bgRect: Rect;

  hideRect: Rect;

  g: Group;

  children: (Image | Text | Rect)[];

  leftIcon: ActionIcon;

  rightIcon: ActionIcon;

  expandLoadingIcon: Loading;

  text: Text;

  constructor(props: IProps) {
    this.props = props;

    const { data, context } = this.props;

    data.bindView(this);
    context.viewGraph.originNodeMap.set(data.index, data);
    context.viewGraph.currentNodeMap.set(data.index, data);

    this.render();
  }

  render() {
    const { parentNode, data, num } = this.props;
    const { name, highlight } = data;
    const { position, constraint = '' } = data._data_;
    const [width, height] = data.getSize();

    this.g = new Group({
      name: 'column-group',
      style: {
        y: num > COL_Y_NUM ? num * (height - 1) : 0,
        cursor: 'default',
      },
    });

    (this.g as IElement).node = this;

    this.bgRect = new Rect({
      name: 'column-bgRect',
      style: {
        fill: '#fff',
        stroke: '#E8E8E8',
        lineWidth: RECT_LINE_WIDTH,
        width,
        height: height - 1,
      },
    });

    // 用于hover到sideIcon区域时也能显示sideIcon
    this.hideRect = new Rect({
      name: 'column-bgRect-hide',
      style: {
        x: -20,
        fill: '#0000',
        stroke: '#0000',
        lineWidth: RECT_LINE_WIDTH,
        width: width + 40,
        height: height - 1,
      },
    });

    const colOrder = new Rect({
      style: {
        fill: '#fff',
        stroke: '#EAECF0',
        lineWidth: RECT_LINE_WIDTH,
        width: COL_ORDER_WIDTH,
        height: COL_ORDER_HEIGHT,
        x: COL_ORDER_X,
        y: COL_TYPE_ICON_Y,
        radius: COL_ORDER_R,
      },
    });

    const colOrderText = new Text({
      style: {
        x: COL_ORDER_TEXT_X,
        y: COL_ORDER_TEXT_Y,
        text: String(position) || '-',
        fill: '#A2A2A2', // @NL0
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Tahoma',
      },
    });

    this.text = new Text({
      name: 'column-text',
      style: {
        x: COL_TEXT_X,
        y: COL_TEXT_Y,
        ...getEllipsisStyle(130),
        text: name,
        fill: '#171717',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'PingFang SC',
      },
    });

    this.g.appendChild(this.hideRect);
    this.g.appendChild(this.bgRect);
    this.g.appendChild(colOrderText);
    this.g.appendChild(this.text);

    // PK、FK
    if (['PK', 'FK'].includes(constraint)) {
      const columnType = new Image({
        style: {
          x: 172,
          y: 8,
          width: 12,
          height: 12,
          img: constraint === 'PK' ? pkIcon : fkIcon,
          cursor: 'pointer',
        },
      });
      this.g.appendChild(columnType);
    }

    this.leftIcon = new ActionIcon(this, EIconPositionType.LEFT);
    this.rightIcon = new ActionIcon(this, EIconPositionType.RIGHT);
    this.expandLoadingIcon = new Loading();

    this.g.appendChild(this.leftIcon.image);
    this.g.appendChild(this.rightIcon.image);
    this.g.appendChild(this.expandLoadingIcon.image);

    parentNode.appendChild(this.g);

    this.children = [this.bgRect, colOrder, colOrderText, this.text];
    if (!highlight || !this.props.parentData.isOpen) {
      this.hide();
    }

    if (this.props.parentData.isShowAll || data.isStartNode) {
      this.show();
    }
  }

  onClick(e: Event) {
    const { data, context } = this.props;
    const { index } = data;
    context.hideContextmenu();
    context.subViewGraph.clearRightClickActiveElements();

    // 探索模式下，点击表头不触发高亮，调用木子的接口，显示右侧的 sql
    if (this.props.context.exploreState.mode === EGraphMode.EXPLORE) {
      const { activeElements } = this.props.context.subViewGraph;
      if (activeElements.some((item) => item.index === index)) {
        const activeElementIds = [index];
        if (
          this.props.context.exploreState.exploreDirection === EDirection.INPUT
        ) {
          activeElements.forEach((activeItem) => {
            if (activeItem instanceof Edge) {
              const { srcId, dstId } = activeItem;
              if (dstId === index) {
                activeElementIds.push(srcId);
              }
            }
          });
        } else {
          activeElements.forEach((activeItem) => {
            if (activeItem instanceof Edge) {
              const { srcId, dstId } = activeItem;
              if (srcId === index) {
                activeElementIds.push(dstId);
              }
            }
          });
        }
        context.subViewGraph.currentClickId = activeElementIds;

        // 更新高亮
        this.props.context.subViewGraph.activeElements.forEach((item) => {
          if (item instanceof Table || item instanceof Column) {
            item.view.updateHighLight();
          }
        });

        // 侧边栏高亮处理
        const activeSqlIds = getActiveSqlIdsById(
          index,
          this.props.context.exploreState.taskSqlList,
          this.props.context.exploreState.exploreDirection,
        );
        if (activeSqlIds.length === 0) {
          showExploreTipNoSql();
        }
        this.props.context.changeExploreState({
          ...this.props.context.exploreState,
          activeSqlIds,
        });
      } else {
        showExploreTipNotInActiveElement();
      }
      return;
    }

    if (e.target instanceof Image) {
      this.hideSideIcon();
      return;
    }

    context.subViewGraph.currentClickId = [index];
    context.updateActiveItem(this.props.data);

    this.showDetailPanel();
  }

  onDblClick = () => {
    const { data, context } = this.props;

    if (
      this.leftIcon.visible &&
      this.leftIcon.expandType === EExpandType.CLOSE
    ) {
      // 左-
      context.removeGraphData(data, EDirection.INPUT);
    } else if (
      this.rightIcon.visible &&
      this.rightIcon.expandType === EExpandType.CLOSE
    ) {
      // 右-
      context.removeGraphData(data, EDirection.OUTPUT);
    } else if (
      this.leftIcon.visible &&
      this.leftIcon.expandType === EExpandType.EXPAND
    ) {
      // 左+
      this.leftIcon.expandGraph();
    } else if (
      this.rightIcon.visible &&
      this.rightIcon.expandType === EExpandType.EXPAND
    ) {
      this.rightIcon.expandGraph();
    }
    // context.removeGraphData(data, EDirection.BOTH);
  };

  onMouseEnter() {
    // 右键菜单出来时不要修改存储的状态，不然会导致右键菜单实际操作源不是当前的节点
    if (this.props.context.contextmenuState.visible) return;
    this.showSideIcon();
  }

  onMouseLeave() {
    this.hideSideIcon();
  }

  onContextMenu(e: Event) {
    e.preventDefault();

    const { data, context } = this.props;
    const { index } = data;

    context.subViewGraph.currentRightClickId = [index];
    context.updateRightClickActiveItem(data);

    if (data.parent._data_.type === ETableType.TEMP_TABLE) return;
    // 只有输入资产或者输出资产或者即使输入资产又是输出资产才有右键
    if (
      [EDirection.BOTH, EDirection.INPUT, EDirection.OUTPUT].includes(
        data.direction,
      )
    ) {
      const translate = runtime.sceneGraphManager.getPosition(this.text);
      const [translateX, translateY] = translate;
      const left = 100 + translateX;
      const top = Number(this.text.style.y) + translateY - 10;

      context.showContextmenu({
        guid: index,
        parentGuid: data.parent.index,
        visible: true,
        left,
        top,
        type: EElementType.COLUMN,
        typeCode: 'Table',
        direction: data.direction,
      });
    }
  }

  showDetailPanel = async () => {
    const { data, context } = this.props;
    const { index } = data;

    // 将data同步到atom，触发更新
    const { userClosed, visible } = context.overviewState;
    let overviewType = EOverviewType.COLUMN;
    if (data.parent._data_.type === ETableType.TEMP_TABLE) {
      overviewType = EOverviewType.TEMP_COLUMN;
    }
    context.setOverview({
      ...context.overviewState,
      targetId: index,
      type: overviewType,
      visible: !(userClosed && !visible),
      initial: true,
      direction: data.direction,
      name: data._data_.name,
    });
  };

  hide() {
    this.g.parentNode.removeChild(this.g);
    this.props.data.visible = false;
  }

  show() {
    this.props.parentNode.appendChild(this.g);
    this.props.data.visible = true;
  }

  showSideIcon() {
    const { data, context } = this.props;

    // 输出资产
    if (data.direction === EDirection.OUTPUT) {
      if (getIconVisible(context, data, EDirection.INPUT)) {
        // 上游和起始表
        this.leftIcon.expandType = getIconType(context, data, EDirection.INPUT);
        this.leftIcon.setImg(sideIconTypeObj[this.leftIcon.expandType]);
        this.leftIcon.show();
      } else {
        this.leftIcon.hide();
      }
    }

    // 输入资产
    if (data.direction === EDirection.INPUT) {
      if (getIconVisible(context, data, EDirection.OUTPUT)) {
        this.rightIcon.expandType = getIconType(
          context,
          data,
          EDirection.OUTPUT,
        );
        this.rightIcon.setImg(sideIconTypeObj[this.rightIcon.expandType]);
        this.rightIcon.show();
      } else {
        this.rightIcon.hide();
      }
    }

    this.props.context.changeSideIconState({
      data,
    });
  }

  hideSideIcon() {
    this.leftIcon.hide();
    this.rightIcon.hide();
  }

  showExpandLoadingIcon() {
    this.expandLoadingIcon.show();
  }

  hideExpandLoadingIcon() {
    this.expandLoadingIcon.hide();
  }

  setExpandLoadingIconPosition(pos: vec3) {
    this.expandLoadingIcon.setPosition(pos);
  }

  updateHighLight() {
    const { currentClickId, currentRightClickId, nodes } =
      this.props.context.subViewGraph;

    let stroke = '#E8E8E8';
    let fill = '#fff';
    let textFill = '#171717';
    let zIndex = 0;

    if (this.props.context.exploreState.mode === EGraphMode.EXPLORE) {
      if (currentClickId.includes(this.props.data.index)) {
        stroke = '#FFB01F';
        fill = '#FFFAF0';
        textFill = '#171717';
        zIndex = 2;
      } else {
        if (nodes.includes(this.props.data)) {
          stroke = '#497FCC';
          fill = '#F2F5FA';
          zIndex = 1;
        }
      }
    } else {
      if (currentClickId.includes(this.props.data.index)) {
        stroke = '#3769AD';
        fill = '#3769AD';
        textFill = '#FFFFFF';
        zIndex = 1;
      } else {
        if (nodes.includes(this.props.data)) {
          stroke = '#497FCC';
          fill = '#F2F5FA';
          zIndex = 1;
        }
      }
    }

    if (currentRightClickId.includes(this.props.data.index)) {
      stroke = '#3271C9';
      zIndex = 3;
    }

    this.bgRect.setAttribute('stroke', stroke);
    this.bgRect.setAttribute('fill', fill);
    this.g.setAttribute('zIndex', zIndex);
    this.text.setAttribute('fill', textFill);
  }

  highLightBySearch() {
    this.bgRect.setAttribute('stroke', '#FFC04D');
    this.bgRect.setAttribute('fill', '#FFD180');
  }

  resetHighLight() {
    this.bgRect.setAttribute('stroke', '#E8E8E8');
    this.bgRect.setAttribute('fill', '#FFF');
    this.text.setAttribute('fill', '#171717');
  }

  getPosition() {
    return this.g.getPosition();
  }
}

export default ColumnView;
