import { useEffect, useState } from 'react';
import { parseResultData } from '@/utils/lineageData';
import { useSearchParams } from '@@/exports';
import { ERelationType, ILineageQuery } from '@/services/lineage/type';
import { getColumnList, getLineageDataV2 } from '@/services/lineage';
import { Spin } from '@aloudata/aloudata-design';
import { getTaskLineage } from '@/services/task';

import {
  IUserEdge,
  IUserTable,
  TInputEntity,
} from '@/components/TaskLineageView/typings';
import { logReport } from '@/utils/report';
import { EReportTag } from '@/constants/report';
import TaskLineageView from '@/components/TaskLineageView';
import styles from './index.less';
import { parseColumnGuid } from '@/utils';
import { EDirection } from '@/typings';

export const directionMap: { [key: string]: EDirection } = {};

const Lineage = () => {
  const [searchParams] = useSearchParams();
  const [nodes, setNodes] = useState<(TInputEntity | IUserTable)[]>([]);
  const [edges, setEdges] = useState<IUserEdge[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const guid = searchParams.get('guid');
  const originTableGuid = searchParams.get('originTableGuid');

  const params: ILineageQuery = {
    depth: 3,
    direction: EDirection.INPUT,
    taskGuid: guid,
    relations: [ERelationType.TableDirectTable],
    entityType: 'Table',
    needIndirect: true, // 第一次始终请求全量的节点
  };

  useEffect(() => {
    setLoading(true);
    logReport(EReportTag.TASKLINEAGE_PAGE_OPEN, { taskGuid: guid });

    const fetchLineageData = async () => {
      try {
        const lineageData = await getLineageDataV2(params);
        if (!lineageData) throw new Error('lineage data is empty');

        // check lineage status
        const lineageStatus = await getTaskLineage(guid);
        const { tables } = lineageStatus;
        const outputTableIds: string[] = [];
        tables.forEach((item) => {
          // 如果已经存在，且方向不一致，改成双向
          if (directionMap[item.guid]) {
            if (
              directionMap[item.guid] === EDirection.INPUT &&
              item.direction === EDirection.OUTPUT
            ) {
              directionMap[item.guid] = EDirection.BOTH;
            } else if (
              directionMap[item.guid] === EDirection.OUTPUT &&
              item.direction === EDirection.INPUT
            ) {
              directionMap[item.guid] = EDirection.BOTH;
            }
          } else {
            directionMap[item.guid] = item.direction as EDirection;
          }

          if (item.direction === EDirection.OUTPUT) {
            outputTableIds.push(item.guid);
          }
        });

        // parse table data
        const parsedData = parseResultData(lineageData);

        const { nodes: parsedNodes, edges: parsedEdges } = parsedData;

        if (originTableGuid && originTableGuid !== 'undefined') {
          // fetch target table columns
          const columnsData = await getColumnList(originTableGuid);
          // update table columns data
          if (columnsData) {
            parsedNodes.forEach((node) => {
              if (node.guid === originTableGuid) {
                node.children = columnsData.columns.map((item) => {
                  return {
                    ...item,
                    guid: parseColumnGuid(item.guid),
                  };
                });
              }
            });
          }
        }

        setNodes([...parsedNodes]);
        setEdges(parsedEdges);

        if (!lineageStatus) return;
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchLineageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  const renderLineage = () => {
    // loading status
    if (loading) {
      return (
        <div className={styles.spin}>
          <Spin spinning={loading} size={'large'} />
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.errorLineage}>
          <div className={styles.empty} />
        </div>
      );
    }

    // render task lineage
    return <TaskLineageView nodes={nodes as IUserTable[]} edges={edges} />;
  };

  return renderLineage();
};

export default Lineage;
