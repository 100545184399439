import { useRecoilValue } from 'recoil';
import styles from './index.less';
import { EGraphMode } from '@/components/TaskLineageView/atoms/overviewAtom';
import { Button, Empty, Select, Tooltip } from '@aloudata/aloudata-design';
import { ReactComponent as InfoIcon } from '@/assets/info-circle-line.svg';
import { ReactComponent as TableIcon } from '@/assets/table-24px.svg';
import { ReactComponent as ColumnIcon } from '@/assets/structure-column-line.svg';
import useOverview from '@/components/TaskLineageView/hooks/useOverview';
import { EOverviewType } from '@/typings/overview';

import React, { useEffect, useState } from 'react';
import { getParamsFromSearch } from '@/utils';
import { SQLGraphAtom } from '@/components/TaskLineageView/atoms/sqlGraphAtom';
import Table from '@/components/TaskLineageView/model/Table';
import Column from '@/components/TaskLineageView/model/Column';
import useExploreData from '@/components/TaskLineageView/hooks/useExploreData';
import { useLocation } from '@/.umi/exports';
import { ReactComponent as UpStepIcon } from '@/assets/chevron-up-line.svg';
import { ReactComponent as DownStepIcon } from '@/assets/chevron-down-line.svg';
import _ from 'lodash';
import SqlCardContent from './SqlCardContent';
import { EDirection } from '@/typings';
import classNames from 'classnames';
import { useSearchParams } from 'umi';

let previousSelectValue = '';

const Explore = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const sqlGraph = useRecoilValue(SQLGraphAtom);
  const overviewState = useOverview();
  const exploreState = useExploreData();

  const { activeSqlIds, exploreAssetId, exploreDirection } = exploreState;
  const [selectValue, setSelectValue] = useState<string>();

  useEffect(() => {
    const currentOrder =
      exploreDirection === EDirection.INPUT ? 'reverseOrder' : 'order';
    if (selectValue === currentOrder) {
      return;
    }
    if (exploreDirection) {
      setSelectValue(currentOrder);
      previousSelectValue = selectValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreDirection, exploreAssetId]);

  const location = useLocation();

  const [currentSqlIndex, setCurrentSqlIndex] = useState(0);

  const intoViewElementId = activeSqlIds[currentSqlIndex];
  useEffect(() => {
    const element = document.getElementById(intoViewElementId);
    const container = document.getElementById('scrollArea');

    if (element) {
      // 当前在可视区域就不滚动
      const { top, bottom } = element.getBoundingClientRect();
      const topOffset = 128;
      const leftTopIconOffsetTop = 10;
      const bottomOffset =
        window.innerHeight || document.documentElement.clientHeight;
      if (top > topOffset && bottom < bottomOffset) {
        return;
      }

      setTimeout(() => {
        const { offsetTop } = element;
        container.scrollTo({
          top: offsetTop - leftTopIconOffsetTop,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [intoViewElementId]);

  // 当前点击的id发生变更，重置currentSqlIndex
  const { currentClickId } = sqlGraph.subViewGraph;
  const firstCurrentClickId = currentClickId[0];
  const { name: assetName } =
    sqlGraph.viewGraph.currentNodeMap.get(firstCurrentClickId) || {};
  useEffect(() => {
    setCurrentSqlIndex(0);
  }, [firstCurrentClickId]);

  let sqlListOrder = exploreState.taskSqlList;
  if (selectValue === 'reverseOrder') {
    sqlListOrder = [];
    const lastIndex = exploreState.taskSqlList.length - 1;
    for (let i = lastIndex; i > -1; i--) {
      const item = exploreState.taskSqlList[i];
      sqlListOrder.push(item);
    }
  }

  const [renderSqlList, setRenderSqlList] = useState([]);

  const BATCH_SIZE = 20;
  useEffect(() => {
    let isMounted = true;
    setRenderSqlList([]);

    if (!selectValue || selectValue === previousSelectValue) return;
    const renderInBatches = async () => {
      for (let i = 0; i < sqlListOrder.length; i += BATCH_SIZE) {
        if (!isMounted) return;
        setRenderSqlList((prev) => {
          return [...prev, ...sqlListOrder.slice(i, i + BATCH_SIZE)];
        });
        await new Promise((resolve) => setTimeout(resolve, 0));
      }
    };

    renderInBatches();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreAssetId, selectValue, sqlListOrder.length]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <span className={styles.nameContainer}>
            <Tooltip title={`资产路径：${overviewState.assetPath}`}>
              {overviewState.type === EOverviewType.TABLE ? (
                <TableIcon color={'#fff'} />
              ) : (
                <ColumnIcon />
              )}
            </Tooltip>
            <Tooltip title={overviewState.name}>
              <span className={styles.name}>{overviewState.name}</span>
            </Tooltip>
          </span>
          <div className={styles.text}>
            {exploreState.exploreDirection === EDirection.INPUT
              ? '溯源探索'
              : '影响探索'}
            裁剪代码
          </div>
          <Select
            options={[
              { label: '正序展示', value: 'order' },
              { label: '倒序展示', value: 'reverseOrder' },
            ]}
            value={selectValue}
            className={styles.select}
            onChange={(value) => {
              previousSelectValue = selectValue;
              setSelectValue(value);
            }}
          />
          <Tooltip title="按照脚本内相关代码的原始编写顺序，从输入表到输出表进行展示的方式为正序展示，反之为倒序展示">
            <InfoIcon color="#858585" className={styles.infoIcon} />
          </Tooltip>
        </div>
        <Button
          type="primary"
          onClick={() => {
            // 把之前打开的 Table 全部收起来
            sqlGraph.viewGraph.currentNodeMap.forEach((item) => {
              if (item instanceof Table) {
                item.isOpen = false;
              }
            });

            // 删除之前 nodes 中highlight 为 true 的数据
            sqlGraph.viewGraph.nodes.forEach((node) => {
              // 删除 children 中 highlight 为 true 的数据
              _.remove(node.children, (child) => child.highlight);
            });
            _.remove(sqlGraph.viewGraph.edges, (child) => child.highlight);
            sqlGraph.viewGraph.originNodeMap.forEach((node) => {
              if (node instanceof Table) {
                node.children.forEach((child) => {
                  if (child.highlight) {
                    child.highlight = false;
                  }
                });
              }
              if (node.highlight) {
                sqlGraph.viewGraph.originNodeMap.delete(node.index);
              }
            });
            sqlGraph.viewGraph.originEdgeMap.forEach((node) => {
              if (node.highlight) {
                sqlGraph.viewGraph.originEdgeMap.delete(node.index);
              }
            });

            sqlGraph.subViewGraph.activeItem = null;
            sqlGraph.changeExploreState({
              ...exploreState,
              mode: EGraphMode.NORMAL,
              activeSqlIds: [],
              exploreDirection: null,
            });

            // 更新侧边栏的概览信息
            const targetId = getParamsFromSearch(location.search, 'guid');
            sqlGraph.setOverview({
              ...overviewState,
              targetId,
              type: EOverviewType.TASK,
            });
            sqlGraph.resetAllHighLight();
            sqlGraph.rerender();
            sqlGraph.viewGraph.wrapper.getCamera().resetPosition();
          }}
        >
          退出探索
        </Button>
      </div>
      <div className={styles.sqlListText}>
        以下展示的内容为，用户选中资产在当前任务内的裁剪后代码
        <Tooltip title="针对Update、Merge等统一处理为Select模式剔除Delete等模式">
          <InfoIcon color="#858585" className={styles.infoIcon} />
        </Tooltip>
        {activeSqlIds.length > 1 ? (
          <div className={styles.stepContainer}>
            <span>
              {overviewState.type === EOverviewType.COLUMN ? '列' : '表'}
              {assetName} 加工片段
            </span>
            <span>
              <span className={styles.currenSqlIndex}>
                {currentSqlIndex + 1}
              </span>
              / {activeSqlIds.length}
            </span>
            <span className={styles.splitLine} />
            <UpStepIcon
              size={16}
              onClick={() => {
                if (currentSqlIndex > 0) {
                  setCurrentSqlIndex(currentSqlIndex - 1);
                }
              }}
              className={styles.stepIcon}
            />
            <DownStepIcon
              size={16}
              onClick={() => {
                if (currentSqlIndex < activeSqlIds.length - 1) {
                  setCurrentSqlIndex(currentSqlIndex + 1);
                }
              }}
              className={styles.stepIcon}
            />
          </div>
        ) : null}
      </div>
      {sqlListOrder.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SEARCH_SMALL}
          size="small"
          title="无数据"
          className={styles.empty}
        />
      ) : null}
      <div
        id="scrollArea"
        className={classNames(styles.exploreContainer, {
          [styles.simulationExploreContainer]: !!id,
        })}
        key={exploreAssetId}
      >
        {renderSqlList.map((item) => {
          const { sql, sqlId, sqlOrder, outputEntities, inputEntities } = item;
          let color = '#f1f4fd';
          let borderColor = '#dee4f7';
          const isActiveId = activeSqlIds.includes(sqlId);

          if (isActiveId) {
            color = '#feebc8';
            borderColor = '#FFB01F';
          }

          return (
            <div
              className={styles.codeMirrorCard}
              style={{ background: '#f1f4fd', borderColor }}
              id={sqlId}
              key={sqlId}
              onClick={() => {
                // 更新侧边栏高亮的ids
                sqlGraph.changeExploreState({
                  ...exploreState,
                  activeSqlIds: [sqlId],
                });

                // 更新画布的高亮信息
                sqlGraph.subViewGraph.currentClickId = [
                  ...outputEntities,
                  ...inputEntities,
                ];
                sqlGraph.subViewGraph.activeElements.forEach((activeItem) => {
                  if (
                    activeItem instanceof Table ||
                    activeItem instanceof Column
                  ) {
                    activeItem.view.updateHighLight();
                  }
                });

                // 将当前元素挪到可视区域中
                const currNode = sqlGraph.viewGraph.currentNodeMap.get(
                  outputEntities[0],
                );
                sqlGraph.determineNodeIsInView(currNode);
              }}
            >
              <SqlCardContent
                sql={sql}
                sqlOrder={sqlOrder}
                color={color}
                relationType={item.relationType}
                background={isActiveId ? '#feebc8' : '#e4e7ef'}
                fontColor={isActiveId ? '#ad5c0b' : '#98a2bc'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Explore);
