import { get } from '@/utils/request';
import { API_SERVER } from '..';
import { ITaskSqlListItem } from '@/components/TaskLineageView/atoms/overviewAtom';

// 目前把任务血缘的所有接口都放在这里

// 获取输入/输出表/列的逐层sql数据
export const getTaskLineageSqlList = (data: {
  entityGuid: string;
  taskGuid: string;
  type: string;
  direction: string;
  needIndirect: boolean;
}) => {
  return get<Omit<ITaskSqlListItem, 'sqlId'>[]>(
    `${API_SERVER}/asset/sql/entityInTask`,
    data,
  );
};
