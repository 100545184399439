// 根据useLocation中提供的search字段，获取当前的params
import { history } from 'umi';
import type { Location } from 'history';

export function getParamsFromSearch(
  search: string,
  key: string,
): string | undefined {
  if (!search) {
    return;
  }
  const str = search.replace('?', '');
  const paramList = str.split('&');
  let value;
  paramList.some((item) => {
    const [currKey, currValue] = item.split('=');
    if (currKey === key) {
      // 浏览器本身做了一次 encode, 代码上又做了一次，所以这里需要 2 次 decode
      value = decodeURIComponent(decodeURIComponent(currValue));
    }
    return currKey === key;
  });
  return value;
}

// 路由模式为hash时，从hash中获取对应参数
export function getParamsFromHash(
  hash: string,
  key: string,
): string | undefined {
  const str = hash.split('?')[1];
  return getParamsFromSearch(str, key);
}

export function directTo(
  path: string,
  params?: { [key: string]: string | number },
) {
  if (!params) {
    history.push(path);
    return;
  }
  const paramStringArr: string[] = [];
  for (const paramStringKey in params) {
    if (params.hasOwnProperty(paramStringKey)) {
      paramStringArr.push(
        `${paramStringKey}=${encodeURIComponent(params[paramStringKey])}`,
      );
    }
  }

  history.push(path + '?' + paramStringArr.join('&'));
}

// 新窗口打开
export function openNewWindow(
  path: string,
  params?: { [key: string]: string | number },
) {
  if (!params) {
    window.open(path);
    return;
  }
  const paramStringArr: string[] = [];
  for (const paramStringKey in params) {
    if (params.hasOwnProperty(paramStringKey)) {
      paramStringArr.push(
        `${paramStringKey}=${encodeURIComponent(params[paramStringKey])}`,
      );
    }
  }
  // console.log('#/' + path + '?' + paramStringArr.join('&'));

  window.open('/#' + path + '?' + paramStringArr.join('&'));
}

export function getSearchParam(location: Location, key: string): string | null {
  const { search } = location;
  const params = new URLSearchParams(search);
  const val = params.get(key);
  return val;
}
