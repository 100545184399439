import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { SQLGraphAtom } from '../atoms/sqlGraphAtom';
import SQLGraph from '../model/SQLGraph';
import { EEventEnum } from '../typings';
import useUpdate from './useUpdate';
import { commonUseFunc } from './util';

export default function useExploreData() {
  const update = useUpdate();
  const sqlGraph: SQLGraph = useRecoilValue(SQLGraphAtom);
  useEffect(() => {
    return commonUseFunc(sqlGraph, EEventEnum.EXPLORE_CHANGE, update);
  });
  return sqlGraph.exploreState;
}
