import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import Table from '@/components/TaskLineageView/model/Table';
import Column from '@/components/TaskLineageView/model/Column';
import Edge from '@/components/TaskLineageView/model/Edge';
import { getTablePathNodesEdges } from '@/components/TaskLineageView/utils/lineage';
import { TActiveItem, TNodeItem } from '@/components/TaskLineageView/typings';
import { EDirection } from '@/typings';

interface IColumnInfo {
  column: Column;
  x: number;
  y: number;
  w: number;
  h: number;
}

export default class SubGraph {
  highlightCurrIndex: number = 0;

  highlightNodes: TNodeItem[] = [];

  highlightColumnInfos: IColumnInfo[] = [];

  activeItem: TActiveItem;

  edges: Edge[];

  nodes: TNodeItem[];

  activeElements: TActiveItem[] = [];

  rightClickActiveElements: TActiveItem[] = [];

  parent: SQLGraph;

  currentClickId: string[] = []; // 当前点击的节点  列 表 边 自定义节点

  currentRightClickId: string[] = []; // 当前右键点击的节点  列 表 边 自定义节点

  textCanvasCtx: CanvasRenderingContext2D;

  highlightNodeId: string;

  constructor(rootDom: HTMLElement, parent: SQLGraph) {
    this.parent = parent;
  }

  rerender() {
    this.updateActiveItem();
  }

  updateActiveItem(
    item: TActiveItem = this.activeItem,
    direction = EDirection.BOTH,
  ) {
    this.renderActiveItem(
      item,
      this.parent.exploreState.exploreDirection || direction,
    );
  }

  updateRightClickActiveItem(item: TActiveItem = this.activeItem) {
    this.renderRightClickActiveItem(item);
  }

  resetActiveItem(item: TActiveItem = this.activeItem) {
    this.activeItem = item;
  }

  // 每次查询 重置参数
  resetHighLightColumns() {
    // reset highlight column
    const col = this.highlightNodes.find(
      (item) => item.index === this.highlightNodeId,
    );
    if (col) col.view.resetHighLight();

    this.highlightCurrIndex = 0;
    this.highlightNodeId = '';
    this.highlightNodes = [];
  }

  // 首次查询
  searchHighLightNode(res: TNodeItem[]) {
    requestAnimationFrame(() => {
      this.highlightNodes = res;
      // 定位到第一个
      this.highlightCurrIndex = 0;

      this.highLightByIndex();
    });
  }

  highlightToNext() {
    this.highlightCurrIndex += 1;
    this.highLightByIndex();
    return this.highlightCurrIndex;
  }

  highLightToPrev() {
    this.highlightCurrIndex -= 1;
    this.highLightByIndex();
    return this.highlightCurrIndex;
  }

  highLightByIndex() {
    // reset last node
    const lastNode = this.highlightNodes.find(
      (node) => node.index === this.highlightNodeId,
    );
    if (lastNode) lastNode.view.resetHighLight();

    // highlight current node
    const currNode = this.highlightNodes[this.highlightCurrIndex];
    if (!currNode) return;

    currNode.view.highLightBySearch();

    // move to center
    this.parent.viewGraph.moveTargetToCenter(currNode.view.bgRect);

    this.highlightNodeId = currNode.index;
  }

  private renderActiveItem(
    item: TActiveItem = this.activeItem,
    direction = EDirection.BOTH,
  ) {
    if (!item) return;

    this.nodes = [];
    this.edges = [];
    this.removeActiveElements();
    this.activeItem = item;

    if (item instanceof Table) {
      const res = getTablePathNodesEdges(
        [item],
        this.parent.viewGraph.originEdgeMap,
        this.parent.viewGraph.currentNodeMap,
        direction,
        Number.MAX_SAFE_INTEGER,
        {
          edgeType: this.parent.viewGraph.edgeDashVisible,
          operatorId: this.parent.operatorId,
        },
      );
      this.nodes.push(...res.nodes);
      this.edges.push(...res.edges);
    } else if (item instanceof Column) {
      // const res = getColumnPathNodesEdges(
      //   [item],
      //   this.parent.viewGraph.originEdgeMap,
      //   this.parent.viewGraph.currentNodeMap,
      //   EDirection.BOTH,
      //   Number.MAX_SAFE_INTEGER,
      //   {
      //     edgeType: this.parent.viewGraph.edgeDashVisible,
      //     operatorId: this.parent.operatorId,
      //   },
      // );
      const taskTables: string[] = [];

      this.parent.exploreState.taskSqlList.forEach((task) => {
        if (task.outputEntityType === 'TABLE') {
          taskTables.push(...task.outputEntities);
        }
      });
      const newColumns = Array.from(
        this.parent.viewGraph.originNodeMap.values(),
      ).filter((node) => {
        if (
          node instanceof Column &&
          (node.highlight || this.currentClickId.includes(node.index))
        ) {
          return true;
        }
      });

      const newEdges = Array.from(
        this.parent.viewGraph.originEdgeMap.values(),
      ).filter((node) => {
        if (node.highlight) {
          return true;
        }
      });

      // 服务端返回的 table 都应该要高亮
      const tables: Table[] = taskTables.map((tableId) => {
        return this.parent.viewGraph.originNodeMap.get(tableId) as Table;
      });

      this.nodes.push(...newColumns);
      this.nodes.push(...tables);
      this.edges.push(...newEdges);
    } else {
      const res = {
        edges: [item],
        nodes: [
          this.parent.viewGraph.currentNodeMap.get(item.srcId) as Column,
          this.parent.viewGraph.currentNodeMap.get(item.dstId) as Column,
        ],
      };
      this.nodes.push(...res.nodes);
      this.edges.push(...res.edges);
    }

    this.renderActive();
  }

  renderActive() {
    // 从 nodeMap中找出需要渲染的内容，add到副画布中
    this.nodes.forEach((item) => {
      if (
        item instanceof Table &&
        this.parent.viewGraph.currentNodeMap.has(item.index)
      ) {
        item.view.updateHighLight();
        this.addActiveElement(item);
      }

      if (item instanceof Column) {
        const parentTable = item.parent;
        if (
          parentTable.isOpen &&
          this.parent.viewGraph.currentNodeMap.has(item.index)
        ) {
          item.view.updateHighLight();
          this.addActiveElement(item);
        }
      }
    });

    // 从 originEdgeMap 中选出需要渲染的内容
    this.edges.forEach((edge) => {
      if (edge.view && edge.visible) {
        edge.view.updateHighLight();
        this.addActiveElement(edge);
      }
    });
  }

  private renderRightClickActiveItem(item: TActiveItem = this.activeItem) {
    if (!item) return;

    this.nodes = this.nodes || [];
    this.edges = this.edges || [];

    // clear prev highlight
    this.removeRightClickActiveElements();

    // update current item
    item.view.updateHighLight();

    this.addRightClickActiveElement(item);
  }

  addActiveElement(element: TNodeItem | Edge) {
    this.activeElements.push(element);
  }

  removeActiveElements() {
    this.activeElements.forEach((item) => {
      item.view.updateHighLight();
    });
    this.activeElements = [];

    // clear right click item highlight
    this.clearRightClickActiveElements();
  }

  addRightClickActiveElement(element: TActiveItem) {
    this.rightClickActiveElements.push(element);
  }

  removeRightClickActiveElements() {
    this.rightClickActiveElements.forEach((item) => {
      item.view.updateHighLight();
    });
    this.rightClickActiveElements = [];
  }

  clearRightClickActiveElements() {
    this.currentRightClickId = [];
    this.removeRightClickActiveElements();
  }
}
