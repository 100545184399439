import { Component, ErrorInfo, isValidElement } from 'react';
import ErrorBoundaryContent from './components/ErrorBoundaryContent';

type FallbackRender = (
  error: Error,
  componentStack: string | null,
) => React.ReactElement;

interface IErrorBoundaryProps {
  onError?: (error: Error, componentStack: string) => void;
  fallback?: React.ReactElement | FallbackRender;
  children?: React.ReactNode | (() => React.ReactNode);
}

interface IErrorBoundaryState {
  error: Error;
  componentStack: string;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null, componentStack: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo.componentStack); // onError callback
    }
    this.setState({
      error,
      componentStack: errorInfo.componentStack,
    });
  }

  render() {
    const { fallback, children } = this.props;
    const { error, componentStack } = this.state;

    let element = <ErrorBoundaryContent />;

    if (error) {
      if (fallback) {
        if (typeof fallback === 'function') {
          element = fallback(error, componentStack);
        } else {
          element = fallback;
        }
      }

      if (isValidElement(element)) {
        return element;
      } else {
        // eslint-disable-next-line no-console
        console.warn('fallback did not produce a valid ReactElement');
        return null;
      }
    }

    if (typeof children === 'function') {
      return children();
    }

    return children;
  }
}

export default ErrorBoundary;
