export const home = {
  tipWord: '随时在线的数据助手',
  searchPlaceholder: '请开始你的探索吧',

  recentlyViewedTabText: '最近查看',

  alterMonitorTabText: '重点链路变更',
  reportsTabText: '报告产出',
  notification: '通知',
  readAll: '全部已读',

  viewNow: '立即查看',
  notificationEmpty: '暂无通知内容',
  peopleResponsible: '您负责的',
  occur: '发生',
  inputNode: '上游链路节点',
  tagNode: '标签链路中链路节点',
  makeFailTips: '制作失败，请查看并重新执行',
  makeSuccessTips: '制作成功，请查看',
  supportText: '如果遇到使用问题，请联系大应科技支持人员',

  recentlyEmpty: '暂无最近查看的内容',
};
