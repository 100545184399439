// 这里加 bigmeta 的原因是因为本地开发和招行环境是服务端根据这个前缀做了反向代理的，
// 但是在迭代平台场景下因为前端是放到了服务端 VM 中返回的，所以不需要了
export const API_SERVER =
  process.env.UMI_ENV === 'develop' ? '/bigmeta' : window.location.origin;

export interface IPagination {
  pageSize?: number;
  pageNum?: number;
}

export interface ISearchNamePagination extends IPagination {
  name?: string;
}

export interface IPaginationResData<T = any> {
  currentPage: number;
  pageSize: number;
  totalNum: number;
  totalPage: number;
  result: T[];
}
