import styles from './index.less';
import ToolbarSearch from '@/components/TaskLineageView/components/Toolbar/ToolbarSearch';
import Tools from '@/components/TaskLineageView/components/Toolbar/Tools';
import classNames from 'classnames';
import useOverview from '@/components/TaskLineageView/hooks/useOverview';

const Toolbar = () => {
  const overviewState = useOverview();

  return (
    <div
      data-testid="components-TaskLineageView2-components-Toolbar-index-div-1"
      className={styles.toolbar}
    >
      <div
        data-testid="components-TaskLineageView2-components-Toolbar-index-div-2"
        className={styles.left}
      >
        <ToolbarSearch data-testid="components-TaskLineageView2-components-Toolbar-index-ToolbarRight-3" />
      </div>
      <div
        data-testid="components-TaskLineageView2-components-Toolbar-index-div-4"
        className={classNames(styles.right, {
          [styles.overviewVisible]: overviewState.visible,
        })}
      >
        <Tools data-testid="components-TaskLineageView2-components-Toolbar-index-ToolbarTopRight-5" />
      </div>
    </div>
  );
};

export default Toolbar;
