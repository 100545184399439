import ColumnView from '../view/ColumnView';
import {
  COL_HEIGHT,
  DEFAULT_X,
  DEFAULT_Y,
  TABLE_COL_WIDTH,
} from '../constants';
import { IColProperties } from '../typings';
import { EDirection } from '@/typings';
import BaseNode from './BaseNode';
import Table from '@/components/TaskLineageView/model/Table';

export default class Column extends BaseNode<ColumnView, IColProperties> {
  parent: Table;

  isStartNode: boolean;

  fold: boolean; // 是否可折叠

  direction: EDirection;

  assetPath: string;

  constructor(data: IColProperties, parent: Table) {
    super({
      context: parent.context,
      name: data.name,
      index: data.guid,
      typeCode: 'Column',
      width: TABLE_COL_WIDTH,
      height: COL_HEIGHT,
      x: DEFAULT_X,
      y: DEFAULT_Y,
    });
    this._data_ = data;
    this.assetPath = `${parent.assetPath}.${parent.name}`;
    this.parent = parent;
    const { isStartNode, direction } = this.parent;
    this.isCorrelation = isStartNode || !!data.isCorrelation;
    this.isStartNode = isStartNode;
    this.direction = direction;
    this.highlight = data.highlight;
    this.expandFromOperator = this.parent.expandFromOperator;
  }
}
