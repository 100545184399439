import React, { useState } from 'react';
import styles from './index.less';
import { ReactComponent as CopyLine } from '@/assets/copy-line.svg';
import { ReactComponent as DownLine } from '@/assets/chevron-down-line.svg';
import { ReactComponent as UpLine } from '@/assets/chevron-up-line.svg';
import { copyContent } from '@/utils';
import ReactCodeMirror from './ReactCodeMirror';
const SqlCardContent = ({
  sqlOrder,
  sql,
  relationType,
  background,
  fontColor,
  color,
}: {
  color: string;
  sqlOrder: number;
  sql: string;
  relationType: string;
  background: string;
  fontColor: string;
}) => {
  const isIndirect = relationType === 'INDIRECT';
  const [isShowComment, setIsShowComment] = useState(isIndirect);
  return (
    <>
      <span className={styles.numOrder} style={{ background: color }}>
        {sqlOrder}
      </span>
      {isIndirect ? (
        <span
          className={styles.comment}
          style={{ background, color: fontColor }}
        >
          间接影响表
        </span>
      ) : null}
      <ReactCodeMirror sql={sql} isShowComment={isShowComment} />
      {sql ? (
        <CopyLine
          size={20}
          className={styles.copyIcon}
          fill="#171717"
          onClick={() => {
            copyContent(sql);
          }}
        />
      ) : null}
      {isIndirect ? (
        <span
          onClick={() => {
            setIsShowComment(!isShowComment);
          }}
          className={styles.commentText}
        >
          {isShowComment ? (
            <>
              展开 <DownLine />
            </>
          ) : (
            <>
              收起 <UpLine />
            </>
          )}
        </span>
      ) : null}
    </>
  );
};

export default React.memo(SqlCardContent);
