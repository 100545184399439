import { Switch, Tooltip } from '@aloudata/aloudata-design';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';
import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import { SQLGraphAtom } from '@/components/TaskLineageView/atoms/sqlGraphAtom';

import { ReactComponent as ResetIcon } from '../../../images/center-circle-line.svg';
import { EDashType } from '@/components/TaskLineageView/typings';
import { InfoCircleLine } from '@aloudata/icons-react';
import { logReport } from '@/utils/report';
import { EReportTag } from '@/constants/report';

const mouseEnterDelay = 0.5;

const ToolbarTools = () => {
  const sqlGraph: SQLGraph = useRecoilValue(SQLGraphAtom);
  const [zoomRatio, setZoom] = useState(1);
  useEffect(() => {
    if (zoomRatio !== 1 && sqlGraph?.viewGraph?.wrapper) {
      sqlGraph.viewGraph.wrapper.getCamera().setZoom(zoomRatio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRatio]);

  const handlePosition = () => {
    setZoom(1);
    sqlGraph?.viewGraph?.wrapper?.getCamera().resetPosition();
  };

  const onLineageRelationChange = (checked: boolean) => {
    sqlGraph.updateEdgeDashVisible(
      checked ? EDashType.INDIRECT : EDashType.DIRECT,
    );
    logReport(EReportTag.TASKLINEAGE_TOOLBAR_INDIRECT_CLICK);
  };

  return (
    <div
      data-testid="components-TaskLineageView2-components-Toolbar-ToolbarTopRight-index-div-3"
      className={styles.wrapper}
    >
      <Switch onChange={onLineageRelationChange} defaultChecked={false}>
        间接血缘
        <Tooltip title={'本期暂未支持列上游间接血缘展示'}>
          <InfoCircleLine
            style={{ marginLeft: 4 }}
            size={18}
            color={'#fff'}
            className={styles.helpIcon}
          />
        </Tooltip>
      </Switch>
      <div className={styles.line} />
      <Tooltip
        data-testid="components-TaskLineageView2-components-Toolbar-ToolbarTopRight-index-Tooltip-9"
        title={'重置定位'}
        mouseEnterDelay={mouseEnterDelay}
        placement={'left'}
      >
        <ResetIcon
          onClick={handlePosition}
          size={24}
          className={classNames(styles.iconBtn)}
        />
      </Tooltip>
    </div>
  );
};

export default ToolbarTools;
