import { EEdgeType } from '@/services/lineage/type';
import { EDashType, IEdge, IUserEdge } from '../typings';
import EdgeView from '@/components/TaskLineageView/view/EdgeView';

export default class Edge implements IEdge {
  index: string;

  srcId!: string;

  dstId!: string;

  visible: boolean = true;

  active?: boolean;

  highlight?: boolean;

  type: EDashType;

  edgeType: EEdgeType;

  view: EdgeView;

  fold: boolean; // v0.6 合并链路

  showExpand?: boolean = false; // v0.6 是否为可展开的边

  foldTableId?: string; // v0.6 合并链路的表id

  inFoldLink?: boolean; // v0.6 在合并链路上

  constructor(data: IUserEdge) {
    Object.assign(this, data);
    this.index = `${this.srcId}-${this.dstId}`;
  }

  refresh(data: this) {
    Object.assign(this, data);
  }

  bindView(view: EdgeView) {
    this.view = view;
  }
}
