import React, { useState } from 'react';
import { tags as t } from '@lezer/highlight';
import CodeMirror from '@uiw/react-codemirror';
import { extensions, myTheme } from '@/components/RightPanelComponentsNew/SQL';
import sqlFormatter from 'sql-formatter';
import { useMount } from 'ahooks';

const ReactCodeMirror = ({
  color = '#f1f4fd',
  sql,
  isShowComment,
}: {
  color?: string;
  sql: string;
  isShowComment: boolean;
}) => {
  const settings = {
    background: color,
    gutterBackground: color,
    gutterBorder: color,
    lineHighlight: color,
  };
  // const formatSql = sqlFormatter.format(sql);
  const [formatSql, setFormatSql] = useState('');
  useMount(() => {
    setFormatSql(sqlFormatter.format(sql));
  });

  const [sql1] = formatSql.split('/\n');
  const comment = `${sql1}/`;

  if (!formatSql) return null;

  return (
    <CodeMirror
      value={isShowComment ? comment : formatSql}
      theme={myTheme(settings, [{ tag: t.comment, color: 'green' }])}
      extensions={extensions}
      readOnly={true}
    />
  );
};

export default React.memo(ReactCodeMirror);
