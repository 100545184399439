import { ICommonRectStyle } from '@/components/TaskLineageView/components/type';
import { RECT_LINE_WIDTH } from '@/components/TaskLineageView/constants';
import Table from '@/components/TaskLineageView/model/Table';
import { EEntityType } from '../../components/type';

export const RECT_BORDER_RADIUS = 2;

export const TABLE_WIDTH = 190;
export const TABLE_HEADER_HEIGHT = 54;
export const TABLE_FOOTER_HEIGHT = 16;

// 表与表之间的间距
export const TABLE_MARGIN = 2;

// table 展开收起icon
export const TABLE_OPERATE_ICON_X = 170;
export const TABLE_OPERATE_ICON_Y = 11;
export const TABLE_OPERATE_ICON_WIDTH = 10;
export const TABLE_OPERATE_ICON_HEIGHT = 6;

// table header rect
export const HEADER_RECT_WIDTH = 10;

// table text
export const TABLE_TEXT_X = 12;
export const TABLE_TEXT_Y = 18;
export const TABLE_TEXT_WIDTH = 165;

// table footer
export const TABLE_FOOTER_RECT_HEIGHT = 24;
export const TABLE_FOOTER_TEXT1_X = 12;
export const TABLE_FOOTER_TEXT2_X = 130;
export const TABLE_FOOTER_TEXT2_X1 = 174;
export const TABLE_FOOTER_TEXT2_X2 = 165;
export const TABLE_FOOTER_TEXT2_X3 = 159;

// footer image
export const TABLE_FOOTER_IMAGE_X = 173;
export const TABLE_FOOTER_IMAGE_Y = 10;
export const TABLE_FOOTER_IMAGE_WIDTH = 8;
export const TABLE_FOOTER_IMAGE_HEIGHT = 4;

export const NUM_10 = 10;
export const NUM_100 = 100;
export const NUM_1000 = 1000;

export const COMMON_BG_COLOR = '#fff';
export const BASE_STROKE = 'rgba(18, 18, 18, 0.05)';

export const getTableStyles = ({
  isOpen,
  type,
}: {
  isOpen?: boolean;
  type?: EEntityType;
}): {
  header?: ICommonRectStyle;
  footer?: ICommonRectStyle;
  footerRadius?: ICommonRectStyle;
} => {
  return {
    footer: {
      style: {
        fill: COMMON_BG_COLOR,
        // stroke: BASE_STROKE,
        lineWidth: RECT_LINE_WIDTH,
        width: TABLE_WIDTH,
        height: 0,
        radius: RECT_BORDER_RADIUS,
      },
    },
    header: {
      style: {
        stroke: '#E8E8E8',
        fill: '#FFFFFF',
        lineWidth: RECT_LINE_WIDTH,
        width: TABLE_WIDTH,
        height: TABLE_HEADER_HEIGHT,
        radius: [
          EEntityType.HIVE,
          EEntityType.OPERATOR,
          EEntityType.VIRTUAL,
        ].includes(type)
          ? RECT_BORDER_RADIUS
          : isOpen
          ? [RECT_BORDER_RADIUS, RECT_BORDER_RADIUS, 0, 0]
          : RECT_BORDER_RADIUS,
      },
    },
    footerRadius: {
      style: {
        fill: 'rgba(255, 255, 255,0)',
        // stroke: 'rgba(18, 18, 18, 0.05)',
        lineWidth: 1,
        x: 0,
        y: 0,
        width: TABLE_WIDTH,
        height: TABLE_FOOTER_RECT_HEIGHT,
        radius: [0, 0, RECT_BORDER_RADIUS, RECT_BORDER_RADIUS],
      },
    },
  };
};

export function getFooterStyle(height: number): ICommonRectStyle {
  const tableStyles = getTableStyles({ isOpen: true });
  return {
    style: {
      ...tableStyles.footer.style,
      height,
    },
  };

  // return {
  //   style: {
  //     ...tableStyles.footer.style,
  //   },
  //   shape: {
  //     ...tableStyles.footer.shape,
  //     height,
  //   },
  // };
}

export function getHeaderStyle(
  isOpen = false,
  type = EEntityType.DATABASE,
): ICommonRectStyle {
  const tableStyles = getTableStyles({ isOpen, type });
  // if (isStartNode) return tableStyles.header;
  return {
    style: {
      ...tableStyles.header.style,
      // ...tableStyles.header.shape,
    },
  };
}

export function getHighLightHeader(data: Table): ICommonRectStyle {
  const { isOpen } = data;
  const tableStyles = getTableStyles({ isOpen });
  // if (isStartNode) return tableStyles.header;
  return {
    style: {
      ...tableStyles.header.style,
      fill: isOpen ? '#f1f9ff' : '#f1f9ff',
      stroke: isOpen ? '#f1f9ff' : '#2E90FA',
    },
    // shape: {
    //   ...tableStyles.header.shape,
    // },
  };
}
