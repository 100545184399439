import HiveIcon from '@/assets/hive.svg';
import MySqlIcon from '@/assets/my-sql.svg';
import GaussIcon from '@/assets/gauss.svg';
import SnowflakeIcon from '@/assets/snowflake.svg';
import PostgreSqlIcon from '@/assets/postgre-sql.svg';
import KafkaIcon from '@/assets/kafka-logo.svg';
import ImpalaIcon from '@/assets/impala.svg';
import OracleIcon from '@/assets/oracle.svg';
import SparkIcon from '@/assets/spark.svg';
import TeradataIcon from '@/assets/teradata.svg';
import { EDataSourceType } from '@/typings';

export const getDataSourceTypeIcon = (datasourceType: EDataSourceType) => {
  switch (datasourceType) {
    case EDataSourceType.HIVE:
      return HiveIcon;
    case EDataSourceType.MYSQL:
      return MySqlIcon;
    case EDataSourceType.GAUSS:
      return GaussIcon;
    case EDataSourceType.KAFKA:
      return KafkaIcon;
    case EDataSourceType.SNOWFLAKE:
      return SnowflakeIcon;
    case EDataSourceType.POSTGRE:
    case EDataSourceType.POSTGRESQL:
      return PostgreSqlIcon;
    case EDataSourceType.IMPALA:
      return ImpalaIcon;
    case EDataSourceType.SPARK:
      return SparkIcon;
    case EDataSourceType.ORACLE:
      return OracleIcon;
    case EDataSourceType.TERADATA:
      return TeradataIcon;
    default:
      return '';
  }
};
