import { vec2 } from 'gl-matrix';
import SQLGraph from '../../model/SQLGraph';
import dagre from 'dagre';
import { ILayoutOptions } from '../type';
import { EDashType } from '../../typings';

export function layoutByDagre(context: SQLGraph, options: ILayoutOptions) {
  const { nodes, edges } = context.viewGraph;
  const { width, height } = options;

  const g = new dagre.graphlib.Graph();
  g.setDefaultEdgeLabel(() => ({}));

  g.setGraph({
    rankdir: 'LR',
    ranksep: 160,
    nodesep: 60,
  });

  nodes.forEach((node) => {
    g.setNode(node.index, {
      width: 190,
      height: node.view.getHeight(),
    });
  });

  edges.forEach((edge) => {
    if (edge.type === EDashType.INDIRECT) return;
    g.setEdge(edge.srcId, edge.dstId);
  });

  dagre.layout(g);

  let rightNode = '';

  const positions = nodes.map((node) => {
    const nodePos = g.node(node.index);
    const newPos = vec2.fromValues(
      nodePos.x - nodePos.width / 2,
      nodePos.y - nodePos.height / 2,
    );
    if (rightNode === '') {
      rightNode = node.index;
    } else if (nodePos.x > g.node(rightNode).x) {
      rightNode = node.index;
    }
    return newPos;
  });

  const maxXGroup = findMaxXPos(positions);

  const RIGHT_SIDEBAR_WIDTH = 620;
  const canvasWidth = width - RIGHT_SIDEBAR_WIDTH - 200 - 100;
  const offsetX = maxXGroup[0] - canvasWidth;
  // const offsetY = 120;
  const offsetY =
    -g.node(rightNode).y + g.node(rightNode).height / 2 + height / 3;

  const pos = positions.map((item) => {
    return vec2.fromValues(item[0] - offsetX, item[1] + offsetY);
  });

  return pos;
}

function findMaxXPos(arr: vec2[]): vec2 | null {
  if (arr.length === 0) {
    return null;
  }

  let maxXGroup = arr[0];
  for (const [x, y] of arr) {
    if (x > maxXGroup[0]) {
      maxXGroup = [x, y];
    }
  }

  return maxXGroup;
}
