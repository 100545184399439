import { Canvas, Event } from '@aloudata/ink';
import Graph, { IElement } from '../model/Graph';
import TableView from '../view/TableView';
import EdgeView from '../view/EdgeView';
import ActionIcon from '../components/ActionIcon';
import ColumnView from '../view/ColumnView';
import { EGraphMode } from '@/components/TaskLineageView/atoms/overviewAtom';

export class EventManager {
  context: Graph;

  wrapper: Canvas;

  constructor(context: Graph) {
    this.context = context;
    this.wrapper = context.wrapper;
  }

  initEvents() {
    this.initGlobalEvent();
    this.initClickEvent();
    this.initDblClickEvent();
    this.initContextMenuEvent();
    this.initMouseEnter();
    this.initMouseLeave();
  }

  initGlobalEvent() {
    this.wrapper
      .addEventListener('click', (e: Event) => {
        if (e.target === this.wrapper.document) {
          this.context.parent.hideContextmenu();
        }
      })
      .addEventListener('drag', () => {
        this.context.isDragGraph = true;
        this.context.parent.hideContextmenu();
      })
      .addEventListener('dragend', () => {
        this.context.isDragGraph = false;
      })
      .addEventListener('dblclick', (e: Event) => {
        this.context.parent.checkIsFlush(e);
        if (e.target === this.wrapper.document) {
          // 取消高亮
          if (this.context.parent.exploreState.mode === EGraphMode.EXPLORE) {
            this.context.parent.subViewGraph.clearRightClickActiveElements();
            return;
          }
          this.context.parent.resetAllHighLight();
        }
      })
      .addEventListener('contextmenu', (e: Event) => {
        e.preventDefault();
      })
      .addEventListener('wheel', () => {
        this.context.parent.hideContextmenu();
      });
  }

  initClickEvent() {
    this.wrapper.addEventListener('click', (e: Event) => {
      const target = e.target as IElement;
      if (!target) return;

      const targetParent = target.parentElement as IElement;

      /**
       * column event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'column-group') {
        const targetNode = targetParent.node as ColumnView;
        if (!targetNode) return;

        targetNode.onClick(e);
      }

      /**
       * table event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'table-header-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onHeaderClick(e);
      }

      if (target.name === 'table-header-toggle-image') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onHeaderToggleClick();
      }

      if (targetParent && targetParent.name === 'table-footer-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onFooterClick();
      }

      /**
       * edge event  ------------------------- start
       **/
      if (target.name === 'edge') {
        const targetNode = target.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onEdgeClick();
      }

      if (target.name === 'edge-marker-text') {
        const rootNode = target.parentNode?.parentNode as IElement;
        if (!rootNode) return;
        const targetNode = rootNode.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMarkerClick();
      }

      if (target.name === 'edge-marker-rect') {
        const rootNode = target.parentNode as IElement;
        if (!rootNode) return;
        const targetNode = rootNode.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMarkerClick();
      }

      /**
       * sideIcon event  ------------------------- start
       **/
      if (target.name === 'sideIcon-img') {
        const targetNode = target.node as ActionIcon;
        if (!targetNode) return;

        targetNode.onIconClick();
      }
    });
  }

  initDblClickEvent() {
    this.wrapper.addEventListener('dblclick', (e: Event) => {
      const target = e.target as IElement;
      if (!target) return;

      const targetParent = target.parentElement as IElement;

      /**
       * column event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'column-group') {
        const targetNode = targetParent.node as ColumnView;
        if (!targetNode) return;

        targetNode.onDblClick();
      }

      /**
       * table event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'table-header-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onHeaderDblClick(e);
      }

      if (targetParent && targetParent.name === 'table-footer-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onFooterClick();
      }
    });
  }

  initContextMenuEvent() {
    this.wrapper.addEventListener('contextmenu', (e: Event) => {
      const target = e.target as IElement;
      if (!target) return;

      const targetParent = target.parentElement as IElement;

      /**
       * column event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'column-group') {
        const targetNode = targetParent.node as ColumnView;
        if (!targetNode) return;

        targetNode.onContextMenu(e);
      }

      /**
       * table event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'table-header-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onContextMenu(e);
      }
    });
  }

  initMouseEnter() {
    this.wrapper.addEventListener('mouseover', (e: Event) => {
      if (this.context.isDragGraph) return;

      const target = e.target as IElement;
      if (!target) return;

      const targetParent = target.parentElement as IElement;

      /**
       * column event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'column-group') {
        const targetNode = targetParent.node as ColumnView;
        if (!targetNode) return;

        targetNode.onMouseEnter();

        const handleMouseLeave = () => {
          targetNode.onMouseLeave();
          targetNode.g.removeEventListener('mouseleave', handleMouseLeave);
        };

        targetNode.g.addEventListener('mouseleave', handleMouseLeave);
      }

      /**
       * table event  ------------------------- start
       **/
      if (targetParent && targetParent.name === 'table-header-group') {
        const targetNode = targetParent.node as TableView;
        if (!targetNode) return;

        targetNode.onMouseEnter();

        const handleMouseLeave = () => {
          targetNode.onMouseLeave();
          targetNode.headerGroup.removeEventListener(
            'mouseleave',
            handleMouseLeave,
          );
        };

        targetNode.headerGroup.addEventListener('mouseleave', handleMouseLeave);
      }

      /**
       * edge event  ------------------------- start
       **/
      if (target.name === 'edge') {
        const targetNode = target.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMouseEnter();

        // const handleMouseLeave = (ev: Event) => {
        //   targetNode.onMouseLeave();
        //   target.removeEventListener('mouseleave', handleMouseLeave);
        // };

        // target.addEventListener('mouseleave', handleMouseLeave);
      }

      if (target.name === 'edge-marker-text-close') {
        const rootNode = target.parentNode?.parentNode as IElement;
        if (!rootNode) return;
        const targetNode = rootNode.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMarkerMouseEnter();

        // const handleMouseLeave = () => {
        //   targetNode.onMarkerMouseLeave();
        //   rootNode.removeEventListener('mouseleave', handleMouseLeave);
        // };

        // rootNode.addEventListener('mouseleave', handleMouseLeave);
      }

      if (target.name === 'edge-marker-rect-close') {
        const rootNode = target.parentNode as IElement;
        if (!rootNode) return;
        const targetNode = rootNode.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMarkerMouseEnter();

        // const handleMouseLeave = () => {
        //   targetNode.onMarkerMouseLeave();
        //   rootNode.removeEventListener('mouseleave', handleMouseLeave);
        // };

        // rootNode.addEventListener('mouseleave', handleMouseLeave);
      }
    });
  }

  initMouseLeave() {
    this.wrapper.addEventListener('mouseout', (e: Event) => {
      if (this.context.isDragGraph) return;

      const target = e.target as IElement;
      if (!target) return;

      /**
       * edge event  ------------------------- start
       **/
      if (target.name === 'edge') {
        const targetNode = target.node as unknown as EdgeView;
        if (!targetNode) return;

        targetNode.onMouseLeave();
      }

      // if (target.name === 'edge-marker-text-close') {
      //   const rootNode = target.parentNode?.parentNode as IElement;
      //   if (!rootNode) return;
      //   const targetNode = rootNode.node as EdgeView;
      //   if (!targetNode) return;

      //   targetNode.onMarkerMouseLeave();
      // }

      // if (target.name === 'edge-marker-rect-close') {
      //   const rootNode = target.parentNode as IElement;
      //   if (!rootNode) return;
      //   const targetNode = rootNode.node as EdgeView;
      //   if (!targetNode) return;

      //   targetNode.onMarkerMouseLeave();
      // }
    });
  }
}
