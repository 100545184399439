import { isTableEdge } from '@/components/TaskLineageView/utils/edge';
import Edge from '@/components/TaskLineageView/model/Edge';

export function getEdgeStyle(edge: Edge) {
  const { edgeType } = edge;

  if (isTableEdge(edgeType)) {
    return '#ddd';
  } else {
    return '#497FCC';
  }
}
