// 变更影响分析的国际化 中文
export const changeImpactAnalysis = {
  //待变更资产
  waitChangeAssets: '待变更资产',
  // 分析类型
  analysisType: '分析类型',
  // 变更类型
  changeType: '变更类型',
  // 报告类型
  reportType: '报告类型',
  // 用户分析报告
  userAnalysisReport: '用户分析报告',
  // 系统分析报告
  systemAnalysisReport: '系统分析报告',
  // 状态
  status: '状态',
  //操作
  operate: '操作',
  // 查看报告
  viewReport: '查看报告',
  // 重新执行
  reExecute: '重新执行',
  // 重新执行成功
  reExecuteSuccess: '重新执行成功',
  // 重新执行失败
  reExecuteFailure: '重新执行失败',
  // 共 X 份报告
  totalReport: (length: number) => {
    return `共 ${length} 份报告`;
  },
  // 请选择
  pleaseSelect: '请选择',
  // 暂无内容
  noContent: '暂无内容',
  // 链路影响盘点
  changeImpactAnalysis: '影响分析',
  // 新建报告
  createReport: '新建报告',
  // 是否确认删除该报告
  confirmDelete: '是否确认删除该报告',
  // 删除成功
  deleteSuccess: '删除成功',
  // 删除失败
  deleteFailure: '删除失败',
  // 等待
  init: '等待',
  // 已完成
  success: '已完成',
  // 失败
  fail: '失败',
  // 执行中
  process: '执行中',

  /*************报告详情页****************/
  // 报告不存在
  reportNotExist: '报告不存在',
  // 影响分析
  impactAnalysis: '影响分析',
  // 下载报告
  downloadReport: '下载报告',
  // 基本信息
  basicInfo: '基本信息',
  // 变更内容
  changeContent: '变更内容',
  // 最大分析深度
  maxAnalysisDepth: '最大分析深度',
  //层
  layer: '层',
  // 影响资产统计
  impactAssets: '影响资产统计',
  // 资产数量
  assetsNumber: '资产数量',
  // 负责人
  owner: '负责人',
  // 风险类型
  riskType: '风险类型',
  // 受影响资产
  affectedAssets: '受影响资产',
  // 受影响来源
  affectedSource: '受影响来源',
  // 查看
  view: '查看',
  // 上游资产
  upstreamAssets: '上游资产',
  // 血缘类型
  lineageType: '血缘类型',
  // 血缘层级
  lineageLevel: '血缘层级',
  // 影响资产列表
  impactAssetsList: '影响资产列表',
  // 共 {data.length} 份资产
  totalAssets: (length: number) => {
    return `共 ${length} 份资产`;
  },
  // 无下游资产受影响
  noDownstreamAssetsAffected: '无下游资产受影响',
  // 变更资产
  changeAssets: '变更资产',
  // 链路盘点
  reportManagement: '链路分析',
  //场景分析
  sceneAnalysis: '场景分析',
  // 资产唯一编码
  assetCode: '资产唯一编码',
  // 上游资产类型
  upstreamAssetType: '上游资产类型',
  // 上游资产唯一编码
  upstreamAssetCode: '上游资产唯一编码',
};
