import useOverview from '@/components/TaskLineageView/hooks/useOverview';
import { useResetRecoilState } from 'recoil';

import { SidebarRouterAtom } from '../../atoms/sqlGraphAtom';
import { EGraphMode } from '../../atoms/overviewAtom';
import Task from './Task';
import Explore from './Explore';
import useExploreData from '../../hooks/useExploreData';
import ErrorBoundary from '@/components/ErrorBoundary';

const DetailPanel = () => {
  const overviewState = useOverview();

  const resetSidebarRouter = useResetRecoilState(SidebarRouterAtom);
  const exploreState = useExploreData();

  if (overviewState.initial) {
    resetSidebarRouter();
  }

  const getContent = () => {
    if (overviewState.visible) {
      switch (exploreState.mode) {
        case EGraphMode.NORMAL:
          return <Task />;
        case EGraphMode.EXPLORE:
          return (
            <ErrorBoundary>
              <Explore />
            </ErrorBoundary>
          );
      }
    }
  };

  return (
    <div data-testid="components-TaskLineageView2-components-DetailPanel-index-div-3">
      {getContent()}
    </div>
  );
};

export default DetailPanel;
