import { SQLGraphAtom } from '@/components/TaskLineageView/atoms/sqlGraphAtom';
import useContextmenu from '@/components/TaskLineageView/hooks/useContextmenu';
import { IUserEdge, IUserTable } from '@/components/TaskLineageView/typings';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DetailPanel from './components/DetailPanel';
import RightKeyMenu from './components/RightKeyMenu';
import styles from './index.less';
import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import Toolbar from '@/components/TaskLineageView/components/Toolbar';
import _ from 'lodash';
import { useSearchParams } from '@@/exports';
import { EOverviewType } from '@/typings/overview';
import useOverview from '@/components/TaskLineageView/hooks/useOverview';
import { Drawer, Spin } from '@aloudata/aloudata-design';
import { ReactComponent as ExpandRightLine } from '@/assets/expand_right_line.svg';
import { ReactComponent as CollapseRightLine } from '@/assets/collapse_right_line.svg';
import { logReport } from '@/utils/report';
import { EReportTag } from '@/constants/report';
import { useMount, useUnmount } from 'ahooks';
import useExploreData from '@/components/TaskLineageView/hooks/useExploreData';
import { EGraphMode } from './atoms/overviewAtom';

export interface ITaskLineageView2Props {
  nodes: IUserTable[];
  edges: IUserEdge[];
}

let pageTime = 0;
let exploreTime = 0;
const TaskLineageView = ({ nodes, edges }: ITaskLineageView2Props) => {
  const [searchParams] = useSearchParams();
  const guid = searchParams.get('guid');
  const id = searchParams.get('id');
  const updateFlag = searchParams.get('updateFlag');
  const sqlGraph = useRecoilValue(SQLGraphAtom);
  const setSqlGraph = useSetRecoilState(SQLGraphAtom);
  const contextmenuState = useContextmenu();
  const divRef = useRef<HTMLDivElement>(null);
  const overviewState = useOverview();
  const exploreState = useExploreData();

  useMount(() => {
    // 记录页面打开时间戳
    pageTime = Date.now();
  });

  useUnmount(() => {
    // 记录页面关闭时间戳
    const timeDiff = Date.now() - pageTime;
    if (timeDiff < 1000) return;
    logReport(EReportTag.TASKLINEAGE_CANVAS_TIME, {
      time: timeDiff,
    });
  });

  // 上报探索时间
  useEffect(() => {
    if (exploreState.mode === EGraphMode.EXPLORE) {
      exploreTime = Date.now();
    } else {
      if (exploreTime === 0) return;
      logReport(EReportTag.TASKLINEAGE_CANVAS_EXPLORE_TIME, {
        time: Date.now() - exploreTime,
      });
      exploreTime = 0;
    }
  }, [exploreState.mode]);

  useEffect(() => {
    const root = document.getElementById('TaskLineageView2');

    // 当是任务血缘的时候，侧边栏需要默认打开
    sqlGraph.setOverview({
      type: EOverviewType.TASK,
      visible: true,
      targetId: guid,
      id,
    });
    if (nodes.length > 0) sqlGraph.init(nodes, edges, root, guid || id);
    (window as any).sqlGraph = sqlGraph;

    return () => {
      setSqlGraph(new SQLGraph());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid, id, updateFlag]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      _.debounce(() => {
        sqlGraph.svgWrapperResize();
      }, 100),
    );
    resizeObserver.observe(divRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [sqlGraph]);

  /**
   * 收缩按钮
   *
   */
  const handlePanelToggle = async () => {
    const newOverviewState = { ...overviewState };
    const { visible: newOverviewStateVisible } = newOverviewState;

    if (newOverviewStateVisible) {
      newOverviewState.userClosed = true;
    }
    newOverviewState.visible = !newOverviewStateVisible;

    // 更新状态
    sqlGraph.setOverview(newOverviewState);
  };

  // toggle icon的展示状态
  const toggleIcon = overviewState.visible ? (
    <CollapseRightLine
      data-testid="components-TaskLineageView2-components-DetailPanel-index-ArrowsUpLine-2"
      title={'展开'}
      size={24}
      fill={'#98A2B3'}
    />
  ) : (
    <ExpandRightLine
      data-testid="components-TaskLineageView2-components-DetailPanel-index-ArrowsDownLine-1"
      size={24}
      title={'收起'}
      fill={'#98A2B3'}
    />
  );
  return (
    <>
      <Toolbar data-testid="components-TaskLineageView2-index-Toolbar-1" />
      <div
        data-testid="components-TaskLineageView2-index-div-2"
        id="SQLView2Wrapper"
        ref={divRef}
        className={classNames(styles.sqlviewWrapper)}
      >
        <div
          data-testid="components-TaskLineageView2-index-div-3"
          id="TaskLineageView2"
          className={styles.svgWrapper}
        />
        {overviewState.showLoading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}

        {contextmenuState.visible && (
          <RightKeyMenu
            data-testid="components-TaskLineageView2-index-RightKeyMenu-9"
            key={Date.now()}
            {...contextmenuState}
          />
        )}
      </div>
      <Drawer
        placement="right"
        open={overviewState.visible}
        mask={false}
        getContainer={() => document.getElementById('wrapperLinage')}
        width={650}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0 }}
        className={classNames(styles.drawer, {
          [styles.simulationDrawer]: !!id,
        })}
        rootClassName={styles.drawerRoot}
      >
        <DetailPanel data-testid="components-TaskLineageView2-index-DetailPanel-7" />
      </Drawer>
      <div
        data-testid="components-TaskLineageView2-components-DetailPanel-index-div-37"
        className={classNames(styles.toggle, {
          [styles.overviewVisible]: overviewState.visible,
          [styles.isSimulationToggle]: !!id,
        })}
        onClick={handlePanelToggle}
      >
        {toggleIcon}
      </div>
    </>
  );
};

export default TaskLineageView;
