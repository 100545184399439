import { get, post } from '@/utils/request';
import { API_SERVER } from '..';
import { IColumnListRes, ILineageDataRes, ILineageQuery } from './type';
import { deparseGuid } from '@/utils';

// 自定义血缘版本的查询
export function getLineageData(data: ILineageQuery) {
  data.guids = data.guids.map((item) => {
    return deparseGuid(item);
  });
  return post<ILineageDataRes>(`${API_SERVER}/lineage/custom`, data);
}

// 自定义血缘版本的查询
export function getLineageDataV2(data: ILineageQuery) {
  if (data.guids) {
    data.guids = data.guids.map((item) => {
      return deparseGuid(item);
    });
  }
  return post<ILineageDataRes>(`${API_SERVER}/lineage/custom/v2`, data);
}

// 获取表的列信息
export function getColumnList(guid: string) {
  return get<IColumnListRes>(`${API_SERVER}/lineage/table/column/list`, {
    guid: deparseGuid(guid),
  });
}

// 行裁剪创建
export function createAnalyzeTask(data: {
  assetId: string;
  assetType: string;
  cropAnalyseConfig: { analyseDepth: number };
}) {
  return post<string>(`${API_SERVER}/assetTraceCrop/createAnalyseTask`, data);
}

// 根据查询值，获取包含目标值的tableGuid
export function getTableGuidListBySearchValue(data: {
  searchName: string;
  tableGuidList: string[];
}) {
  return post<string[]>(`${API_SERVER}/asset/tables/search`, data);
}
