import { EOverviewType, IOverView } from '@/typings/overview';

export const overviewDefaultData: IOverView = {
  targetId: '', // 当前选中的guid
  type: EOverviewType.TABLE, // 展示的类型 数据集/列
  userClosed: false, // 用户手动触发收起
  visible: false, // overview显隐状态
  initial: false, // 是否是初始状态
  showLoading: false, // 是否展示loading
  assetPath: '', //资产路径
};
