import { vec2 } from 'gl-matrix';
import { ELayoutType, ILayoutOptions } from './type';
import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import { layoutByDagre } from './dagre';

export default class Layout {
  static run(
    context: SQLGraph,
    options: ILayoutOptions = {
      layout: ELayoutType.DAGRE,
      width: 0,
      height: 0,
    },
  ) {
    let pos: vec2[] = [];
    switch (options.layout) {
      case ELayoutType.DAGRE:
        pos = layoutByDagre(context, options);
        break;
    }
    return pos;
  }
}
