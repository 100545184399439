import { EDirection } from '.';

export enum EOverviewType {
  TABLE = 'TABLE',
  VIEW = 'VIEW',
  COLUMN = 'COLUMN',
  TASK = 'TASK',
  TEMP_TABLE = 'TEMP_TABLE',
  TEMP_COLUMN = 'TEMP_COLUMN',
  CUSTOM = 'CUSTOM',
}

export interface IOverView {
  targetId: string;
  type?: EOverviewType;
  userClosed?: boolean;
  visible: boolean;
  // tableName: string;
  initial?: boolean;
  direction?: EDirection;
  name?: string;
  showLoading?: boolean;
  assetPath?: string;
  taskIsHighlight?: boolean;
  caliberIsHighlight?: boolean;
  id?: string;
}
