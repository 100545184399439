import { SIDE_ICON_SIZE } from '../../constants';
import SQLGraph from '../../model/SQLGraph';
import { EDashType, sideIconTypeObj } from '../../typings';
import Column from '@/components/TaskLineageView/model/Column';
import { parseResultData } from '@/utils/lineageData';
import { getColumnListParams } from '@/components/TaskLineageView/utils/request';
import Table from '@/components/TaskLineageView/model/Table';
import {
  removeColumnByNode,
  showNoMoreLinage,
} from '@/components/TaskLineageView/utils/lineage';
import {
  ERelationType,
  ILineageDataRes,
  ILineageQuery,
  IResRelation,
} from '@/services/lineage/type';
import { getLineageDataV2 } from '@/services/lineage';
import { Image } from '@aloudata/ink';
import ColumnView from '../../view/ColumnView';
import TableView from '../../view/TableView';
import { IElement } from '../../model/Graph';
import { showIndirectLineage } from '../../utils';
import { EDirection } from '@/typings';
import { getLinageSimulation } from '@/services/sceneCenter';

export enum EIconPositionType {
  LEFT,
  RIGHT,
}

export enum EExpandType {
  EXPAND,
  CLOSE,
}

let prevColumn: Column = null;

class ActionIcon {
  context: SQLGraph;

  positionType: EIconPositionType;

  expandType: EExpandType = EExpandType.EXPAND;

  image: Image;

  target: ColumnView | TableView;

  constructor(target: ColumnView | TableView, type: EIconPositionType) {
    this.target = target;
    this.context = target.props.context;
    this.positionType = type;

    this.render();
  }

  render() {
    this.image = new Image({
      name: `sideIcon-img`,
      style: {
        x: this.positionType === EIconPositionType.LEFT ? -28 : 190,
        y: 4,
        width: SIDE_ICON_SIZE,
        height: SIDE_ICON_SIZE,
        img: sideIconTypeObj[this.expandType],
        cursor: 'pointer',
        zIndex: 99,
        visibility: 'hidden',
      },
    });

    (this.image as IElement).node = this;
  }

  onIconClick() {
    // 关闭两侧的图标
    this.target.hideSideIcon();

    if (this.expandType === EExpandType.EXPAND) {
      this.expandGraph();
    } else {
      this.closeGraph();
    }
  }

  removeGraph() {
    const { context, positionType } = this;
    const direction =
      positionType === EIconPositionType.LEFT
        ? EDirection.INPUT
        : EDirection.OUTPUT;
    const startNode = context.getSideState().data;
    if (startNode.isStartNode) {
      removeColumnByNode(
        (startNode as unknown as Table).children,
        direction,
        context,
      );
    }
    context.removeGraphData(startNode, direction);
  }

  closeGraph() {
    this.removeGraph();
  }

  async expandGraph(depth: number = 1) {
    // show loading
    // this.target.setExpandLoadingIconPosition(this.image.getPosition());
    // this.target.showExpandLoadingIcon();

    const { context, positionType } = this;
    const direction =
      positionType === EIconPositionType.LEFT
        ? EDirection.INPUT
        : EDirection.OUTPUT;

    const { data } = context.getSideState();
    const guidList: string[] = [];

    if (data instanceof Column) {
      // 删除之前探索的那个节点相关的 node
      if (prevColumn) {
        removeColumnByNode([prevColumn], direction, context);
      }
      if (data.isStartNode) {
        // 删除当前节点的关联血缘
        // removeColumnByNode([data], direction, context);
      }

      // 列扩展
      guidList.push(data.index);
      try {
        const params: ILineageQuery = getColumnListParams(
          guidList,
          data,
          context,
          direction,
          depth,
          true,
          context.viewGraph.edgeDashVisible === EDashType.INDIRECT, // new
        );
        params.taskGuid = context.taskId;
        let newData: ILineageDataRes;
        if (context.overviewState.id) {
          newData = await getLinageSimulation({
            id: context.overviewState.id,
            guids: guidList,
            direction,
            entityType: 'Column',
            needIndirect:
              context.viewGraph.edgeDashVisible === EDashType.INDIRECT,
          });
        } else {
          newData = await getLineageDataV2(params);
        }

        const parseData = parseResultData(newData);

        const { edges, nodes } = parseData;
        // 如果只有一个 delete 语句的话，也是没有 edge 的，所以需要看下是否有 sql
        const hasSql = context.exploreState.taskSqlList.length > 0;
        if (edges.length <= 0 && !hasSql) {
          // 需要吧之前删除那个 column 重新添加回去，并且高亮设置为 false
          if (prevColumn) {
            const parent = context.viewGraph.originNodeMap.get(
              prevColumn.parent.index,
            ) as Table;
            prevColumn.highlight = false;
            parent.children.push(prevColumn);
            prevColumn = null;
            context.rerender();
          }
          return showNoMoreLinage(direction);
        }

        // 把之前打开的 Table 全部收起来
        context.viewGraph.currentNodeMap.forEach((item) => {
          if (item instanceof Table) {
            item.isOpen = false;
          }
        });
        nodes.forEach((item) => {
          item.children.forEach((child: any) => {
            child.highlight = true;
          });
        });

        edges.forEach((item) => {
          item.highlight = true;
        });

        data.highlight = true;
        if (data.isStartNode) {
          removeColumnByNode(
            data.parent.children.filter((item) => item.index !== data.index),
            EDirection.BOTH,
            context,
          );
        }
        context.expandGraphData(nodes, edges);

        // 打开了间接血缘开关，需要判断是否存在间接血缘的关系，有的话要把 table 展开
        // if (this.context.viewGraph.edgeDashVisible === EDashType.INDIRECT) {
        edges.forEach((edge) => {
          const { srcId, dstId } = edge;
          const srcNode = this.context.viewGraph.originNodeMap.get(srcId);
          const dstNode = this.context.viewGraph.originNodeMap.get(dstId);
          if (srcNode instanceof Column) {
            if (!srcNode.parent.isOpen) {
              srcNode.parent.isOpen = true;
            }
          }
          if (dstNode instanceof Column) {
            if (!dstNode.parent.isOpen) {
              dstNode.parent.isOpen = true;
            }
          }
        });
        // }

        // 高亮当前扩展的血缘
        context.dellColumnExpand(data);
        // 判断是否存在间接血缘，弹出提示
        this.checkIsShowIndirectLineage(
          newData.relations,
          direction,
          data.index,
        );
        prevColumn = data;
      } catch (e) {
        console.error(e);
      }
    }

    // hide loading
    // this.target.hideExpandLoadingIcon();
  }

  // FIXME: 临时新增方法，避免影响原有链路，后续优化
  async expandGraphByDashType(
    depth: number,
    direction: EDirection,
    guid: string,
    data: Column,
  ) {
    const { context } = this;

    if (data instanceof Column) {
      // 删除之前探索的那个节点相关的 node
      if (prevColumn) {
        removeColumnByNode([prevColumn], direction, context);
      }

      // 列扩展
      try {
        const params: ILineageQuery = getColumnListParams(
          [guid],
          null,
          context,
          direction,
          depth,
          true,
          context.viewGraph.edgeDashVisible === EDashType.INDIRECT, // new
        );
        params.taskGuid = context.taskId;
        let newData: ILineageDataRes;
        if (context.overviewState.id) {
          newData = await getLinageSimulation({
            id: context.overviewState.id,
            guids: [guid],
            direction,
            entityType: 'Column',
            needIndirect:
              context.viewGraph.edgeDashVisible === EDashType.INDIRECT,
          });
        } else {
          newData = await getLineageDataV2(params);
        }

        const parseData = parseResultData(newData);

        const { edges, nodes } = parseData;
        // 如果只有一个 delete 语句的话，也是没有 edge 的，所以需要看下是否有 sql
        const hasSql = context.exploreState.taskSqlList.length > 0;
        if (edges.length <= 0 && !hasSql) {
          // 需要吧之前删除那个 column 重新添加回去，并且高亮设置为 false
          if (prevColumn) {
            const parent = context.viewGraph.originNodeMap.get(
              prevColumn.parent.index,
            ) as Table;
            prevColumn.highlight = false;
            parent.children.push(prevColumn);
            prevColumn = null;
            context.rerender();
          }
          return showNoMoreLinage(direction);
        }

        // 把之前打开的 Table 全部收起来
        context.viewGraph.currentNodeMap.forEach((item) => {
          if (item instanceof Table) {
            item.isOpen = false;
          }
        });
        nodes.forEach((item) => {
          item.children.forEach((child) => {
            child.highlight = true;
          });
        });

        edges.forEach((item) => {
          item.highlight = true;
        });

        data.highlight = true;
        if (data.isStartNode) {
          removeColumnByNode(
            data.parent.children.filter((item) => item.index !== data.index),
            EDirection.BOTH,
            context,
          );
        }
        context.expandGraphData(nodes, edges);

        // 打开了间接血缘开关，需要判断是否存在间接血缘的关系，有的话要把 table 展开
        // if (this.context.viewGraph.edgeDashVisible === EDashType.INDIRECT) {
        edges.forEach((edge) => {
          const { srcId, dstId } = edge;
          const srcNode = this.context.viewGraph.originNodeMap.get(srcId);
          const dstNode = this.context.viewGraph.originNodeMap.get(dstId);
          if (srcNode instanceof Column) {
            if (!srcNode.parent.isOpen) {
              srcNode.parent.isOpen = true;
            }
          }
          if (dstNode instanceof Column) {
            if (!dstNode.parent.isOpen) {
              dstNode.parent.isOpen = true;
            }
          }
        });
        // }

        // 高亮当前扩展的血缘
        context.dellColumnExpand(data);
        // 判断是否存在间接血缘，弹出提示
        this.checkIsShowIndirectLineage(
          newData.relations,
          direction,
          data.index,
        );
        prevColumn = data;
      } catch (e) {
        console.error(e);
      }
    }

    // hide loading
    // this.target.hideExpandLoadingIcon();
  }

  checkIsShowIndirectLineage(
    relations: IResRelation[],
    direction: EDirection,
    guid: string,
  ) {
    // 找出当前节点的直接节点的 relation
    const isInput = direction === EDirection.INPUT;

    const directRelations = relations.filter((relation) =>
      isInput ? relation.dstGuid === guid : relation.srcGuid === guid,
    );

    const hasIndirectLineage = directRelations.every(
      (relation) =>
        relation.relationTypeCode === ERelationType.TableIndirectTable ||
        relation.relationTypeCode === ERelationType.ColumnIndirectTable ||
        relation.relationTypeCode === ERelationType.ColumnIndirectColumn,
    );

    // 如果有间接血缘，同时没有打开间接血缘的开关，则弹出提示
    if (
      hasIndirectLineage &&
      this.context.viewGraph.edgeDashVisible === EDashType.DIRECT
    ) {
      showIndirectLineage(direction);
    }
  }

  get visible() {
    return this.image.isVisible();
  }

  setShape(x: number, y: number) {
    this.image.style.x = x;
    this.image.style.y = y;
    this.image.style.img = sideIconTypeObj[this.expandType];
  }

  setImg(img: string) {
    this.image.style.img = img;
  }

  show() {
    this.image.setAttribute('visibility', 'hidden');
  }

  hide() {
    this.image.setAttribute('visibility', 'hidden');
  }
}

export default ActionIcon;
