import * as Ink from '@aloudata/ink';
import Edge from '../../model/Edge';
import SQLGraph from '../../model/SQLGraph';
import { getEdgeEndNode } from '../../utils';
import { EDashType } from '@/components/TaskLineageView/typings';
import {
  getEdgeVisible,
  isTableEdge,
} from '@/components/TaskLineageView/utils/edge';
import { getEdgeStyle } from '@/components/TaskLineageView/view/EdgeView/style';
import { vec3 } from 'gl-matrix';
import { Cursor } from '@aloudata/ink/dist/displayObjects/types';
import { IElement } from '../../model/Graph';

import Table from '../../model/Table';
import Column from '@/components/TaskLineageView/model/Column';
import { TABLE_WIDTH } from '@/components/TaskLineageView/view/TableView/style';
import { roundUpToTenThousand } from '@/utils';
import { EGraphMode } from '@/components/TaskLineageView/atoms/overviewAtom';

const PF = require('pathfinding');

interface IProps {
  edge: Edge;
  wrapper: Ink.Group;
  context: SQLGraph;
  samePosEdgeMap: Map<string, EdgeView>;
}

class EdgeView {
  path: Ink.Path;

  props: IProps;

  focusOnMaker: boolean = false;

  focusOnPath: boolean = false;

  constructor(props: IProps) {
    this.props = props;
    this.props.edge.bindView(this);
    this.render();
  }

  render() {
    const { dstId, srcId, type, visible } = this.props.edge;
    const { context } = this.props;
    const src = context.viewGraph.originNodeMap.get(srcId);
    const dst = context.viewGraph.originNodeMap.get(dstId);

    if (!visible) return;

    const defaultPos = 0;
    const offset = 20;
    const arrowOffsetX = 11;
    const arrowOffsetY = 14;
    const arrowStartY = 10;
    const arrowEndY = 19;
    const defaultTargetOffset = 2;
    const x3Offset = 3;
    const arrowX1Offset = 8;
    const arrowX2Offset = 1;
    const arrowX3Offset = 6.5;
    const arrowY2Offset = 13.5;
    const arrowOffset15 = 1.5;
    const arrowOffset03 = 0.3;
    const arrowOffset05 = 0.5;

    const edgeData = getEdgeEndNode(src, dst);

    if (edgeData?.srcNode && edgeData?.dstNode) {
      const { srcNode, dstNode } = edgeData;
      let srcTransform: vec3 = vec3.create();
      let dstTransform: vec3 = vec3.create();

      srcTransform = srcNode.g.getPosition() || vec3.create();
      dstTransform = dstNode.g.getPosition() || vec3.create();

      // 剔除rootGroup的矩阵
      let [rootX, rootY, rootScaleX, rootScaleY] = [0, 0, 1, 1];
      const rootGroup = this.props.context.viewGraph.root;
      if (rootGroup && rootGroup.transformable) {
        const rootGroupTrans = rootGroup.transformable;

        rootScaleX = rootGroupTrans.scaling[0];
        rootScaleY = rootGroupTrans.scaling[1];
        rootX = rootGroupTrans.position[0];
        rootY = rootGroupTrans.position[1];
      }

      // 终点原始位置
      const [dstX = defaultPos, dstY = defaultPos] = [
        (dstTransform[0] - rootX) / rootScaleX,
        (dstTransform[1] - rootY) / rootScaleY,
      ];

      const DEFAULT_ROOT_X = 190; // 列的宽度
      const DEFAULT_ROOT_Y = 14; // 列一半的高度
      const startXOffset = 0;
      // 起点原始位置
      const [srcX = defaultPos, srcY = defaultPos] = [
        (srcTransform[0] +
          (DEFAULT_ROOT_X + startXOffset) * rootScaleX -
          rootX) /
          rootScaleX,
        (srcTransform[1] + DEFAULT_ROOT_Y * rootScaleY - rootY) / rootScaleY,
      ];

      // end
      const x1 = srcX + offset;
      const y1 = srcY;
      const x2 = dstX - offset - arrowOffsetX - defaultTargetOffset;
      const y2 = dstY + arrowOffsetY;
      const x3 = dstX - arrowOffsetX + x3Offset;
      const y3 = y2;

      const theta = Math.atan((y2 - y1) / (x2 - x1));
      const sx = x1 + offset * Math.cos(theta);
      const sy = y1 + offset * Math.sin(theta);
      const dx = x2 - offset * Math.cos(theta);
      const dy = y2 - offset * Math.sin(theta);

      const [arrowX1, arrowY1] = [dstX - arrowX1Offset, dstY + arrowStartY];
      const [arrowX2, arrowY2] = [dstX - arrowX2Offset, dstY + arrowY2Offset];
      const [arrowX3, arrowY3] = [dstX - arrowX3Offset, dstY + arrowEndY];
      const arrowA1X = arrowX1 + arrowOffset15;
      const arrowA1Y = arrowY1 - arrowOffset03;
      const arrowA2X = arrowX2 - arrowOffset03;
      const arrowA2Y = arrowY2 + arrowOffset15;
      const arrowA3X = arrowX3 - arrowOffset15;
      const arrowA3Y = arrowY3 - arrowOffset05;

      let d = `
        M${srcX} ${srcY}
        Q${x1} ${y1} ${sx} ${sy}
        L${dx} ${dy}
        Q${x2} ${y2} ${x3} ${y3}
        L${arrowX1} ${arrowY1}
        A 1 1, 0, 0, 1 ${arrowA1X} ${arrowA1Y}
        L${arrowX2} ${arrowY2}
        A 1 1, 0, 0, 1 ${arrowA2X} ${arrowA2Y}
        L${arrowX3} ${arrowY3}
        A 1 1, 0, 0, 1 ${arrowA3X} ${arrowA3Y}
        L${arrowX1} ${arrowY1}
        `;

      // 自环场景
      if (srcId === dstId) {
        const srcColumn = context.viewGraph.currentNodeMap.get(
          this.props.edge.srcId,
        );

        let offsetY = 5;
        if (srcColumn instanceof Column) {
          const columnPos = srcColumn.view.getPosition();
          const TablePos = srcColumn.parent.getPos();
          offsetY = columnPos[1] - TablePos[1] + 10;

          d = `
            M${srcX} ${srcY}
            L${sx + 6} ${y1}
            L${sx + 6} ${y1 - 2 * offset - offsetY}
            L${dx - 6} ${dy - 2 * offset - offsetY}
            L${dx - 6} ${dy}
            Q${x2} ${y2} ${x3} ${y3}
            L${arrowX1} ${arrowY1}
            A 1 1, 0, 0, 1 ${arrowA1X} ${arrowA1Y}
            L${arrowX2} ${arrowY2}
            A 1 1, 0, 0, 1 ${arrowA2X} ${arrowA2Y}
            L${arrowX3} ${arrowY3}
            A 1 1, 0, 0, 1 ${arrowA3X} ${arrowA3Y}
            L${arrowX1} ${arrowY1}
            `;
        } else {
          d = `
            M${srcX} ${srcY}
            L${sx} ${y1}
            L${sx} ${y1 - 2 * offset - offsetY}
            L${dx} ${dy - 2 * offset - offsetY}
            L${dx} ${dy}
            Q${x2} ${y2} ${x3} ${y3}
            L${arrowX1} ${arrowY1}
            A 1 1, 0, 0, 1 ${arrowA1X} ${arrowA1Y}
            L${arrowX2} ${arrowY2}
            A 1 1, 0, 0, 1 ${arrowA2X} ${arrowA2Y}
            L${arrowX3} ${arrowY3}
            A 1 1, 0, 0, 1 ${arrowA3X} ${arrowA3Y}
            L${arrowX1} ${arrowY1}
           `;
        }
      }
      // 回环场景
      else if (srcX > dstX) {
        let linePath = '';
        try {
          linePath = this.getPathForLoopBack({
            srcX,
            srcY,
            dx,
            dy,
            arrowX: x3,
            arrowY: y3,
          });
        } catch (e) {
          console.log(e);
        }

        d = linePath
          ? `
        ${linePath}
        Q${x2} ${y2} ${x3} ${y3}
         L${arrowX1} ${arrowY1}
        A 1 1, 0, 0, 1 ${arrowA1X} ${arrowA1Y}
        L${arrowX2} ${arrowY2}
        A 1 1, 0, 0, 1 ${arrowA2X} ${arrowA2Y}
        L${arrowX3} ${arrowY3}
        A 1 1, 0, 0, 1 ${arrowA3X} ${arrowA3Y}
        L${arrowX1} ${arrowY1}
        `
          : d;
      }
      const config = {
        name: 'edge',
        style: {
          path: d,
          fill: 'rgb(255, 255, 255,0)',
          stroke: getEdgeStyle(this.props.edge),
          // show stroke dash while indirect checkbox checked & edge relation type is indirect
          strokeDasharray: type === EDashType.INDIRECT ? [2] : [0],
          lineWidth: 1,
          zIndex: isTableEdge(this.props.edge.edgeType) ? -2 : -1,
          cursor: 'pointer' as Cursor,
        },
      };

      const path = new Ink.Path(config);
      this.path = path;

      (path as unknown as IElement).node = this;

      // 每添加过就先加一个 加了之后做记录
      this.props.wrapper.appendChild(path);

      // //   没有记录过 同起点 和 终点  做记录
      // if (!this.props.samePosEdgeMap.has(key)) {
      //   // 每添加过就先加一个 加了之后做记录
      //   this.props.wrapper.appendChild(path);

      //   this.props.samePosEdgeMap.set(key, this);
      // }
    }
  }

  getPathForLoopBack({
    srcX,
    srcY,
    dx,
    dy,
    arrowX,
    arrowY,
  }: {
    srcX: number;
    srcY: number;
    dx: number;
    dy: number;
    arrowX: number;
    arrowY: number;
  }) {
    const { context } = this.props;

    const boundingRect = context.viewGraph.root.getBBox();

    const step = 25;

    const width =
      Math.ceil(roundUpToTenThousand(boundingRect.width) / step) + 100;
    const height =
      Math.ceil(roundUpToTenThousand(boundingRect.height) / step) + 100;

    const grid = new PF.Grid(width, height);

    let offsetY = 0;
    let offsetX = 0;

    // 找出画布中最左侧和最下侧的表的坐标，以此将坐标系转成正数
    context.viewGraph.currentNodeMap.forEach((item) => {
      if (item instanceof Table) {
        const [tableX, tableY] = item.view.getPosition();
        if (offsetX > tableX) offsetX = tableX;
        if (offsetY > tableY) offsetY = tableY;
      }
    });
    if (offsetX < step * 3) {
      offsetX -= step * 3;
    }

    if (offsetY < step * 3) {
      offsetY -= step * 3;
    }

    // 各自留出20个单位的边距
    offsetY -= 20;
    offsetX -= 20;

    const not = [];
    context.viewGraph.currentNodeMap.forEach((item) => {
      if (item instanceof Table) {
        const TableHeight = item.view.getHeight();
        const [tableX, tableY] = item.view.getPosition();
        for (
          let x = Math.floor((tableX - offsetX) / step);
          x < Math.ceil((tableX - offsetX + TABLE_WIDTH) / step);
          x++
        ) {
          for (
            let y = Math.floor((tableY - offsetY) / step);
            y < Math.ceil((tableY - offsetY + TableHeight) / step) + 1;
            y++
          ) {
            not.push([x, y]);
            grid.setWalkableAt(x, y, false);
          }
        }
      }
    });
    //**************find start*******************

    // 使用A*算法查找路径
    const finder = new PF.AStarFinder({
      // allowDiagonal: true,
    });

    const _path = finder.findPath(
      Math.ceil((srcX + 30 - offsetX) / step),
      Math.ceil((srcY - offsetY) / step),
      Math.ceil((dx - offsetX) / step),
      Math.ceil((dy - offsetY) / step),
      grid,
    );
    const newPath = PF.Util.smoothenPath(grid, _path);
    if (newPath.length < 2) return '';

    newPath.map((item: Array<number>) => {
      item[0] = Math.ceil(item[0] * step + offsetX);
      item[1] = Math.ceil(item[1] * step + offsetY);
    });
    let d = ``;

    function generateBezierPath(points: Array<Array<number>>) {
      if (points.length < 2) {
        return '';
      }

      let path = `M${points[0][0]} ${points[0][1]}
            `;
      for (let i = 2; i < points.length; i++) {
        if (i === points.length - 2) {
          path += `Q${points[i][0]} ${points[i][1]} ${points[i + 1][0]} ${
            points[i + 1][1]
          }`;
          break;
        } else if (i < points.length - 1) {
          let x1 = points[i - 1][0] + (points[i][0] - points[i - 1][0]) / 3;
          const _x2 =
            points[i - 1][0] + ((points[i][0] - points[i - 1][0]) * 2) / 3;

          // 调整曲率，向内收
          if (i === 2) {
            x1 = points[i - 1][0] - (points[i][0] - points[i - 1][0]) / 2;
          }

          // 计算控制点
          const y1 = points[i - 1][1];

          const _y2 = points[i][1];

          // 结束点
          const x = points[i][0];
          const y = points[i][1];

          // 添加三次贝塞尔曲线命令
          path += ` C${x1} ${y1}, ${_x2} ${_y2}, ${x} ${y}`;
        }
      }
      return path;
    }

    let isClockwise = false; // 是否是顺时针方向
    if (newPath[1] && newPath[1][1] > newPath[0][1]) {
      isClockwise = true;
    }

    let isEndClockwise = false; // 结尾处是否是顺时针方向
    const length = newPath.length;
    if (newPath[length - 1][1] > newPath[length - 2][1]) {
      isEndClockwise = true;
    }

    // 移除首位，因为需要调整起止目标，
    // 起始是从 srcX + 20 开始的，所以需要移除首位
    newPath.shift();
    // 末尾是指向的箭头的位置，需要移除
    newPath.pop();

    d = generateBezierPath([
      [srcX, srcY],
      [srcX + 20, isClockwise ? srcY + 20 : srcY - 20],
      ...newPath,
      [arrowX - 20, isEndClockwise ? arrowY - 25 : arrowY + 25],
    ]);
    return d;
  }

  onEdgeClick() {
    const { edge, context } = this.props;
    const { edgeType, index } = edge;
    // 探索模式下不能点
    if (this.props.context.exploreState.mode === EGraphMode.EXPLORE) return;

    // 表到表的边不能点击
    if (isTableEdge(edgeType)) return;
    context.subViewGraph.currentClickId = [index];
    context.updateActiveItem(edge);
  }

  onMarkerClick() {
    const { context } = this.props;
    context.rerender();
  }

  onMouseEnter() {
    const { edge } = this.props;

    this.focusOnPath = true;

    // 表与表不能操作
    if (isTableEdge(edge.edgeType)) return;

    this.path.style.lineWidth = 1.5;
  }

  onMouseLeave() {
    const { edge, context } = this.props;
    const { currentClickId, edges } = context.subViewGraph;

    this.focusOnPath = false;

    this.path.style.lineWidth = 1;

    // 表与表不能操作
    if (isTableEdge(edge.edgeType)) return;
    if (currentClickId.includes(this.props.edge.index)) return;
    if (edges?.includes(this.props.edge)) return;
  }

  onMarkerMouseEnter() {
    this.focusOnMaker = true;
    // this.path.style.lineWidth = 2;
  }

  onMarkerMouseLeave() {
    // const { edge } = this.props;
    // const { inFoldLink } = edge;
    // this.focusOnMaker = false;
    // setTimeout(() => {
    //   if (inFoldLink && !this.focusOnPath) {
    //     this.path.style.markerMid = null;
    //   }
    //   if (!this.focusOnPath) {
    //     // this.path.style.lineWidth = 1;
    //   }
    // }, 0);
  }

  updateHighLight() {
    if (!this.path) return;

    const { currentClickId, edges } = this.props.context.subViewGraph;
    let lineWidth = 1;
    if (currentClickId.includes(this.props.edge.index)) {
      lineWidth = 1.5;
    } else {
      if (edges.includes(this.props.edge)) {
        lineWidth = 1.5;
      }
    }

    this.path.style.lineWidth = lineWidth;
  }

  rerender() {
    if (this.path) this.props.wrapper.removeChild(this.path);

    const { context, edge } = this.props;
    // 显示逻辑为起点终点都显示，且符合目前间接直接关系
    this.props.edge.visible = getEdgeVisible(context, edge);
    this.render();
  }

  isVisible() {
    return getEdgeVisible(this.props.context, this.props.edge);
  }

  destroy() {
    if (this.path) this.props.wrapper.removeChild(this.path);
  }
}

export default EdgeView;
