import abc from '@/components/TaskLineageView/images/abc.svg';
import {
  EElementType,
  EEdgeType,
  ICustomEntity,
  ITableProperties,
} from '@/services/lineage/type';
import sideCloseIcon from '../assets/icons/close.svg';
import sideExpandIcon from '../assets/icons/expand.svg';
import Column from '../model/Column';
import Table from '../model/Table';
import { EEntityType } from '@/components/TaskLineageView/components/type';
import { EExpandType } from '@/components/TaskLineageView/components/ActionIcon';
import Edge from '@/components/TaskLineageView/model/Edge';
import TableView from '@/components/TaskLineageView/view/TableView';
import ColumnView from '@/components/TaskLineageView/view/ColumnView';
import { EDataSourceType, EDirection } from '@/typings';

export interface IProperties {
  name: string;
  guid: string;
}

export interface IColProperties extends IProperties {
  dataType: string;
  caliber?: string;
  description?: string;
  isCorrelation?: boolean;
  tableName?: string; // 实际列返回的有这个字段，并且isCorrelation 已经不存在
  position?: number;
  typeCode?: string;
  constraint?: string; // v 0.6
  highlight?: boolean; // v 0.6
}

// 表的类型
export enum ETableType {
  // 视图
  VIEW = 'VIEW',
  // 表
  TABLE = 'TABLE',
  // 临时表
  TEMP_TABLE = 'TEMP_TABLE',
}

export interface ITableModelProps extends IProperties {
  type: ETableType;
  typeCode: string;
}

export interface ICustomEntityProps {
  [key: string]: any;
}

export interface IViewEdge {
  type: string;
  visible: boolean;
  active: boolean;
  highlight: boolean;
}

export interface IEdge extends Partial<IViewEdge> {
  index: string;
  srcId: string;
  dstId: string;
  showExpand?: boolean;
}

export interface IPopover {
  title: string;
  visible: boolean;
  content: string;
  left: number;
  top: number;
}

export interface IContextmenuState {
  visible: boolean;
  guid: string;
  parentGuid?: string;
  left: number;
  top: number;
  type: EElementType;
  typeCode: string;
  direction?: EDirection;
}

export interface IInputCommonProperties extends IProperties {
  entityType: EEntityType;
  isStartNode?: boolean;
}

export interface IInputEntity extends IInputCommonProperties {
  typeCode?: string;
  properties?: ITableProperties | ICustomEntity;
}

export interface IUserDatabase extends IInputCommonProperties {
  children: IUserTable[];
}

export type TInputEntity = IUserDatabase | IInputEntity;

export interface IUserTable extends IInputCommonProperties {
  columnCount: number;
  isStartNode?: boolean;
  type: string;
  typeCode: string;
  assetPath: string;
  datasourceType: EDataSourceType;
  children: IUserColumn[];
  direction?: EDirection;
}

export interface IUserColumn extends IColProperties {}

export interface IUserEdge {
  edgeType: EEdgeType;
  srcId: string;
  dstId: string;
  type: EDashType;
  fold: boolean; // v0.6 added
  showExpand?: boolean; // v0.6 added
  foldTableId?: string; // v0.6 added
  inFoldLink?: boolean; // v0.6 added
  highlight?: boolean; // v0.6 added
}

export type Events = {
  [key: string]: unknown;
};

export enum EDashType {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
  ALL = 'ALL',
}

export enum EEventEnum {
  NODES_CHANGE = 'nodesChange',
  POPOVER_CHANGE = 'popoverChange',
  SUBVIEW_CHANGE = 'subviewChange',
  SIDE_ICON_STATE_CHANGE = 'sideIconStateChange',
  CONTEXT_MENU_CHANGE = 'contextmenuChange',
  // 概览
  OVERVIEW_CHANGE = 'overviewChange',

  // 探索
  EXPLORE_CHANGE = 'exploreChange',
}

export interface ISideIconState {
  data?: TNodeItem;
  caliberX?: number;
}

export const sideIconTypeObj = {
  [EExpandType.EXPAND]: sideExpandIcon,
  [EExpandType.CLOSE]: sideCloseIcon,
};

export enum ENodeType {
  TABLE,
  COLUMN,
}

export enum EColumnDataType {
  TEXT = 'TEXT',
  BINARY = 'BINARY',
  BOOLEAN = 'BOOLEAN',
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  MIXED = 'MIXED',
  DATE = 'DATE',
  TIME = 'TIME',
  DATETIME = 'DATETIME',
  LIST = 'LIST',
  MAP = 'MAP',
  GEO = 'GEO',
  OTHER = 'OTHER',
}

// 所有第二层的节点类型
export type TInnerNodeItem = Table;
export type TNodeItem = TInnerNodeItem | Column;
export type TActiveItem = TNodeItem | Edge;
export type TNodeViewItem = TableView | ColumnView;
// 所有最外层的节点类型
export type TOuterNodeItem = Table;

export const typeMap = {
  [EColumnDataType.TEXT]: { icon: 'Abc', name: '文本', iconUrl: abc },
  [EColumnDataType.BINARY]: { icon: 'Varbinary', name: '二进制', iconUrl: abc },
  [EColumnDataType.BOOLEAN]: { icon: 'Boolean', name: '布尔', iconUrl: abc },
  [EColumnDataType.FLOAT]: { icon: 'float', name: '小数', iconUrl: abc },
  [EColumnDataType.INTEGER]: { icon: 'tag', name: '整数', iconUrl: abc },
  [EColumnDataType.DECIMAL]: {
    icon: 'decimal_',
    name: '高精度小数',
    iconUrl: abc,
  },
  [EColumnDataType.MIXED]: { icon: 'mixed1', name: '混合', iconUrl: abc },
  [EColumnDataType.DATE]: { icon: 'calendar_line', name: '日期', iconUrl: abc },
  [EColumnDataType.TIME]: { icon: 'schedule', name: '时间', iconUrl: abc },
  [EColumnDataType.DATETIME]: {
    icon: 'a-calendartime_line',
    name: '日期时间',
    iconUrl: abc,
  },
  [EColumnDataType.LIST]: { icon: 'list', name: '列表', iconUrl: abc },
  [EColumnDataType.MAP]: { icon: 'map', name: '键值', iconUrl: abc },
  [EColumnDataType.GEO]: { icon: 'GEO', name: '地理', iconUrl: abc },
  [EColumnDataType.OTHER]: { icon: 'other', name: '其他', iconUrl: abc },
};

export enum ELineageStatus {
  // 多输出
  MULTI_OUTPUT = 'MULTIPLE_OUTPUT',
  // 链路中断
  LINK_BREAK = 'LINK_BREAK',
  // 多链路
  MULTI_LINK = 'MULTI_LINK',
  // 部分解析失败
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
}

export const lineStatusTitleMap = {
  [ELineageStatus.MULTI_OUTPUT]: '多输出表',
  [ELineageStatus.LINK_BREAK]: '链路中断',
  [ELineageStatus.MULTI_LINK]: '多链路',
  [ELineageStatus.PARTIAL_SUCCESS]: '部分解析失败',
};

export const lineStatusTipMap = {
  [ELineageStatus.MULTI_OUTPUT]: '任务血缘内存在两个及以上的输出物理表资产',
  [ELineageStatus.LINK_BREAK]:
    '如果任务血缘中存在无下游的临时表，或者有仅作为上游输入的临时表，那么任务血缘中存在链路中断的情况',
  [ELineageStatus.MULTI_LINK]:
    '任务血缘内存在两条及以上的单独链路，链路之间没有任何直接或者间接血缘连接',
  [ELineageStatus.PARTIAL_SUCCESS]: '请点击跳转查看对应报错信息',
};
