// 溯源行裁剪分析 中文
export const traceAnalysis = {
  // 链路溯源盘点
  traceAnalysis: '溯源分析',
  // 等待中
  init: '初始化',
  // 执行中
  processing: '执行中',
  // 执行成功
  finish: '执行成功',
  // 执行失败
  fail: '执行失败',
  // 处理终止
  terminated: '处理终止',

  // 报告名称
  fileName: '报告名称',
  // 状态
  status: '状态',

  // 新建溯源行裁剪分析成功
  createCropAnalysisSuccess: '新建溯源分析成功',
  // 新建溯源行裁剪分析失败
  createCropAnalysisFailure: '新建溯源分析失败',
  // 跳转查看
  jumpToView: '跳转查看',
  // 待变更表
  waitChangeTable: '待变更表',
  // 待变更字段
  waitChangeField: '待变更字段',
  // 分析深度
  analysisDepth: '分析深度',

  // 刷新
  refresh: '刷新',

  // 重新执行
  reExecute: '重新执行',
  // 报告重新执行成功
  rerunSuccess: '报告重新执行成功',
  // 报告重新执行失败
  rerunFailure: '报告重新执行失败',

  // 是否确认删除该报告
  confirmDelete: '是否确认删除该报告',
  confirmDeleteSubTitle: '删除报告后将不可恢复，确认删除吗？',

  // 删除成功
  deleteSuccess: '报告删除成功',
  // 删除失败
  deleteFailure: '报告删除失败',
};
