import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import Column from '@/components/TaskLineageView/model/Column';
import { ERelationType, ILineageQuery } from '@/services/lineage/type';
import { EDirection } from '@/typings';

export function getColumnListParams(
  guidList: string[],
  column: Column,
  context: SQLGraph,
  direction: EDirection,
  depth: number,
  extendAll?: boolean,
  needIndirect?: boolean,
) {
  if (!depth) {
    depth = 1;
    // 如果需要默认展开已展开表深度的列，就打开注释
    // const tablePath = getLayerTablePath(
    //   [column.parent],
    //   context.viewGraph.originEdgeMap,
    //   context.viewGraph.originNodeMap,
    //   direction,
    //   {
    //     edgeType: EDashType.ALL,
    //     isOnlyShowRelatedTable: false,
    //   },
    //   2,
    // );

    // tablePath.shift();

    // depth = tablePath.length || 1;
  }

  const params: ILineageQuery = {
    depth,
    direction,
    guids: guidList,
    relations: [
      ERelationType.ColumnDirectColumn,
      // ERelationType.TableDirectTable,
      // ERelationType.ColumnIndirectTable, // 列到表 间接
    ],
    entityType: 'Column',
    extendAll: extendAll || false,
    needIndirect,
  };

  // tablePath.flat().forEach((item) => {
  //   params.tableGuidList.push(deparseGuid(item.index));
  // });

  return params;
}
