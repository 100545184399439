import * as Renderer from '@aloudata/ink';
import { SIDE_ICON_SIZE } from '../../constants';
import sideExpandLoadingIcon from '../../assets/icons/expandLoading.svg';
import { vec3 } from 'gl-matrix';
import SubGraph from '../../model/SubGraph';

class Loading {
  parent: SubGraph;

  image: Renderer.Image;

  visible: boolean = false;

  constructor() {
    this.render();
  }

  render() {
    this.image = new Renderer.Image({
      name: 'loading',
      style: {
        width: SIDE_ICON_SIZE,
        height: SIDE_ICON_SIZE,
        img: sideExpandLoadingIcon,
        zIndex: 100,
        visibility: 'hidden',
      },
    });
  }

  show() {
    this.image.style.visibility = 'visible';
  }

  hide() {
    this.image.style.visibility = 'hidden';
  }

  setPosition(pos: vec3) {
    this.image.setPosition(pos);
  }
}

export default Loading;
