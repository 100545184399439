import {
  groupByTable,
  parseColumnGuid,
  parseGuid,
  parseTableGuid,
} from '@/utils/index';
import {
  EElementType,
  EEdgeType,
  ERelationType,
  IEntity,
  ILineageDataRes,
  ITableProperties,
} from '@/services/lineage/type';
import {
  EDashType,
  IUserEdge,
  IUserTable,
  TInputEntity,
} from '@/components/TaskLineageView/typings';
import { EDirection } from '@/typings';
import { EEntityType } from '@/components/TaskLineageView/components/type';
import { directionMap } from '@/pages/DataAsset/Task/components/Lineage';

export function transTableData(
  res: ILineageDataRes,
  tableId?: string,
): {
  nodes: TInputEntity[];
  edges: IUserEdge[];
} {
  const { entities: resNodes, relations } = res;

  const nodes: TInputEntity[] = groupByTable(resNodes, tableId);

  const edges: IUserEdge[] = [];
  const edgesMap: Map<string, IUserEdge> = new Map();
  if (relations.length) {
    relations.forEach((item) => {
      const { relationTypeCode, srcGuid, dstGuid, fold } = item;
      const { edgeType, relationType } = getEdgeType(relationTypeCode);

      if (edgesMap.has(`${srcGuid}-${dstGuid}`)) {
        const { type } = edgesMap.get(`${srcGuid}-${dstGuid}`)!;
        // 出现了重复的关系，直接跳过
        if (type === relationType) return;
        // 出现了不同类型的关系，改为 ALL
        edgesMap.get(`${srcGuid}-${dstGuid}`)!.type = EDashType.ALL;
        return;
      }
      const srcEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const dstEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const srcId = parseGuid(srcGuid, srcEntityType);
      const dstId = parseGuid(dstGuid, dstEntityType);
      const edge: IUserEdge = {
        edgeType,
        srcId,
        dstId,
        type: relationType,
        fold,
      };
      edgesMap.set(`${srcGuid}-${dstGuid}`, edge);
      edges.push(edge);
    });
  }

  return {
    edges,
    nodes,
  };
}

// task data parse here-----------------
export function parseResultData(res: ILineageDataRes): {
  nodes: IUserTable[];
  edges: IUserEdge[];
} {
  const { entities, relations } = res;

  // nodes
  const nodes = parseEntities(entities);

  // edges
  const edges: IUserEdge[] = [];
  const edgesMap: Map<string, IUserEdge> = new Map();

  if (relations.length) {
    relations.forEach((item) => {
      const { relationTypeCode, srcGuid, dstGuid, fold } = item;
      // if (isCircleEdge(item)) return;
      let edgeType = EEdgeType.T_T;
      let relationType = EDashType.DIRECT;
      switch (relationTypeCode) {
        case ERelationType.TableDirectTable:
          edgeType = EEdgeType.T_T;
          relationType = EDashType.DIRECT;
          break;
        case ERelationType.TableIndirectTable:
          edgeType = EEdgeType.T_T;
          relationType = EDashType.INDIRECT;
          break;
        case ERelationType.ColumnDirectColumn:
          edgeType = EEdgeType.C_C;
          relationType = EDashType.DIRECT;
          break;
        case ERelationType.ColumnIndirectTable:
          edgeType = EEdgeType.C_C;
          relationType = EDashType.INDIRECT;
          break;
      }
      if (edgesMap.has(`${srcGuid}-${dstGuid}`)) {
        const { type } = edgesMap.get(`${srcGuid}-${dstGuid}`)!;
        // 出现了重复的关系，直接跳过
        if (type === relationType) return;
        // 出现了不同类型的关系，改为 ALL
        edgesMap.get(`${srcGuid}-${dstGuid}`)!.type = EDashType.ALL;
        return;
      }
      const srcEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const dstEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const srcId = parseGuid(srcGuid, srcEntityType);
      const dstId = parseGuid(dstGuid, dstEntityType);
      const edge: IUserEdge = {
        edgeType,
        srcId,
        dstId,
        type: relationType,
        fold,
      };
      edgesMap.set(`${srcGuid}-${dstGuid}`, edge);
      edges.push(edge);
    });
  }

  return {
    edges,
    nodes,
  };
}

export function parseEntities(resNodes: IEntity[]): IUserTable[] {
  const nodes: IUserTable[] = [];

  resNodes.forEach((entity) => {
    if (entity.typeCode === 'Table' || entity.typeCode === 'View') {
      let exist = false;
      nodes.forEach((node) => {
        if (node.guid === entity.guid) exist = true;
      });

      const direction = directionMap[entity.guid];
      const isStartNode =
        (direction === EDirection.INPUT ||
          direction === EDirection.OUTPUT ||
          direction === EDirection.BOTH) &&
        entity.properties.type !== 'TEMP_TABLE';

      if (!exist) {
        const props = entity.properties as ITableProperties;
        const table = {
          guid: parseTableGuid(entity.guid),
          name: props.name,
          isStartNode,
          columnCount: props.columnCount,
          typeCode: entity.typeCode,
          type: props.type,
          assetPath: props.assetPath,
          entityType: EEntityType.TABLE,
          datasourceType: props.datasourceType,
          direction: directionMap[entity.guid],
          children:
            props?.columns?.map((column) => {
              return {
                dataType: column.dataType,
                guid: parseColumnGuid(column.guid),
                name: column.name || ' ',
                description: column.description,
                typeCode: column?.typeCode || 'Column',
                position: column.position,
                isStartNode,
              };
            }) || [],
        };

        nodes.push(table);
      }
    }
  });
  return nodes;
}

export function getEdgeType(relationTypeCode: ERelationType) {
  let edgeType = EEdgeType.T_T;
  let relationType = EDashType.DIRECT;

  switch (relationTypeCode) {
    case ERelationType.TableDirectTable:
      edgeType = EEdgeType.T_T;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.TableIndirectTable:
      edgeType = EEdgeType.T_T;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.ColumnDirectColumn:
      edgeType = EEdgeType.C_C;
      relationType = EDashType.DIRECT;
      break;
    // case ERelationType.ColumnIndirectColumn:     // V0.6 removed
    //   edgeType = EEdgeType.C_C;
    //   relationType = EDashType.INDIRECT;
    //   break;
    case ERelationType.ColumnIndirectTable:
      edgeType = EEdgeType.C_C;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.ColumnDirectCustom: // v1.0.0 add
      edgeType = EEdgeType.C_CE;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.ColumnIndirectCustom: // v1.0.0 add
      edgeType = EEdgeType.C_CE;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.TableDirectCustom: // v1.0.0 add
      edgeType = EEdgeType.T_CE;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.TableIndirectCustom: // v1.0.0 add
      edgeType = EEdgeType.T_CE;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.CustomDirectCustom: // v1.0.0 add
      edgeType = EEdgeType.CE_CE;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.CustomIndirectCustom: // v1.0.0 add
      edgeType = EEdgeType.CE_CE;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.CustomDirectColumn: // v1.0.0 add
      edgeType = EEdgeType.CE_C;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.CustomIndirectColumn: // v1.0.0 add
      edgeType = EEdgeType.CE_C;
      relationType = EDashType.INDIRECT;
      break;
    case ERelationType.CustomDirectTable: // v1.0.0 add
      edgeType = EEdgeType.CE_T;
      relationType = EDashType.DIRECT;
      break;
    case ERelationType.CustomIndirectTable: // v1.0.0 add
      edgeType = EEdgeType.CE_T;
      relationType = EDashType.INDIRECT;
      break;
  }
  return {
    edgeType,
    relationType,
  };
}
