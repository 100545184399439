export enum ELayoutType {
  TREE,
  SANKEY,
  VETICALLY,
  DAGRE,
}

export interface ILayoutOptions {
  // 可视化区域宽度
  width: number;
  // 可视化区域高度
  height: number;
  // x轴偏移量，如有提供，布局结果会固定x轴的偏移
  transformX?: number;
  // y轴偏移量，如有提供，布局结果会固定y轴的偏移
  transformY?: number;
  layout: ELayoutType;
}
