// import { commonSplitGuid, deparseGuid, parseColumnGuid } from '@/utils';
//
// const a =
//   'database.test_hive_schema_0902.nf_sss_tt.nf_sss_tt.`customer.info`.`lzl.aid`';
// const b = 'database.test_hive_schema_0902.nf_sss_tt.nf_sss_tt.`customer.info`';
//
// const parsedId = parseColumnGuid(a);
// const parsedb = parseColumnGuid(b);
// const deparsedIdb = deparseGuid(parsedb);
// const deparsedId = deparseGuid(parsedId);
//
// console.log(parsedId, deparsedId, a === deparsedId);
// console.log(parsedb, deparsedIdb, b === deparsedIdb);
import pkg from '../package.json';
import { parseUserInfoFromUrl } from '@/utils/report';

(window as unknown as { bigVersion: string }).bigVersion = pkg.version;

// eslint-disable-next-line no-console
console.log('bigVersion', pkg.version);
parseUserInfoFromUrl();
