import { atom } from 'recoil';
import SQLGraph from '../model/SQLGraph';
import { EOverviewType } from '@/typings/overview';

// 单例SQLGraph
// const sqlGraph = new SQLGraph();

export const SQLGraphAtom = atom<SQLGraph>({
  key: 'taskSqlGraph3',
  default: new SQLGraph(),
  dangerouslyAllowMutability: true,
});

export interface ISidebarRouter {
  type: EOverviewType;
  guid: string;
}

export const SidebarRouterAtom = atom<ISidebarRouter[]>({
  key: 'taskSidebarRouter3',
  default: [],
});
