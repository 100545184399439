import { Menu } from '@aloudata/aloudata-design';
import { useRecoilValue } from 'recoil';
import { SQLGraphAtom } from '../../atoms/sqlGraphAtom';
import { IContextmenuState } from '../../typings';
import styles from './index.less';
import { mat4, vec3 } from 'gl-matrix';
import { logReport } from '@/utils/report';
import { EReportTag } from '@/constants/report';
import { EElementType } from '@/services/lineage/type';
import { isIframeEnv } from '@/constants/global';
import { postMessageToParentWithId } from '@/utils/iframe';
import { EDirection } from '@/typings';

const RightKeyMenu = (props: IContextmenuState) => {
  const { guid, type, direction, parentGuid } = props;
  const sqlGraph = useRecoilValue(SQLGraphAtom);
  const { originNodeMap } = sqlGraph.viewGraph;
  const currentNode = originNodeMap?.get(guid);
  const nodeView = currentNode?.view;
  if (!nodeView) return;

  const camera = sqlGraph.viewGraph.wrapper.getCamera();

  const translation = mat4.getTranslation(
    vec3.create(),
    camera.getOrthoMatrix(),
  );

  const zoom = camera.getZoom();
  const top = props.top * zoom + translation[1];
  const left = props.left * zoom + translation[0];

  const items = sqlGraph.overviewState.id
    ? []
    : [
        {
          key: 'windowOpen',
          label: '以此节点展开页面',
          onClick: () => {
            if (isIframeEnv) {
              postMessageToParentWithId(guid);
            } else {
              openNewWindow();
            }
            sqlGraph.hideContextmenu();
          },
        },
      ];

  const InputItem = {
    key: 'expandInput',
    label: '溯源探索',
    onClick: () => {
      logReport(EReportTag.TASKLINEAGE_CANVAS_EXPLORE_CLICK);
      sqlGraph.expandInput();
    },
  };

  const OutputItem = {
    key: 'expandOutput',
    label: '影响探索',
    onClick: () => {
      logReport(EReportTag.TASKLINEAGE_CANVAS_EXPLORE_CLICK);
      sqlGraph.expandOutput();
    },
  };

  // 根据不同的方向，展示不同的菜单
  if (direction === EDirection.BOTH) {
    items.push(OutputItem);
    items.push(InputItem);
  } else if (direction === EDirection.INPUT) {
    items.push(OutputItem);
  } else if (direction === EDirection.OUTPUT) {
    items.push(InputItem);
  }

  // 新窗口打开
  const openNewWindow = () => {
    const { origin } = window.location;
    const tableGuid = type === EElementType.COLUMN ? parentGuid : guid;
    const url = `${origin}/#/dataAsset/table?code=Table&guid=${tableGuid}`;
    window.open(url);
  };

  return (
    <div
      data-testid="components-TaskLineageView2-components-RightKeyMenu-index-div-1"
      className={styles.contextmenu}
      style={{
        left,
        top,
      }}
    >
      <Menu
        data-testid="components-TaskLineageView2-components-RightKeyMenu-index-Menu-2"
        items={items}
      />
    </div>
  );
};

export default RightKeyMenu;
