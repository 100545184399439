import { EDashType, IUserEdge, IUserTable, TNodeItem } from '../typings';
import Edge from './Edge';
import SQLGraph, {
  TNodeMap,
} from '@/components/TaskLineageView/model/SQLGraph';
import EdgeView from '@/components/TaskLineageView/view/EdgeView';
import Table from '@/components/TaskLineageView/model/Table';
import { difference } from 'lodash';
import { Canvas, DisplayObject, Group, Image, Rect, Text } from '@aloudata/ink';
import ColumnView from '../view/ColumnView';
import TableView from '../view/TableView';
import ActionIcon from '../components/ActionIcon';
import { getTextDomWidth, nodesSort } from '../utils';
import { EventManager } from '../events';
import triangleIcon from '../assets/icons/triangle.svg';

export interface IElement extends DisplayObject {
  node: TableView | ColumnView | EdgeView | ActionIcon;
}

export default class Graph {
  wrapper: Canvas;

  root!: Group;

  parent: SQLGraph;

  width?: number;

  height?: number;

  nodes!: Table[];

  edges!: Edge[];

  originNodeMap: TNodeMap = new Map<string, TNodeItem>();

  originEdgeMap = new Map<string, Edge>();

  currentNodeMap: TNodeMap = new Map<string, TNodeItem>();

  samePosEdgeMap = new Map<string, EdgeView>(); // 用来记录同起始点的EdgeView

  // 虚实控制，影响血缘图中虚线和实线的显示
  edgeDashVisible: EDashType = EDashType.DIRECT; // 默认为直接血缘

  // 是否显示相关表的控制
  isOnlyShowRelatedTable: boolean = false;

  isDragGraph: boolean = false;

  fullNameView: Rect;

  textCanvasCtx: CanvasRenderingContext2D;

  currCorrelationColumnId: string;

  read(
    nodes: IUserTable[],
    edges: IUserEdge[],
    parent: SQLGraph,
    rootDom: HTMLElement,
  ) {
    this.initBasicView(rootDom);

    // bind parent context  -- SQLGraph
    this.parent = parent;

    // init node model & save model
    const newNodes = nodes.map((node) => {
      // init node data model
      const tableDataModel = new Table(node, this.parent);
      // add node origin data in originNodeMap
      this.addOriginNode(tableDataModel);

      return tableDataModel;
    });
    // 进行排序处理
    nodesSort(newNodes);
    this.nodes = newNodes;

    // init edge model & save model
    this.edges = edges.map((item) => {
      const edgeDataModel = new Edge(item);
      this.addOriginEdge(edgeDataModel);

      return edgeDataModel;
    });
  }

  /**
   * init graph view
   *
   * @param {HTMLElement} root
   * @memberof Graph
   */
  initBasicView(root: HTMLElement) {
    // canvas like window
    this.width = root.clientWidth;
    this.height = root.clientHeight;
    this.wrapper = new Canvas({
      width: root.clientWidth,
      height: root.clientHeight,
      root,
      scrollDisable: false,
      zoomDisable: true,
    });

    // root group
    this.root = new Group();
    this.wrapper.appendChild(this.root);

    const textCanvas = document.createElement('canvas');
    this.textCanvasCtx = textCanvas.getContext('2d');
  }

  /**
   * bind all event on graph
   *
   * @memberof Graph
   */
  initListener() {
    const eventManager = new EventManager(this);
    eventManager.initEvents();
  }

  // 添加原始数据
  addOriginNode(node: Table) {
    const originTable = this.originNodeMap.get(node.index) as Table;

    // 如果已经存在，需要将其下的列添加到原始数据中
    if (originTable) {
      (node as Table).children.forEach((item) => {
        if (!this.originNodeMap.has(item.index)) {
          this.originNodeMap.set(item.index, item);

          const isExist = originTable.children.some(
            (column) => column.index === item.index,
          );
          if (!isExist) originTable.children.push(item);

          item.parent = originTable;
        }
      });

      // this.originNodeMap.set(node.index, originTable);
    } else {
      (node as Table).children.forEach((item) => {
        this.originNodeMap.set(item.index, item);
      });

      this.originNodeMap.set(node.index, node);
    }
  }

  addOriginEdge(edge: Edge) {
    this.originEdgeMap.set(edge.index, edge);
  }

  removeOriginNode(node: TNodeItem) {
    if (node instanceof Table) {
      node.children.forEach((item) => {
        this.originNodeMap.delete(item.index);
      });
    }
    this.originNodeMap.delete(node.index);
  }

  removeOriginEdge(edge: Edge) {
    if (edge && this.originEdgeMap.has(edge.index)) {
      this.originEdgeMap.delete(edge.index);
      this.edges = this.edges.filter((item) => item.index !== edge.index);
    }
  }

  rerender() {
    this.clear();
    this.render();
  }

  clear() {
    // clear view in canvas
    this.root.removeChildren();
    // clear rendered data
    this.currentNodeMap.clear();
  }

  render() {
    this.renderNodes();
    // this.renderEdges();
  }

  renderNodes() {
    this.nodes?.forEach((item) => {
      new TableView({
        parentNode: this.root,
        data: item,
        context: this.parent,
      });
    });
  }

  renderEdges() {
    this.edges.forEach((edge: Edge) => {
      new EdgeView({
        edge,
        wrapper: this.root,
        context: this.parent,
        samePosEdgeMap: this.samePosEdgeMap,
      });
    });
  }

  setNodes(nodes: Table[]) {
    // this.removeNodes();
    // 进行排序处理
    nodesSort(nodes);

    this.nodes = nodes;
  }

  remove(nodes?: TNodeItem[], edges?: Edge[]) {
    // remove origin edges
    edges?.forEach((item) => {
      this.originEdgeMap.delete(item.index);
    });

    // remove edges
    if (edges) this.edges = difference(this.edges, edges);

    // remove origin nodes
    nodes?.forEach((item) => {
      if (item instanceof Table) {
        const hasLineage = this.edges.some(
          (edge) => edge.dstId === item.index || edge.srcId === item.index,
        );
        if (!hasLineage) {
          this.remove(item.children);
          this.removeOriginNode(item);
        }
      } else {
        this.removeOriginNode(item);
      }
    });
  }

  update(nodes: IUserTable[], edges: IUserEdge[]) {
    // this.nodes.forEach((item) => {
    //   if (item.children.length) {
    //     item.children.length = 0;
    //   }
    // });

    const newNodes = nodes
      .map((item) => {
        if (this.originNodeMap.has(item.guid)) {
          const originNode = this.originNodeMap.get(item.guid);
          if (originNode instanceof Table) {
            originNode.update(item);
          }
          return null;
        } else {
          // init node data model
          const tableDataModel = new Table(item, this.parent);
          // 将其下的表和列都添加到原始数据中
          this.addOriginNode(tableDataModel);
          return tableDataModel;
        }
      })
      .filter((item) => item !== null);

    const newEdges = edges.map((item) => {
      const isExist = this.originEdgeMap.has(`${item.srcId}-${item.dstId}`);
      if (!isExist) {
        const edge = new Edge(item);
        this.addOriginEdge(edge);
        return edge;
      }
    });

    // 进行排序处理
    nodesSort(newNodes);
    this.nodes.push(...newNodes);
    this.edges.push(...newEdges);
  }

  showRelatedTable(isShow: boolean) {
    this.isOnlyShowRelatedTable = isShow;
  }

  moveTargetToCenter(target: DisplayObject) {
    // move to center
    const [x, y] = target.getPosition();
    const { height, width } = target.getBBox();

    const canvasW = this.wrapper.getConfig().width;
    const canvasH = this.wrapper.getConfig().height;

    this.wrapper.getCamera().resetPosition();
    this.wrapper
      .getCamera()
      .pan(x - canvasW / 2 + width / 2, y - canvasH / 2 + height / 2);
  }

  refresh() {
    // this.wrapper.refresh();
  }

  showFullName(id: string) {
    const target = this.parent.viewGraph?.originNodeMap?.get(id);
    if (!target) return;

    const { name } = target;
    if (!name || name.trim().length < 1) return;

    if (!this.fullNameView) {
      this.fullNameView = new Rect({
        style: {
          width: 190,
          height: 28,
          radius: 2,
          fill: '#000000',
          opacity: 0.8,
        },
      });

      const text = new Text({
        style: {
          text: name,
          fontSize: 12,
          fill: '#FCFCFD',
          x: 8,
          y: 18,
          fontFamily: 'PingFangSC',
        },
      });

      const triangle = new Image({
        style: {
          x: 0,
          y: 0,
          width: 10,
          height: 4,
          img: triangleIcon,
        },
      });

      this.fullNameView.appendChild(text);
      this.fullNameView.appendChild(triangle);
      this.wrapper.appendChild(this.fullNameView);
    } else {
      this.fullNameView.children[0].style.text = name;
    }

    // 更新宽度
    const textWidth = getTextDomWidth(this.textCanvasCtx, name) + 20;
    this.fullNameView.style.width = textWidth;

    // 获取target的位置
    const [x, y] = target.view.getPosition();
    this.fullNameView.style.x = x;
    this.fullNameView.style.y = y - 33;
    this.fullNameView.style.visibility = 'visible';

    const triangle = this.fullNameView.children[1];
    triangle.style.x = textWidth / 2 - 3;
    triangle.style.y = 28;
  }

  hideFullName() {
    if (this.fullNameView) {
      this.fullNameView.style.visibility = 'hidden';
    }
  }

  resetPosition() {
    this.wrapper.getCamera().resetPosition();
  }
}
