// 变更影响分析的国际化 中文
export const lineageReport = {
  //待变更资产
  waitChangeAssets: '待变更资产',
  // 分析类型
  analysisType: '分析类型',
  // 变更类型
  changeType: '变更类型',
  // 报告类型
  reportType: '报告类型',
  // 用户分析报告
  userAnalysisReport: '用户分析报告',
  // 系统分析报告
  systemAnalysisReport: '系统分析报告',
  // 状态
  status: '状态',
  //操作
  operate: '操作',
  // 下载报告
  downloadReport: '下载报告',
  // 重新执行
  reExecute: '重新执行',
  // 重新执行成功
  reExecuteSuccess: '重新执行成功',
  // 重新执行失败
  reExecuteFailure: '重新执行失败',
  // 共 X 份报告
  totalReport: (length: number) => {
    return `共 ${length} 份报告`;
  },
  // 请选择
  pleaseSelect: '请选择',
  // 暂无内容
  noContent: '暂无内容',
  // 变更影响分析
  changeImpactAnalysis: '变更影响分析',
  // 链路终端盘点
  assetLineageReport: '终端分析',
  // 新建报告
  createReport: '新建报告',
  // 是否确认删除该报告
  confirmDelete: '是否确认删除该报告',
  confirmDeleteSubTitle: '删除报告后将不可恢复，确认删除吗？',
  // 删除成功
  deleteSuccess: '报告删除成功',
  // 删除失败
  deleteFailure: '报告删除失败',
  // 等待
  init: '等待',
  // 已完成
  success: '已完成',
  // 失败
  fail: '失败',
  // 执行中
  process: '执行中',
  // 报告重新执行成功
  rerunSuccess: '报告重新执行成功',
  // 报告重新执行失败
  rerunFailure: '报告重新执行失败',
};
