export const relation = {
  relationName: '关系名称',
  relationNameEmpty: '暂无名称',
  relationType: '关系类型',
  view: '查看',
  addRelation: '新增关系',

  viewSource: '查看数据来源',
  delSourceTitle:
    '删除数据来源会同步删除数据源中的采集任务，是否确认删除数据源',
  order: '序号',
  dataSource: '数据源',
  databaseName: '数据库',
  tableName: '数据表',

  editRelation: '编辑关系',
  deleteRelation: '删除关系',
  sureAndLink: '确认并跳转',
  isDeleteRelationTitle: '是否删除关系',
  attrName: '属性名称',
  attrDesc: '属性描述',
  attrNumberType: '属性数值类型',
  updateTime: '更新时间',
  source: '来源',
  addAttr: '新增属性',
  addSource: '新增数据来源',
  addSourceSubTitle: '通过数据源连接的内容，配置实体的来源数据映射。',
  baseInfo: '基础信息',
  headTailRelation: '头尾关系',
  attr: '属性',
  attrEmpty: '暂无属性',
  deleteRelationTips: (ingestionTaskCount: number) => {
    return `当前关系存已配置 ${ingestionTaskCount} 个采集任务，请优先删除采集任务后再删除关系。`;
  },
  deleteAttrTips: (ingestionTaskCount: number) => {
    return `当前关系属性存已配置 ${ingestionTaskCount} 个采集任务，请优先删除采集任务后再删除关系属性。`;
  },
  deleteAttrTitle: '删除关系属性',
  isDeleteAttrTitle: '是否删除关系属性',
  editAttr: '编辑属性',
  nameRequiredTips: '属性名称不能为空',
  namePatternTips: '仅支持中文、字母、数字、下划线',
  attrEnName: '属性英文名',
  attrEnNameRequiredTips: '属性英文名不能为空',
  attrEnNamePatternTips: '仅支持字母、数字、下划线并以字母开头',
  attrDescLenTips: '属性描述不能超过200个字符',
  attrNumberTypeRequiredTips: '属性数值类型不能为空',
  attrNumberTypePlaceholder: '请选择数值类型',

  relationNameRequired: '关系名称不能为空',
  relationEnName: '关系英文名',
  relationEnNameRequired: '关系英文名不能为空',
  relationDesc: '关系描述',
  relationDescLen: '关系描述不能超过200个字符',
  headRelation: '头关系',
  headRelationRequired: '头关系不能为空',
  tailRelation: '尾关系',
  tailRelationRequired: '尾关系不能为空',

  defaultEntityRelationTips: '内置实体之间无法创建血缘关系',
};
