import { ScrollArea, Spin, Tooltip } from '@aloudata/aloudata-design';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import OverviewTypeIcon from '../OverviewTypeIcon';
// import sqlFormatter from 'sql-formatter';
import ReactCodeMirror from '@uiw/react-codemirror';
import { extensions, myTheme } from '@/components/RightPanelComponentsNew/SQL';
import styles from './index.less';
import { copyContent } from '@/utils';
import { ReactComponent as CopyLine } from '@/assets/copy-line.svg';
import { EOverviewType } from '@/typings/overview';
import useExploreData from '@/components/TaskLineageView/hooks/useExploreData';
import { EGraphMode } from '@/components/TaskLineageView/atoms/overviewAtom';
import { useSearchParams } from 'umi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TaskAtom } from '@/components/TaskLineageView/atoms/TaskAtom';
import { getLinageSimulationTask } from '@/services/sceneCenter';
import { taskOverviewAtom } from '@/pages/DataAsset/Task/atoms';

const Task = () => {
  const [searchParams] = useSearchParams();
  const { mode } = useExploreData();
  const taskOverview = useRecoilValue(taskOverviewAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [overviewData, setOverviewData] = useState<{
    taskName: string;
    content: string;
    copyContent: string;
  }>();
  const setTaskInfo = useSetRecoilState(TaskAtom);
  const guid = searchParams.get('guid');
  const id = searchParams.get('id');

  const { taskName: taskOverviewTaskName, content: taskOverviewContent } =
    taskOverview || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const res = await getLinageSimulationTask(id);
          const { rawScriptStr, taskName } = res;
          setOverviewData({
            taskName,
            content: rawScriptStr || '',
            copyContent: rawScriptStr,
          });
          setIsLoading(false);
        } else {
          // const contentFormatter = sqlFormatter.format(
          //   taskOverviewContent || '',
          // );
          const contentFormatter = taskOverviewContent || '';
          setOverviewData({
            taskName: taskOverviewTaskName,
            content: contentFormatter,
            copyContent: contentFormatter,
          });
          if (contentFormatter) setIsLoading(false);
        }
      } catch (e) {}
    };

    if ((guid || id) && mode === EGraphMode.NORMAL) {
      fetchData();
    }
  }, [guid, mode, id, taskOverviewContent, taskOverviewTaskName]);

  const { content, taskName, copyContent: copyContentStr } = overviewData || {};

  useEffect(() => {
    if (taskName) {
      setTaskInfo({ taskName });
    }
  }, [taskName, setTaskInfo]);

  return (
    <div className={styles.taskContainer}>
      <div className={classNames(styles.taskHeader)}>
        <OverviewTypeIcon type={EOverviewType.TASK} />
        <Tooltip title={overviewData?.taskName}>
          <span className={styles.taskName}>{overviewData?.taskName}</span>
        </Tooltip>

        <span>任务内容</span>
      </div>

      <div
        className={classNames(styles.scrollArea, {
          [styles.simulationScrollArea]: !!id,
        })}
      >
        <ScrollArea>
          {isLoading ? (
            <Spin className={styles.spin} />
          ) : (
            <ReactCodeMirror
              value={content || '暂无 SQL 内容'}
              theme={myTheme()}
              extensions={extensions}
              readOnly={true}
            />
          )}
          {copyContent ? (
            <CopyLine
              className={classNames(styles.copyIcon, {
                [styles.simulationCopyIcon]: !!id,
              })}
              size={20}
              fill="#171717"
              onClick={() => {
                copyContent(copyContentStr);
              }}
            />
          ) : null}
        </ScrollArea>
      </div>
    </div>
  );
};

export default Task;
