import { ReactComponent as MaximizeLine } from '@/assets/maximize-line.svg';
import styles from './index.less';
import { basicSetup, useCodeMirror } from '@uiw/react-codemirror';
import sqlFormatter from 'sql-formatter';
import { useEffect, useRef, useState } from 'react';
import { sql as codemirrorSql } from '@codemirror/lang-sql';
import { Button, Modal, ScrollArea } from '@aloudata/aloudata-design';
import { ReactComponent as CopyLine } from '@/assets/copy-line.svg';
import { copyContent } from '@/utils';
import createTheme, { Settings } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import { EditorView } from '@codemirror/view';
import { TagStyle } from '@codemirror/language';

export const extensions = [
  basicSetup({ foldGutter: false }),
  EditorView.lineWrapping,
  codemirrorSql(),
];

export const myTheme = (settings?: Settings, themeStyles?: TagStyle[]) => {
  let defaultSettings = {
    background: '#F1F4FD', // 背景色
    foreground: '#575757', // 非关键字体的颜色
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#E6EAFA', // 高亮列的背景色
    gutterBackground: '#F1F4FD', // 侧边栏的背景色
    gutterForeground: '#A2A2A2', // 侧边栏的字体色
    gutterBorder: '#F1F4FD', // 侧边栏的border色
    gutterActiveForeground: '#5c6166', // 侧边栏的高亮列的字体色
  };
  if (settings) {
    defaultSettings = { ...defaultSettings, ...settings };
  }

  const defaultStyles: TagStyle[] = [
    { tag: t.comment, color: 'green', lineHeight: '60px' }, // 注释
    { tag: t.variableName, color: '#5c6166' },
    { tag: [t.string, t.special(t.brace)], color: '#B82828' }, // 字符串的颜色
    { tag: t.number, color: '#F28E2C' }, // 数字的颜色
    { tag: t.bool, color: '#5c6166' },
    { tag: t.null, color: '#5c6166' },
    { tag: t.keyword, color: '#3769AD' }, // 关键字体的颜色
    { tag: t.operator, color: '#0A9953' }, // 运算符的颜色，比如+ - =
    { tag: t.className, color: '#5c6166' },
    { tag: t.definition(t.typeName), color: '#5c6166' },
    { tag: t.typeName, color: '#5c6166' },
    { tag: t.angleBracket, color: '#5c6166' },
    { tag: t.tagName, color: '#5c6166' },
    { tag: t.attributeName, color: '#5c6166' },
    { tag: t.comment, color: 'green' }, // 注释
  ];

  if (themeStyles) {
    defaultStyles.concat(themeStyles);
  }

  return createTheme({
    theme: 'light',
    settings: defaultSettings,
    styles: defaultStyles,
  });
};

const Sql = ({
  sql,
  title,
}: {
  sql: string;
  title: string;
  height: string | number;
}) => {
  const sqlEditor = useRef();
  const modalSqlEditor = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const formatSql = sqlFormatter.format(sql || '暂无SQL内容');
  const { setContainer: setSqlContainer } = useCodeMirror({
    container: sqlEditor.current,
    extensions,
    value: formatSql,
    editable: false,
    readOnly: true,
    theme: myTheme(),
  });

  useEffect(() => {
    if (sql) {
      setSqlContainer(sqlEditor.current);
    }
  }, [setSqlContainer, sql]);

  const { setContainer } = useCodeMirror({
    container: modalSqlEditor.current,
    extensions,
    value: formatSql,
    editable: false,
    readOnly: true,
    theme: myTheme(),
  });

  useEffect(() => {
    if (isOpenModal) {
      setContainer(modalSqlEditor.current);
    }
  }, [isOpenModal, setContainer]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <span>{title}</span>
        <MaximizeLine
          size={20}
          className={styles.icon}
          onClick={() => {
            setIsOpenModal(true);
          }}
          color="#171717"
        />
      </div>
      {/* <ScrollArea className={styles.scrollArea}> */}
      <div ref={sqlEditor} className={styles.sqlEditor} />
      {/* </ScrollArea> */}
      {sql ? (
        <Button
          className={styles.copyIcon}
          icon={
            <CopyLine
              size={20}
              fill="#171717"
              onClick={() => {
                copyContent(formatSql);
              }}
            />
          }
        />
      ) : null}

      <Modal
        open={isOpenModal}
        title={title}
        footer={null}
        wrapClassName={styles.sqlModal}
        width="100vw !important"
        paddingLess={true}
        mask={false}
        onCancel={() => {
          setIsOpenModal(false);
        }}
      >
        <ScrollArea className={styles.modalScrollArea}>
          <div ref={modalSqlEditor} />
        </ScrollArea>
      </Modal>
    </div>
  );
};

export default Sql;
