// 列文字
export const COL_TEXT_Y = 18;
export const COL_TEXT_X = 37;
export const COL_TEXT_NO_ICON_X = 18;

// 列描述
export const COL_TEXT_DESCRIBE_X = 272;

// 口径摘要
export const FX_ICON_Y = 10;
export const FX_ICON_X = 220;
export const FX_ICON_WIDTH = 13;
export const FX_ICON_HEIGHT = 13;
export const FX_ICON_LEFT_OFFSET = 3;
export const FX_ICON_TOP_OFFSET = 4;

// 列icon
export const COL_TYPE_ICON_X = 15;
export const COL_TYPE_ICON_Y = 6;
export const COL_TYPE_ICON_WIDTH = 16;
export const COL_TYPE_ICON_HEIGHT = 16;

// 列的序号
export const COL_ORDER_WIDTH = 26;
export const COL_ORDER_HEIGHT = 16;
export const COL_ORDER_X = 8;
export const COL_ORDER_R = 4;

export const COL_ORDER_TEXT_X = 11;
export const COL_ORDER_TEXT_Y = 18;
