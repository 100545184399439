// 溯源行裁剪分析 中文
export const cropAnalysis = {
  // 链路溯源盘点
  inputCropAnalysis: '裁剪分析',
  // 等待中
  init: '初始化',
  // 处理中
  processing: '处理中',
  // 已完成
  finish: '处理完成',
  // 失败
  fail: '处理失败',
  // 处理终止
  terminated: '处理终止',

  // 文件名称
  fileName: '文件名称',
  // 状态
  status: '状态',

  // 新建溯源行裁剪分析成功
  createCropAnalysisSuccess: '新建溯源行裁剪分析成功',
  // 新建溯源行裁剪分析失败
  createCropAnalysisFailure: '新建溯源行裁剪分析失败',
  // 跳转查看
  jumpToView: '跳转查看',
  // 待变更表
  waitChangeTable: '待变更表',
  // 待变更字段
  waitChangeField: '待变更字段',
  // 分析深度
  analysisDepth: '分析深度',

  // 刷新
  refresh: '刷新',
};
