import { EAssetsType, EDataSourceType, EDirection } from '@/typings';

export enum EElementType {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN',
  CUSTOM = 'CUSTOM',
}

export enum EEdgeType {
  // 列-列
  C_C = 'C_C',
  // 列-表
  C_T = 'C_T',
  // 表-表
  T_T = 'T_T',
  // 列-自定义  new
  C_CE = 'C_CE',
  // 表-自定义 new
  T_CE = 'T_CE',
  // 自定义-自定义 new
  CE_CE = 'CE_CE',
  // 自定义-表 new
  CE_T = 'CE_T',
  // 自定义-列 new
  CE_C = 'CE_C',
}

export interface IResColumn {
  guid: string;
  name: string;
  dataType: string;
  description?: string;
  tableName: string;
  position: number;
  typeCode?: string;
  constraint?: string; // v0.6
  // properties?: ITableProperties | ICustomEntity;
}

export enum ERelationType {
  // 表到表 直接关系
  TableDirectTable = 'TableDirectTable',
  // 表到表 间接关系
  TableIndirectTable = 'TableIndirectTable',
  // 列到列 直接关系
  ColumnDirectColumn = 'ColumnDirectColumn',
  // 列到列 间接关系
  ColumnIndirectColumn = 'ColumnIndirectColumn',
  // 列到表  间接关系
  ColumnIndirectTable = 'ColumnIndirectTable',
  // 列到自定义  直接关系 new
  ColumnDirectCustom = 'ColumnDirectCustom',
  // 列到自定义  间接关系 new
  ColumnIndirectCustom = 'ColumnIndirectCustom',
  // 表到自定义  直接关系 new
  TableDirectCustom = 'TableDirectCustom',
  // 表到自定义  间接关系 new
  TableIndirectCustom = 'TableIndirectCustom',
  // 自定义到自定义  直接关系 new
  CustomDirectCustom = 'CustomDirectCustom',
  // 自定义到自定义  间接关系 new
  CustomIndirectCustom = 'CustomIndirectCustom',
  // 自定义到表  间接关系 new
  CustomIndirectTable = 'CustomIndirectTable',
  // 自定义到表 直接关系 new
  CustomDirectTable = 'CustomDirectTable',
  // 自定义到列  直接关系 new
  CustomDirectColumn = 'CustomDirectColumn',
  // 自定义到列  间接关系 new
  CustomIndirectColumn = 'CustomIndirectColumn',
}

export interface IResRelation {
  srcGuid: string;
  dstGuid: string;
  relationTypeCode: ERelationType;
  fold: boolean;
  properties?: any;
}

export interface ILineageQuery {
  guids?: string[];
  entityType?: string; // v0.6 TABLE COLUMN
  taskGuid?: string;
  relations: string[];
  direction: EDirection;
  depth: number;
  extendAll?: boolean;
  needIndirect?: boolean;
}

export interface ITableProperties {
  guid: string;
  name: string;
  type: string;
  databaseName: string;
  group: string;
  columnCount: number;
  columns: IResColumn[];
  assetPath: string;
  datasourceType: EDataSourceType;
}

export interface ICustomEntity {
  [key: string]: string | number | boolean | ICustomEntity;
}

export interface IEntity {
  guid: string;
  typeCode: string;
  typeCodeName: string;
  properties: ITableProperties | ICustomEntity;
  empty?: boolean;
}

export interface ILineageDataRes {
  relations: IResRelation[];
  entities: IEntity[];
}

// export interface IColumnCaliber {
//   columnGuid: string;
//   caliberGuid: string;
// }

export interface IColumnCaliber {
  columnGuid: string;
  processGuid: string;
  type: EAssetsType;
}

export interface IColumnCaliberOverview {
  columnGuid: string;
  type: string;
}

export interface IColumnListRes {
  guid: string;
  name: string;
  columns: IResColumn[];
}

export interface IProcessExpressionListItem {
  processGuid: string;
  expression: string;
  taskId: string;
  taskGuid: string;
  taskName: string;
  processType: string;
}

export interface IColumnCaliberOverviewRes {
  processType: string;
  processExpressionList: IProcessExpressionListItem[];
  ok: boolean;
}

// export interface IColumnCaliberRes {
//   bizCaliber: string;
//   expression: string;
//   sql: string;
//   tableSql: string;
// }
