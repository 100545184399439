import { EElementType, IColumnListRes } from '@/services/lineage/type';
import { parseColumnGuid } from '@/utils';
import { COL_Y_NUM, RECT_LINE_WIDTH } from '../../constants';
import tableShowLess from '../../images/showLess.svg';
import tableShowMore from '../../images/showMore.svg';
import tableFooterShowMore from '../../images/tableFooterShowMore.svg';
import SQLGraph from '../../model/SQLGraph';
import Table from '../../model/Table';
import {
  getActiveSqlIdsById,
  getOverviewType,
  getTextFromTableType,
  showExploreTipNoSql,
  showExploreTipNotInActiveElement,
} from '../../utils';
import ColumnView from '../ColumnView';
import {
  RECT_BORDER_RADIUS,
  TABLE_FOOTER_HEIGHT,
  TABLE_FOOTER_IMAGE_HEIGHT,
  TABLE_FOOTER_IMAGE_WIDTH,
  TABLE_FOOTER_IMAGE_X,
  TABLE_FOOTER_IMAGE_Y,
  TABLE_FOOTER_TEXT2_X,
  TABLE_HEADER_HEIGHT,
  TABLE_MARGIN,
  TABLE_OPERATE_ICON_HEIGHT,
  TABLE_OPERATE_ICON_WIDTH,
  TABLE_OPERATE_ICON_X,
  TABLE_OPERATE_ICON_Y,
  TABLE_WIDTH,
} from './style';
import Column from '@/components/TaskLineageView/model/Column';
import ActionIcon, {
  EExpandType,
  EIconPositionType,
} from '@/components/TaskLineageView/components/ActionIcon';
import { ETableType } from '@/components/TaskLineageView/typings';
import { getColumnList } from '@/services/lineage';
import Loading from '../../components/Loading';
import { vec3 } from 'gl-matrix';
import { IElement } from '../../model/Graph';
import { getDataSourceTypeIcon } from '@/components/DataSourceTypeIcon/getDataSourceTypeIcon';
import { EGraphMode } from '@/components/TaskLineageView/atoms/overviewAtom';
import { Event, Group, Image, Rect, Text } from '@aloudata/ink';
import Edge from '../../model/Edge';
import { EDirection } from '@/typings';
import { getLinageSimulationColumns } from '@/services/sceneCenter';

interface IProps {
  parentNode: Group;
  data: Table;
  context: SQLGraph;
}

class TableView {
  props: IProps;

  headerGroup: Group;

  colGroup: Group;

  footerGroup: Group;

  bgRect: Rect;

  g: Group;

  tableContentGroup: Group;

  rect: Rect;

  tableToggleImage?: Image; // 展开收起图标

  columns: ColumnView[] = [];

  highLightChildren: (Image | Text | Rect)[];

  leftIcon: ActionIcon;

  rightIcon: ActionIcon;

  expandLoadingIcon: Loading;

  headerName: Text;

  headerAssetPath: Text;

  headerNodeType: Text;

  footerToggleImage: Image;

  footerCountText: Text;

  footerBgRect: Rect;

  constructor(props: IProps) {
    this.props = props;

    // bind rendered table data
    const { data } = this.props;
    this.props.context.viewGraph.currentNodeMap.set(data.index, data);
    data.bindView(this);

    this.render();
  }

  render() {
    const { data, parentNode } = this.props;
    const { isOpen } = data;

    // table root group
    this.g = new Group({
      style: { x: 0, y: 0 },
    });

    // 渲染输入资产、输出资产的 tag
    this.renderDirectionTag();

    // render table header
    this.renderHeader();

    // render table content
    if (isOpen) this.renderTableContent();

    // append to parent node
    parentNode.appendChild(this.g);

    // save table height
    this.updateRectHeight();
  }

  renderDirectionTag() {
    let directionWord = '';

    // 临时表不需要展示
    if (this.props.data._data_.type === ETableType.TEMP_TABLE) return;

    if (this.props.data.direction === EDirection.INPUT) {
      directionWord = '任务输入资产';
    } else if (this.props.data.direction === EDirection.OUTPUT) {
      directionWord = '任务输出资产';
    } else if (this.props.data.direction === EDirection.BOTH) {
      directionWord = '输入资产\\输出资产';
    }

    if (!directionWord) return;

    const tagRect = new Rect({
      style: {
        x: 0,
        y: -21,
        width: TABLE_WIDTH,
        height: 20,
        stroke: '#F1F1F1',
        fill: '#F1F1F1',
        lineWidth: 1,
        radius: [2, 2, 0, 0],
      },
    });

    const group = new Group({
      name: 'direction-header-group',
      style: {
        zIndex: 1,
      },
    });

    const directionText = new Text({
      style: {
        x: 8,
        y: -15,
        lineHeight: 16,
        text: directionWord,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'PingFang SC',
        textBaseline: 'top',
        fill: '#A2A2A2',
      },
    });

    group.appendChild(tagRect);
    group.appendChild(directionText);

    this.g.appendChild(group);
  }

  renderHeader() {
    const { data } = this.props;
    const { isOpen, name, assetPath, _data_, datasourceType } = data;

    // table header 的group
    this.headerGroup = new Group({
      name: 'table-header-group',
      style: {
        zIndex: 1,
      },
    });
    (this.headerGroup as IElement).node = this;

    this.bgRect = new Rect({
      style: {
        stroke: '#E8E8E8',
        fill: '#FFFFFF',
        lineWidth: RECT_LINE_WIDTH,
        width: TABLE_WIDTH,
        height: TABLE_HEADER_HEIGHT,
        radius: isOpen
          ? [RECT_BORDER_RADIUS, RECT_BORDER_RADIUS, 0, 0]
          : RECT_BORDER_RADIUS,
      },
    });

    this.headerName = new Text({
      style: {
        x: 8,
        y: 10,
        text: name,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 16,
        fontFamily: 'PingFang SC',
        textBaseline: 'top',
        wordWrap: true,
        wordWrapWidth: 155,
        maxLines: 1,
        textOverflow: 'ellipsis',
      },
    });

    const headerIcon = new Image({
      style: {
        x: 8,
        y: 32,
        width: 12,
        height: 12,
        img: getDataSourceTypeIcon(datasourceType) as string,
        cursor: 'pointer',
      },
    });

    const { assetPathWordWrapWidth, nodeTypeX, nodeTypeWordWrapWidth } =
      this.getNodeWidthByNodeType(_data_.type);

    this.headerAssetPath = new Text({
      style: {
        x: 24,
        y: 33,
        lineHeight: 16,
        textOverflow: 'ellipsis',
        text: assetPath,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'PingFang SC',
        textBaseline: 'top',
        wordWrap: true,
        wordWrapWidth: assetPathWordWrapWidth,
        maxLines: 1,
        textAlign: 'start',
        fill: '#858585',
      },
    });

    this.headerNodeType = new Text({
      style: {
        x: nodeTypeX,
        y: 33,
        lineHeight: 16,
        textOverflow: 'ellipsis',
        text: getTextFromTableType(_data_.type),
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'PingFang SC',
        textBaseline: 'top',
        fill: '#A2A2A2',
        wordWrap: true,
        wordWrapWidth: nodeTypeWordWrapWidth,
        maxLines: 1,
      },
    });

    this.tableToggleImage = new Image({
      name: 'table-header-toggle-image',
      style: {
        x: TABLE_OPERATE_ICON_X,
        y: TABLE_OPERATE_ICON_Y,
        width: TABLE_OPERATE_ICON_WIDTH,
        height: TABLE_OPERATE_ICON_HEIGHT,
        img: isOpen ? tableShowLess : tableShowMore,
        cursor: 'pointer',
        visibility: 'hidden',
      },
    });

    this.headerGroup.appendChild(this.bgRect);

    this.headerGroup.appendChild(this.headerName);
    this.headerGroup.appendChild(headerIcon);
    this.headerGroup.appendChild(this.headerAssetPath);
    this.headerGroup.appendChild(this.headerNodeType);
    this.headerGroup.appendChild(this.tableToggleImage);

    this.leftIcon = new ActionIcon(this, EIconPositionType.LEFT);
    this.rightIcon = new ActionIcon(this, EIconPositionType.RIGHT);
    this.expandLoadingIcon = new Loading();

    this.headerGroup.appendChild(this.leftIcon.image);
    this.headerGroup.appendChild(this.rightIcon.image);
    this.headerGroup.appendChild(this.expandLoadingIcon.image);

    this.g.appendChild(this.headerGroup);
  }

  getNodeWidthByNodeType(type: ETableType) {
    switch (type) {
      case ETableType.TEMP_TABLE:
        return {
          nodeTypeX: 148,
          assetPathWordWrapWidth: 119,
          nodeTypeWordWrapWidth: 36,
        };
      case ETableType.VIEW:
        return {
          nodeTypeX: 159,
          assetPathWordWrapWidth: 131,
          nodeTypeWordWrapWidth: 24,
        };
      case ETableType.TABLE:
        return {
          nodeTypeX: 170,
          assetPathWordWrapWidth: 143,
          nodeTypeWordWrapWidth: 12,
        };
      default:
        return {
          nodeTypeX: 0,
          assetPathWordWrapWidth: 0,
          nodeTypeWordWrapWidth: 0,
        };
    }
  }

  renderTableContent() {
    const { data, context } = this.props;
    const { children, columnCount = 0 } = data;

    // table content group
    this.tableContentGroup = new Group({
      style: {
        y: TABLE_HEADER_HEIGHT - 1,
        zIndex: 2,
      },
    });

    // table  columns group
    this.colGroup = new Group({
      style: { y: 0, zIndex: 1 },
    });

    // 用于列的高度计算
    let preNum = COL_Y_NUM;
    children.forEach((item, index) => {
      const { isCorrelation, highlight } = item;

      // 如果不是起始表的列，也不是相关列，也不是显示全部列，就不创建该列
      if (!data.isShowAll && !highlight) return;

      if (!data.isStartNode && !highlight && !data.isShowAll) return;

      // 如果是显示全部列 或者显示相关列，都要做此计算，用于列的y的偏移
      if (data.isShowAll || isCorrelation || highlight) preNum += 1;

      const columnView = new ColumnView({
        parentNode: this.colGroup,
        data: item,
        num: preNum,
        context,
        parentData: data,
        operatorConfig: { index, lastIndex: children.length - 1 },
      });

      this.columns.push(columnView);
    });

    // table footer
    const colGHeight = this.colGroup.getBBox().height; // 列group的高度

    // column count
    const columnCountStr = '' + columnCount;

    // table footer group
    const footerY = colGHeight > 0 ? colGHeight - 5 : colGHeight + 1;
    this.footerGroup = new Group({
      name: 'table-footer-group',
      style: {
        x: 0,
        y: footerY,
        zIndex: 0,
      },
    });
    (this.footerGroup as IElement).node = this;

    this.footerBgRect = new Rect({
      style: {
        x: 0,
        y: 0,
        width: TABLE_WIDTH,
        height: 25,
        fill: '#FFF',
        stroke: '#E8E8E8',
        lineWidth: 1,
        radius: [0, 0, RECT_BORDER_RADIUS, RECT_BORDER_RADIUS],
      },
    });

    this.footerCountText = new Text({
      style: {
        x: TABLE_FOOTER_TEXT2_X - columnCountStr.length * 4,
        y: TABLE_FOOTER_HEIGHT,
        text: `全部${columnCountStr}列`,
        fill: '#98A2B3',
        fontSize: '11px',
        fontWeight: 400,
        cursor: 'pointer',
      },
    });

    this.footerToggleImage = new Image({
      style: {
        x: TABLE_FOOTER_IMAGE_X,
        y: TABLE_FOOTER_IMAGE_Y,
        width: TABLE_FOOTER_IMAGE_WIDTH,
        height: TABLE_FOOTER_IMAGE_HEIGHT,
        img: data.isShowAll ? tableShowLess : tableFooterShowMore,
        zIndex: 1,
        cursor: 'pointer',
      },
    });

    this.tableContentGroup.appendChild(this.colGroup);

    this.footerGroup.appendChild(this.footerBgRect);
    this.footerGroup.appendChild(this.footerCountText);
    this.footerGroup.appendChild(this.footerToggleImage);
    this.tableContentGroup.appendChild(this.footerGroup);

    this.g.appendChild(this.tableContentGroup);
  }

  renderStartNodeTag() {
    const tagGroup = new Group({
      name: 'tag',
    });

    const tagRect = new Rect({
      style: {
        x: 0,
        y: -50,
        width: TABLE_WIDTH,
        height: 21,
        stroke: '#F1F1F1',
        fill: '#F1F1F1',
        lineWidth: 1,
        radius: [2, 2, 0, 0],
      },
    });

    const tagText = new Text({
      style: {
        x: 8,
        y: -43,
        lineHeight: 16,
        text: '起始资产',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'PingFang SC',
        textBaseline: 'top',
        fill: '#A2A2A2',
      },
    });
    tagGroup.appendChild(tagRect);
    tagGroup.appendChild(tagText);
    this.g.appendChild(tagGroup);
  }

  showDetailPanel = async () => {
    const { data, context } = this.props;
    const { guid, type } = data._data_;
    // 将data同步到atom，触发更新
    const { userClosed, visible } = context.overviewState;

    context.setOverview({
      ...context.overviewState,
      targetId: guid,
      type: getOverviewType(type),
      visible: !(userClosed && !visible),
      initial: true,
      direction: data.direction,
      name: data._data_.name,
    });
  };

  openTable() {
    const tableHeight = this.g.getBBox().height;
    this.props.data.setHeight(tableHeight);
    this.props.data.toggle(true);
  }

  closeTable() {
    this.props.data.toggle(false);
  }

  // 处理当前database下的所有table的偏移量
  updateAllTableOffsetY() {
    let prevTableHeight = 0;
    this.props.parentNode.children.forEach((item) => {
      item.style.y = prevTableHeight;
      prevTableHeight = prevTableHeight + item.style.height + TABLE_MARGIN;
    });
  }

  updateRectHeight() {
    const rectHeight = this.g.getBBox().height;
    // 同步table的height
    this.props.data.setHeight(rectHeight);
  }

  showSideIcon() {
    const { data } = this.props;

    this.leftIcon.hide();
    this.rightIcon.hide();

    this.props.context.changeSideIconState({ data });
  }

  hideSideIcon() {
    this.leftIcon.hide();
    this.rightIcon.hide();
  }

  showExpandLoadingIcon() {
    this.expandLoadingIcon.show();
  }

  hideExpandLoadingIcon() {
    this.expandLoadingIcon.hide();
  }

  setExpandLoadingIconPosition(pos: vec3) {
    this.expandLoadingIcon.setPosition(pos);
  }

  updateHighLight() {
    const { currentClickId, currentRightClickId, nodes } =
      this.props.context.subViewGraph;

    let stroke = '#E8E8E8';
    let fill = '#fff';
    let textFill = '#171717';
    let assetPathFill = '#858585';
    let zIndex = 1;

    if (this.props.context.exploreState.mode === EGraphMode.EXPLORE) {
      if (currentClickId.includes(this.props.data.index)) {
        stroke = '#FFB01F';
        fill = '#FFFAF0';
        textFill = '#171717';
        zIndex = 3;
      } else {
        if (nodes.includes(this.props.data)) {
          stroke = '#497FCC';
          fill = '#F2F5FA';
          zIndex = 3;
        }
      }
    } else {
      if (currentClickId.includes(this.props.data.index)) {
        stroke = '#3769AD';
        fill = '#3769AD';
        textFill = '#FFFFFF';
        assetPathFill = '#D5D5D5';
        zIndex = 3;
      } else {
        if (nodes.includes(this.props.data)) {
          stroke = '#497FCC';
          fill = '#F2F5FA';
          zIndex = 3;
        }
      }
    }

    if (currentRightClickId.includes(this.props.data.index)) {
      stroke = '#3271C9';
      zIndex = 3;
    }

    this.bgRect.setAttribute('stroke', stroke);
    this.bgRect.setAttribute('fill', fill);
    this.headerName.setAttribute('fill', textFill);
    this.headerAssetPath.setAttribute('fill', assetPathFill);
    this.headerNodeType.setAttribute('fill', assetPathFill);
    this.headerGroup.setAttribute('zIndex', zIndex);
  }

  // 获取table下的列
  getTableChild = async (guid: string, context: SQLGraph, data: Table) => {
    let res: IColumnListRes;
    if (context.overviewState?.id) {
      res = await getLinageSimulationColumns({
        guid,
        id: context.overviewState.id,
      });
    } else {
      res = await getColumnList(guid);
    }
    if (!res) return;
    this.props.data.children = res.columns.map((item) => {
      const isHighlight = this.props.data.children.some(
        (child) => child.index === item.guid && child.highlight,
      );

      return new Column(
        {
          ...item,
          guid: parseColumnGuid(item.guid),
          isCorrelation: this.props.data.isStartNode,
          highlight: isHighlight,
        },
        this.props.data,
      );
    });

    let newRelationColCount = 0;
    // 用于列的相关与不相关计算y
    let newPreNum = COL_Y_NUM;
    this.props.data.children.forEach((item) => {
      const { isCorrelation, highlight } = item;
      if (isCorrelation || highlight) {
        newPreNum += 1;
        newRelationColCount += 1;
      }
      const columnView = new ColumnView({
        parentNode: this.colGroup,
        data: item,
        num: newPreNum,
        context,
        parentData: data,
      });
      this.columns.push(columnView);
      context.viewGraph.currentNodeMap.set(item._data_.guid as string, item);
      item.bindView(columnView);
    });

    return { newRelationColCount, columnListLen: res.columns.length };
  };

  getHeight() {
    return this.g.getBBox().height;
  }

  onHeaderClick(e: Event) {
    // exclude table header toggle icon
    if ((e.target as IElement).name === 'table-header-toggle-image') return;
    // exclude table header side icon
    if ((e.target as IElement).name === 'sideIcon-img') return;
    // highlight table
    const { context, data } = this.props;
    const { index } = data;
    // 探索模式下，点击表头不触发高亮，调用木子的接口，显示右侧的 sql
    if (this.props.context.exploreState.mode === EGraphMode.EXPLORE) {
      const { activeElements } = this.props.context.subViewGraph;
      if (activeElements.some((item) => item.index === index)) {
        const activeElementIds = [index];
        if (
          this.props.context.exploreState.exploreDirection === EDirection.INPUT
        ) {
          activeElements.forEach((activeItem) => {
            if (activeItem instanceof Edge) {
              const { srcId, dstId } = activeItem;
              if (dstId === index) {
                activeElementIds.push(srcId);
              }
            }
          });
        } else {
          activeElements.forEach((activeItem) => {
            if (activeItem instanceof Edge) {
              const { srcId, dstId } = activeItem;
              if (srcId === index) {
                activeElementIds.push(dstId);
              }
            }
          });
        }
        context.subViewGraph.currentClickId = activeElementIds;

        // 更新高亮
        this.props.context.subViewGraph.activeElements.forEach((item) => {
          if (item instanceof Table || item instanceof Column) {
            item.view.updateHighLight();
          }
        });

        // 侧边栏高亮处理
        const activeSqlIds = getActiveSqlIdsById(
          index,
          this.props.context.exploreState.taskSqlList,
          this.props.context.exploreState.exploreDirection,
        );
        if (activeSqlIds.length === 0) {
          showExploreTipNoSql();
        }
        this.props.context.changeExploreState({
          ...this.props.context.exploreState,
          activeSqlIds,
        });
      } else {
        showExploreTipNotInActiveElement();
      }
      return;
    }
    context.subViewGraph.currentClickId = [index];
    context.updateActiveItem(this.props.data);

    // show table detail panel
    this.showDetailPanel();
  }

  onHeaderDblClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const { data, context } = this.props;

    if (
      this.leftIcon?.visible &&
      this.leftIcon?.expandType === EExpandType.CLOSE
    ) {
      // 左-
      context.removeGraphData(data, EDirection.INPUT);
    } else if (
      this.rightIcon?.visible &&
      this.rightIcon?.expandType === EExpandType.CLOSE
    ) {
      // 右-
      context.removeGraphData(data, EDirection.OUTPUT);
    } else if (
      this.leftIcon?.visible &&
      this.leftIcon?.expandType === EExpandType.EXPAND
    ) {
      // 左+
      this.leftIcon?.expandGraph();
    } else if (
      this.rightIcon?.visible &&
      this.rightIcon?.expandType === EExpandType.EXPAND
    ) {
      this.rightIcon?.expandGraph();
    }
    // context.removeGraphData(data, EDirection.BOTH);
  }

  onContextMenu(e: Event) {
    e.preventDefault();

    const { data, context } = this.props;
    const { index } = data;

    context.subViewGraph.currentRightClickId = [index];
    context.updateRightClickActiveItem(data);

    if (data._data_.type === ETableType.TEMP_TABLE) return;

    // 只有输入资产或者输出资产或者即使输入资产又是输出资产才有右键
    if (
      [EDirection.BOTH, EDirection.INPUT, EDirection.OUTPUT].includes(
        data.direction,
      )
    ) {
      const { y } = this.headerName.style;

      const [translateX, translateY] = this.headerName.getPosition();
      const left = 100 + translateX;
      const top = Number(y) + translateY - 10;
      context.showContextmenu({
        guid: index,
        visible: true,
        left,
        top,
        type: EElementType.TABLE,
        typeCode: 'Table',
        direction: data.direction,
      });
    }
  }

  async onHeaderToggleClick() {
    const { data, context } = this.props;
    // 展开收起table，都要收起两侧图标
    this.hideSideIcon();

    if (data.isOpen) {
      this.closeTable();
    } else {
      this.openTable();
    }
    context.rerender();
  }

  onFooterClick = async () => {
    const { data, context } = this.props;
    const { columnCount } = data;
    const { guid } = data._data_;
    if (data.children.length !== columnCount) {
      await this.getTableChild(guid, context, data);
    }

    data.isShowAll = !data.isShowAll;
    context.rerender();
  };

  onMouseEnter() {
    const { context } = this.props;

    // show full name
    context.viewGraph.showFullName(this.props.data.index);

    this.showSideIcon();
    // show columns toggle icon
    this.tableToggleImage.setAttribute('visibility', 'visible');
  }

  onMouseLeave() {
    // show full name
    this.props.context.viewGraph.hideFullName();

    this.hideSideIcon();
    // hide columns toggle icon
    this.tableToggleImage.setAttribute('visibility', 'hidden');
  }

  getPosition() {
    return this.g.getPosition();
  }

  highLightBySearch() {
    this.bgRect.setAttribute('stroke', '#FFC04D');
    this.bgRect.setAttribute('fill', '#FFD180');
  }

  resetHighLight() {
    this.bgRect.setAttribute('stroke', '#E8E8E8');
    this.bgRect.setAttribute('fill', '#FFF');
  }
}

export default TableView;
