import { get } from '@/utils/request';
import { API_SERVER } from '..';
import { ITaskErrorTagItem, ITaskLineage } from '@/services/task/type';
import { ITaskListVersion, ITaskOverviewRes } from '@/typings/task';

// 任务概览
export const getTaskOverview = (guid: string) => {
  return get<ITaskOverviewRes>(`${API_SERVER}/asset/task/overview`, {
    guid,
  });
};

// 任务版本历史
export const getTaskListVersion = (guid: string) => {
  return get<ITaskListVersion[]>(`${API_SERVER}/asset/task/listVersion`, {
    guid,
  });
};

// 任务版本对比
export interface ITaskContent {
  versionId: number;
  versionName: string;
  content: string;
}
export interface ITaskDataCompare {
  fromVersionData: ITaskContent;
  targetVersionData: ITaskContent;
}

export const getTaskDataCompare = (params: {
  guid: string;
  fromVersionId: number;
  targetVersionId: number;
}) => {
  return get<ITaskDataCompare>(
    `${API_SERVER}/asset/task/compareVersion`,
    params,
  );
};

// 获取任务血缘信息
export const getTaskLineage = (guid: string) => {
  return get<{ tables: ITaskLineage[] }>(`${API_SERVER}/asset/task/lineage`, {
    guid,
  });
};

// 获取任务SQL
export const getTaskContent = (guid: string) => {
  return get<{ content: string }>(`${API_SERVER}/asset/task/content`, {
    guid,
  });
};

// // 判断任务血缘是否有错误信息
// export const getTaskPartialSuccess = (guid: string) => {
//   return get<{
//     entityGuid: string;
//     ingestionTaskInstanceId: number;
//     ingestionRecordId: string;
//     gmtUpdate: number;
//   }>(`${API_SERVER}/asset/task/ingestion/partialSuc`, {
//     guid,
//   });
// };

// 任务错误标签
export const getTaskErrorTag = (guid: string) => {
  return get<ITaskErrorTagItem[]>(`${API_SERVER}/asset/task/errorTag`, {
    guid,
  });
};
