export enum EReportTag {
  // ******** PV 部分************

  // 首页 PV
  HOME_PAGE_OPEN = 'home_page_open',
  // 表血缘页面 PV
  TABLELINEAGE_PAGE_OPEN = 'tablelineage_page_open',
  // 任务血缘页面 PV
  TASKLINEAGE_PAGE_OPEN = 'tasklineage_page_open',
  // 代码生成页面v2 PV
  CODEGENERATE_V2_PAGE_OPEN = 'codegenerate_v2_page_open',
  // 代码对比页面v2 PV
  SCRIPT_V2_PAGE_OPEN = 'script_v2_page_open',

  // ******** 事件部分************

  //列右侧边栏-口径 tab 页面 点击次数
  TABLELINEAGE_SIDEBAR_CALIBER_CLICK = 'tablelineage_sidebar_caliber_click',
  // 表列血缘页面-间接血缘功能 点击次数
  TABLELINEAGE_TOOLBAR_INDIRECT_CLICK = 'tablelineage_toolbar_indirect_click',
  // 表列血缘页面-仅展示相关资产功能 点击次数
  TABLELINEAGE_TOOLBAR_RELATED_CLICK = 'tablelineage_toolbar_related_click',
  // 表列血缘页面-合并链路功能 点击次数
  TABLELINEAGE_TOOLBAR_MERGE_CLICK = 'tablelineage_toolbar_merge_click',
  // 表资产右键-影响分析 点击次数
  TABLELINEAGE_CANVAS_TABLE_IMPACT_CLICK = 'tablelineage_canvas_table_impact_click',
  // 列资产右键-影响分析 点击次数
  TABLELINEAGE_CANVAS_COLUMN_IMPACT_CLICK = 'tablelineage_canvas_column_impact_click',
  // 表列血缘页面 -口径解析 点击次数
  TABLELINEAGE_SIDEBAR_CALIBERANALYSIS_CLICK = 'tablelineage_sidebar_caliberanalysis_click',
  // 口径解析页面 页面停留时间
  TABLELINEAGE_CALIBERANALYSIS_TIME = 'tablelineage_caliberanalysis_time',
  // 任务血缘页面-间接血缘功能 点击次数
  TASKLINEAGE_TOOLBAR_INDIRECT_CLICK = 'tasklineage_toolbar_indirect_click',
  // 任务血缘页面 页面停留时间
  TASKLINEAGE_CANVAS_TIME = 'tasklineage_canvas_time',
  // 任务血缘页面-探索 点击次数（包括表溯源探索、表影响探索、列溯源探索、列影响探索）
  TASKLINEAGE_CANVAS_EXPLORE_CLICK = 'tasklineage_canvas_explore_click',
  // 任务血缘页面-探索 页面停留时间（指进入到探索模式之后的时间）
  TASKLINEAGE_CANVAS_EXPLORE_TIME = 'tasklineage_canvas_explore_time',
  // 下载中心-下载 点击次数
  MODAL_DOWNLOADCENTER_DOWNLOAD_CLICK = 'modal_downloadcenter_download_click',

  // 血缘仿真，新建和编辑点击
  LINAGE_SIMULATION_CLICK = 'lineage_simulation_click',

  // 用户登录
  LOGIN_CLICK = 'login_click',

  // 用户登出
  LOGIN_OUT_CLICK = 'login_out_click',
}
