export const permissions = {
  // 角色
  role: '角色',
  // 关联用户数
  userCount: '关联用户数',
  // 操作
  operation: '操作',
  // 查看用户
  viewUser: '查看用户',
  // 共 {data.length} 个角色
  total: (length: number) => {
    return `共 ${length} 个用户`;
  },
  // 平台管理员
  platformAdmin: '平台管理员',
  // 普通用户
  normalUser: '普通用户',
  // 用户关联已删除
  deleteLinkSuccess: '用户关联已删除',
  // 撤销失败
  revokeFailure: '撤销失败',
  // 用户
  user: '用户',
  // 关联时间
  linkTime: '关联时间',
  // 关闭
  close: '关闭',
  // 搜索
  search: '搜索',
  // 添加用户
  addUser: '添加用户',
  // 删除用户关联
  deleteUserLink: '删除用户关联',
  // 取消
  cancel: '取消',
  // 删除
  delete: '删除',
  // 是否确认删除
  confirmDelete: '是否确认删除',
  // 添加用户
  addUserSuccess: '添加用户成功',
  // 关联成功
  linkSuccess: '关联成功',
  // 关联失败
  linkFailure: '关联失败',
};
