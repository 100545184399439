export function postMessageToParentWithId(guid: string) {
  window.parent.postMessage(
    JSON.stringify({
      type: 'big_open_new_tab',
      msg: {
        guid,
      },
    }),
    '*',
  );
}
