// hover 两侧的图标
export const LEFT_ICON_OFFSET = 20;
export const RIGHT_ICON_OFFSET = 5;
export const Y_ICON_OFFSET = 2;

export const TABLE_COL_WIDTH = 190;
// 列的高度
export const COL_HEIGHT = 28;

// 默认x, y
export const DEFAULT_X = 0;
export const DEFAULT_Y = 0;

// rect 的width
export const RECT_LINE_WIDTH = 1;

// 计算相关列的默认y索引
export const COL_Y_NUM = -1;

export const SIDE_ICON_SIZE = 28;

export const RECT_BOTTOM_OFFSET = 4;
