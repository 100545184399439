export const entity = {
  entityName: '实体名称',
  addEntity: '新增实体',

  viewSource: '查看数据来源',
  tableName: '数据表',

  emptyNodePageMessage: '该实体组暂无已管理资产',
  totalNumText: (searchContent: string, num: string) => {
    return searchContent
      ? `与 ${searchContent} 相关的有 ${num} 条`
      : `共 ${num} 条`;
  },

  source: '来源',
  dataMapping: '数据映射',
  updateSchedule: '更新计划',

  nextStep: '下一步',
  configSource: '配置来源',
  configDataMapping: '配置数据映射',
  configUpdate: '配置更新计划',

  entityNameRequired: '实体名称不能为空',
  entityNamePattern: '仅支持中文、字母、数字、下划线，并且长度最大为20',
  entityEnName: '实体英文名',
  entityEnNameRequired: '实体英文名不能为空',
  entityEnNamePattern: '仅支持字母、数字、下划线并以字母开头，并且长度最大为16',
  entityDesc: '实体描述',
  entityDescLen: '实体描述不能超过200个字符',

  attrName: '属性名称',
  attrNameRequired: '属性名称不能为空',
  attrEnName: '属性英文名',
  attrEnNameRequired: '属性英文名不能为空',
  attrDesc: '属性描述',
  attrDescLen: '属性描述不能超过200个字符',
  attrDataType: '属性数组类型',
  attrDataTypeRequired: '属性数值类型不能为空',
  selectAttrDataType: '请选择数值类型',

  dataSourceRequired: '数据源不能为空',
  databaseNameRequired: '数据库不能为空',
  tableNameRequired: '数据表不能为空',
  searchPlaceholder: '输入数据库名查询',
  searchTableNamePlaceholder: '输入数据表名查询',
  inputPlaceholder: '输入分区内容，多个用英文逗号隔开',
  businessPartition: '启动业务分区',
  businessPartitionSelectPlaceholder: '选择业务分区字段',
  businessPartitionTips: '业务分区支持根据字段业务含义的数据更新方式',
  hourSelectPlaceholder: '选择小时分区字段',
  datePartition: '启动二级日期分区',
  datePartitionTips: '二级日期分区字段支持小时级别数据更新方式',
  partitionField: '选择分区字段',
  dateSelectPlaceholder: '选择日期分区字段',
  tableUpdateType: '数据表更新方式',
  tableUpdateTypeRequired: '数据表更新方式不能为空',
  selectTableUpdateType: '请选择数据表更新方式',
  spinText: '分区信息加载中……',

  attrSelectPlaceholder: '选择实体属性',
  mapping: '映射',
  tableSelectPlaceholder: '请选择表字段',
  dataMappingConfig: '数据映射配置',
  databaseTable: '数据库表',

  dataSourceUpdateType: '来源表数据的采集更新计划',
  updatePlan: '更新计划',
  updatePlanRequired: '更新计划不能为空',
  collectNow: '是否立即启动采集',
  collectNowTips:
    '开启时，会在数据来源创建完成后立即开始采集任务；取消时，会在下一次更新计划来临后，再开始采集任务',
};
