export const tokenManage = {
  // API 密钥
  title: 'API 密钥',
  // 新建 API 密钥
  addText: '新建 API 密钥',
  total: (total: number) => `共 ${total} 个 API 密钥`,
  // 创建成功
  createSuccess: '创建成功',
  // 创建失败
  createFail: '创建失败',
  // 成功
  success: '成功',
  // 删除成功
  deleteSuccess: '删除成功',
  // 删除失败
  deleteFail: '删除失败',
};
