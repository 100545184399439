import { throttle } from 'lodash';
import { useRef, useState } from 'react';

export default function useUpdate(time = 30) {
  const [, setState] = useState<Symbol>(Symbol('此处需要一些描述，待补充'));
  // 保存最新的引用 处理useEffect(...,[])这种情况
  const ref = useRef(setState);
  ref.current = setState;

  // 节流
  return throttle(() => {
    ref.current(Symbol('此处需要一些描述，待补充'));
  }, time);
}
