export const ingestManagement = {
  // 运行新实例
  runNewInstance: '运行新实例',

  // 运行新实例成功
  runNewInstanceSuccess: '运行新实例成功',

  // 运行新实例失败，请重新处理
  runNewInstanceFail: '运行新实例失败，请重新处理',

  // 重跑实例
  rerunInstance: '重跑实例',

  // 重跑实例成功
  rerunInstanceSuccess: '重跑实例成功',

  // '重跑实例失败，请重新处理'
  rerunInstanceFail: '重跑实例失败，请重新处理',

  // '重启任务'
  restartTask: '重启任务',

  // '重启任务成功'
  restartTaskSuccess: '重启任务成功',

  // '重启任务失败，请重新处理'
  restartTaskFail: '重启任务失败，请重新处理',

  // '删除任务'
  deleteTask: '删除任务',

  // '删除任务成功'
  deleteTaskSuccess: '删除任务成功',

  // '删除任务失败，请重新处理'
  deleteTaskFail: '删除任务失败，请重新处理',

  // '暂停任务'
  pauseTask: '暂停任务',

  // '暂停任务成功'
  pauseTaskSuccess: '暂停任务成功',

  // '暂停任务失败，请重新处理'
  pauseTaskFail: '暂停任务失败，请重新处理',

  // '暂无相关任务'
  noTask: '暂无相关任务',

  //  最近实例状态：
  recentInstanceState: '最近实例状态：',

  // 实例未运行
  instanceNotRunning: '实例未运行',

  // 最近运行结束时间：
  recentEndTime: '最近运行结束时间：',

  // 任务编辑时间：
  taskEditTime: '任务编辑时间：',

  // '采集任务名称'
  collectTaskName: '采集任务名称',

  // '采集内容'
  collectContent: '采集内容',

  // '任务脚本内容'
  taskScriptContent: '任务脚本内容',

  // '采集方式'
  collectMethod: '采集方式',

  //  任务平台英文名称
  taskPlatformEnName: '任务平台英文名称',

  // '请输入数字、小写英文字母、下划线，并且最大长度为20'
  ingestNameRegText: '请输入数字、小写英文字母、下划线，并且最大长度为20',

  // "请输入任务平台英文名称"
  inputTaskPlatformEnName: '请输入任务平台英文名称',

  // '上传任务基础信息文件'
  uploadTaskBasicInfoFile: '上传任务基础信息文件',

  //  '上传任务脚本内容文件'
  uploadTaskScriptContentFile: '上传任务脚本内容文件',

  // '上传任务实例日志文件';
  uploadTaskLog: '上传任务实例日志文件',

  // '任务属性元数据表';
  taskPropertiesMetadataTable: '任务属性元数据表',

  // '任务脚本内容元数据表';
  taskScriptContentMetadataTable: '任务脚本内容元数据表',

  // '任务实例日志元数据表';
  taskLogMetadataTable: '任务实例日志元数据表',

  // '元数据表所属数据库'
  metadataTableBelongDatabase: '元数据表所属数据库',

  // '请选择数据库'
  selectDatabase: '请选择数据库',

  // '请选择表'
  selectTable: '请选择表',

  // '任务基础信息元数据映射'
  taskBasicInfoMetadataMapping: '任务基础信息元数据映射',

  // '表字段和任务基础信息映射'
  tableFieldsAndTaskBasicInfoMapping: '表字段和任务基础信息映射',

  // '元数据表日期分区字段'
  metadataTableDatePartitionField: '元数据表日期分区字段',

  //  SQL语法类型
  sqlSyntaxType: 'SQL语法类型',

  // '配置全局变量'
  configureGlobalVariables: '配置全局变量',

  // '任务脚本内容元数据映射'
  taskScriptContentMetadataMapping: '任务脚本内容元数据映射',

  // '表字段和任务脚本内容映射'
  tableFieldsAndTaskScriptContentMapping: '表字段和任务脚本内容映射',

  // '任务实例日志元数据映射'
  taskLogMetadataMapping: '任务实例日志元数据映射',

  // '表字段和任务实例日志映射'
  tableFieldsAndTaskLogMapping: '表字段和任务实例日志映射',

  // '任务实例日志处理'
  taskLogProcessing: '任务实例日志处理',

  // '补充任务脚本内容解析'
  supplementTaskScriptContentParsing: '补充任务脚本内容解析',

  // '单独任务实例日志解析'
  singleTaskLogParsing: '单独任务实例日志解析',

  // 依赖任务脚本内容采集任务
  dependentTaskScriptContentCollectionTask: '依赖任务脚本内容采集任务',

  // '表DDL & 视图 SQL语句'
  tableDDLAndViewSQL: '表DDL & 视图 SQL语句',

  // "上传表 DDL或视图 SQL 文件"
  uploadTableDDLAndViewSQLFile: '上传表 DDL或视图 SQL 文件',

  // '数据库采集范围'
  databaseCollectionRange: '数据库采集范围',

  // 选择包含范围
  selectInclusiveRange: '选择包含范围',

  // '选择剔除范围'
  selectExclusionRange: '选择剔除范围',

  // 表视图元数据表
  tableViewMetadataTable: '表视图元数据表',

  // '字段元数据表'
  fieldMetadataTable: '字段元数据表',

  // 「表视图元数据表」和「字段元数据表」必填一项。
  tableAndViewMetadataTableRequired:
    '「表视图元数据表」和「字段元数据表」必填一项。',

  // '表DDL & 视图 SQL 元数据表'
  tableDDLAndViewSQLMetadataTable: '表DDL & 视图 SQL 元数据表',

  // '增量处理状态'
  incrementalProcessingStatus: '增量处理状态',
};
