import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { SQLGraphAtom } from '../atoms/sqlGraphAtom';
import { EEventEnum } from '../typings';
import useUpdate from './useUpdate';
import { commonUseFunc } from './util';

export default function useContextmenu() {
  const update = useUpdate();
  const sqlGraph = useRecoilValue(SQLGraphAtom);
  useEffect(() => {
    return commonUseFunc(sqlGraph, EEventEnum.CONTEXT_MENU_CHANGE, update);
  });
  return sqlGraph.contextmenuState;
}
