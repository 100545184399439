import { get, post } from '@/utils/request';
import { API_SERVER } from '..';
import { ITaskErrorTagItem } from '../task/type';
import { IColumnListRes, ILineageDataRes } from '../lineage/type';
import { ITaskSqlListItem } from '@/components/TaskLineageView/atoms/overviewAtom';
import { EDirection } from '@/typings';
import { ILineageSimulationTask } from './type';

// 任务仿真错误标签
export const getLinageSimulationErrorTag = (id: string) => {
  return get<ITaskErrorTagItem[]>(`${API_SERVER}/simulation/task/errorTags`, {
    id,
  });
};

// 任务仿真下表的列信息
export const getLinageSimulationColumns = (data: {
  id: string;
  guid: string;
}) => {
  return get<IColumnListRes>(`${API_SERVER}/simulation/task/columns`, data);
};

// 任务仿真血缘加工链路
export const getLinageSimulationSqlList = (data: {
  id: string;
  entityGuid: string;
  direction: string;
  needIndirect: boolean;
}) => {
  return get<Omit<ITaskSqlListItem, 'sqlId'>[]>(
    `${API_SERVER}/simulation/task/entityInTask`,
    data,
  );
};

// 探索任务仿真血缘
export const getLinageSimulation = (data: {
  id: string;
  guids?: string[];
  entityType?: string;
  direction: EDirection;
  needIndirect?: boolean;
}) => {
  return post<ILineageDataRes>(`${API_SERVER}/simulation/task/relation`, data);
};

// 获取任务仿真
export const getLinageSimulationTask = (id: string) => {
  return get<ILineageSimulationTask>(`${API_SERVER}/simulation/task/find`, {
    id,
  });
};

// 询任务仿真下的表分类信息
export const getLinageSimulationTable = (id: string) => {
  return get<{
    inputTableGuids: string[];
    outputTableGuids: string[];
    tempTableGuids: string[];
  }>(`${API_SERVER}/simulation/task/tableSort`, {
    id,
  });
};

// 创建任务仿真
export const createSimulationTask = (data: {
  sqlDialect: string;
  rawScriptStr: string;
}) => {
  return post<ILineageSimulationTask>(
    `${API_SERVER}/simulation/task/create`,
    data,
  );
};

// 编辑务仿真
export const updateSimulationTask = (data: {
  id: string;
  sqlDialect: string;
  rawScriptStr: string;
}) => {
  return post<ILineageSimulationTask>(
    `${API_SERVER}/simulation/task/update`,
    data,
  );
};

// sql
export const sqlForm = (
  data: Partial<{
    sqlContext: string;
    sqlDialect: string;
    datasource: string;
    database: string;
    schema: string;
    runVariables: boolean;
    variables: {
      name: string;
      value: string;
    }[];
  }>,
) => {
  return post<any[]>(`http://10.5.120.1:8083/strategy/api/sql/format`, data);
};
