// 节点相关操作
// 显示两侧icon
import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import Table from '@/components/TaskLineageView/model/Table';
import Column from '@/components/TaskLineageView/model/Column';
import * as Renderer from '@aloudata/ink';
import {
  EDashType,
  ETableType,
  sideIconTypeObj,
  TNodeItem,
} from '@/components/TaskLineageView/typings';
import { hasLineage } from '@/components/TaskLineageView/utils/map';
import {
  getColumnPathNodesEdges,
  getTablePathNodesEdges,
} from '@/components/TaskLineageView/utils/lineage';
import ActionIcon, {
  EExpandType,
} from '@/components/TaskLineageView/components/ActionIcon';
import Loading from '../components/Loading';
import { EOverviewType } from '@/typings/overview';
import { EDirection } from '@/typings';

// 对于表来说，如果
export function getIconVisible(
  context: SQLGraph,
  data: TNodeItem,
  direction: EDirection,
): boolean {
  let visible = true;
  if (data.isStartNode && data instanceof Table) return true;
  if (data instanceof Column) {
    // 如果表级没有更上层，就直接不显示扩展符号
    // if (!hasLineage(data.parent.index, context.viewGraph.edgeMap, direction)) {
    //   visible = false;
    // } else {
    visible = !hasLineage(
      data.index,
      context.viewGraph.originEdgeMap,
      direction,
    );
    if (!data.isStartNode) {
      visible = visible && data.isCorrelation;
    }
  } else {
    const res = getTablePathNodesEdges(
      [data],
      context.viewGraph.originEdgeMap,
      // todo，暂时使用全部数据
      context.viewGraph.originNodeMap,
      direction,
      Number.MAX_SAFE_INTEGER,
    );
    res.nodes.forEach((item) => {
      // 如果链路上有起始表，证明不应该显示
      visible = visible && !item.isStartNode;
    });
  }
  return visible;
}

// 对于表来说，会有展开和收起icon，对于列来说，只有展开icon
export function getIconType(
  context: SQLGraph,
  data: TNodeItem,
  direction: EDirection,
): EExpandType {
  if (data instanceof Column) return EExpandType.EXPAND;
  return hasLineage(data.index, context.viewGraph.originEdgeMap, direction)
    ? EExpandType.CLOSE
    : EExpandType.EXPAND;
}

// 控制左右Icon
export function showSideIcon(
  context: SQLGraph,
  data: Table | Column,
  group: Renderer.Group,
  expandLoadingIcon: Loading,
  leftIcon: ActionIcon,
  rightIcon: ActionIcon,
) {
  // 判断当前是否loading，并且判断是在那一边loading
  // const groupWidth = group.getBBox().width;
  //
  // const [translateX] = group.getPosition();

  // let rightIconX = translateX + groupWidth - RIGHT_ICON_OFFSET;

  // todo: 图标类型处理
  leftIcon.expandType = getIconType(context, data, EDirection.INPUT);

  rightIcon.expandType = getIconType(context, data, EDirection.OUTPUT);
  // const { x: loadingX } = expandLoadingIcon.image.style;
  // const loadingIgnore = expandLoadingIcon.visible;
  const isLoadingRight = false;
  const isLoadingLeft = false;
  // if (loadingX === leftX && loadingIgnore) {
  //   isLoadingLeft = true;
  // }
  // if (loadingX === rightX && loadingIgnore) {
  //   isLoadingRight = true;
  // }

  if (getIconVisible(context, data, EDirection.INPUT) && !isLoadingLeft) {
    // 上游和起始表
    leftIcon.show();
    leftIcon.image.setAttribute('img', sideIconTypeObj[leftIcon.expandType]);
  } else {
    leftIcon.hide();
  }
  if (getIconVisible(context, data, EDirection.OUTPUT) && !isLoadingRight) {
    rightIcon.show();
    rightIcon.image.setAttribute('img', sideIconTypeObj[rightIcon.expandType]);
  } else {
    rightIcon.hide();
  }

  context.changeSideIconState({
    data,
  });
}

/**
 *
 * @param context
 * @param needRefreshColumn 是否需要刷新列的属性
 */
export function refreshCorrelation(
  context: SQLGraph,
  needRefreshColumn = true,
) {
  const { nodes, edges, edgeDashVisible, originNodeMap } = context.viewGraph;

  const originNodes = nodes.filter((item) => {
    return item.isStartNode;
  });

  if (originNodes.length < 1) return;

  const columnSet = new Set<string>();
  if (
    edgeDashVisible === EDashType.ALL ||
    edgeDashVisible === EDashType.INDIRECT
  ) {
    edges.forEach((edge) => {
      columnSet.add(edge.srcId);
      columnSet.add(edge.dstId);
    });
  } else {
    originNodes.forEach((node) => {
      const originColumns = node.children.filter((item) => {
        return edges.some(
          (edge) => edge.srcId === item.index || edge.dstId === item.index,
        );
      });
      const res = getColumnPathNodesEdges(
        originColumns,
        context.viewGraph.originEdgeMap,
        context.viewGraph.originNodeMap,
        EDirection.BOTH,
        Number.MAX_SAFE_INTEGER,
        {
          edgeType: context.viewGraph.edgeDashVisible,
          isOnlyShowRelatedTable: false,
        },
      );
      res.nodes.forEach((item) => {
        columnSet.add(item.index);
      });
    });
  }
  originNodeMap.forEach((node) => {
    if (node instanceof Table) {
      node.isCorrelation = node.isStartNode;
      node.children.forEach((column) => {
        if (needRefreshColumn)
          column.isCorrelation = columnSet.has(column.index);
        if (!node.isStartNode)
          node.isCorrelation =
            node.isCorrelation || columnSet.has(column.index);
      });
    }
  });
}

export function isShowTableToggle(typeCode: string) {
  return typeCode === 'Table';
}

export function getTextFromTableType(type: ETableType) {
  switch (type) {
    case ETableType.VIEW:
      return '视图';
    case ETableType.TABLE:
      return '表';
    case ETableType.TEMP_TABLE:
      return '临时表';
  }
  return '未知';
}

export function getOverviewType(type: string) {
  switch (type) {
    case 'VIEW':
      // FIXME: VIEW 属性什么类型？
      return EOverviewType.TABLE;
    case 'TABLE':
      return EOverviewType.TABLE;
    case 'TEMP_TABLE':
      return EOverviewType.TEMP_TABLE;
  }
  return EOverviewType.TABLE;
}
