export const changeNotification = {
  monitorRange: '监控范围',
  globalReceiverNames: '全局通知用户',
  viewGlobalReceiverTips: '查看全局通知用户',
  globalReceiverLen: (num: number) => {
    return ` 共 ${num} 人`;
  },
  editMonitorTips: '编辑监控',
  addMonitor: '新建监控',
  monitorLen: (num: number) => {
    return `共 ${num} 个监控`;
  },
  deleteMonitor: '删除监控',
  deleteTips: '删除变更通知配置将无法恢复，确认删除吗？',

  selectTag: '选择标签',
  selectTagMessage: '请选择标签',
  addUserMessage: '请添加用户',
  notificationUser: '通知用户',
  notificationUserTipsOne:
    '监控范围内所有资产的相关变更，都会向全局通知用户发送通知；',
  notificationUserTipsTwo:
    '依据监控范围下资产的变更类型，自动向下通知对应资产的负责人',
  selectUser: '选择用户',
  notificationGlobalUser: '是否增加全局通知用户',
  monitorRangeTips: '通过选择标签关联的资产，监控这部分资产的自身变更',
  notificationConfigModalTitle: (status: string) => {
    return status === 'edit' ? '编辑变更监控配置' : '新建变更监控配置';
  },

  modalGlobalReceiverLen: (num: number) => {
    return `共 ${num} 个用户`;
  },
  user: '用户',
};
