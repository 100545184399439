import t from '@/locales';
import default_login_logo_img from '@/assets/logo-aloudata-big.svg';
import default_login_product_img from '@/assets/login/preview.png';

export const isIframeEnv = window.parent !== window;

type TFlag = 'true' | 'false';

interface IWindow extends Window {
  bigAttributes: IBigAtrributes;
}

interface IBigAtrributes {
  bigFlag: IBigFlag;
  bigConfig: IBigConfig;
}

interface IBigFlag {
  // 密码是否需要加密
  needEncrypt: TFlag;
  // 血缘顶部栏开关
  lineageTopBar: TFlag;
  // 影响分析开关
  impactAnalysis: TFlag;
  // 任务血缘入口开关
  taskLineageEntry: TFlag;
  // 支持信息开关
  supportInfo: TFlag;
  // 行列裁剪开关
  cropSwitch: TFlag;
  // 仿真血缘开关
  metadataAppMenuSwitch: TFlag;
}

// 定义服务端返回的一些全局配置
interface IBigConfig {
  // 帮助文档链接
  helpUrl: string;
  // 右下角支持信息文本
  supportText: string;

  // 登录页面logo
  login_logo_img: string;
  // 登录页面logo旁简介
  login_logo_desc: string;
  // 登录页面产品封面图片
  login_product_img: string;
  // 登录页面产品封面描述
  login_product_desc: string;
  // 登录页面产品封面副描述
  login_product_desc_sub: string;
  // 主页面logo
  home_logo_img: string;
  // 主页面搜索栏上描述
  home_main_desc: string;
}

const bigAttributes =
  (window as unknown as IWindow).bigAttributes || ({} as any);

const { bigFlag, bigConfig } = bigAttributes;

// big flag
// 定义 big 页面中的各种业务开关（服务端通过 vm 里面的 script 标签返回）,都用 bigFlag 开始
export const bigFlagNeedEncrypt = bigFlag
  ? bigFlag.needEncrypt === 'true'
  : false;

export const bigFlagLineageTopBar = bigFlag
  ? bigFlag.lineageTopBar === 'true'
  : true;

export const bigFlagShowImpactAnalysis = bigFlag
  ? bigFlag.impactAnalysis === 'true'
  : true;

export const bigFlagShowCropAnalysis = bigFlag
  ? bigFlag.cropSwitch === 'true'
  : true;

export const bigFlagShowSimulation = bigFlag
  ? bigFlag.metadataAppMenuSwitch === 'true'
  : true;

export const bigFlagShowTaskLineageInfo = bigFlag
  ? bigFlag.taskLineageEntry === 'true'
  : true;

export const bigFlagShowSupport = bigFlag
  ? bigFlag.supportInfo === 'true'
  : true;

// 资产打标（算法打标显示开关）
export const bigTagMarkAlgorithmVisible = bigFlag
  ? bigFlag.tagMarkAlgorithmVisible === 'true'
  : true;

// 表资产扩散精度列开关
export const bigTagMarkTableUseColumnParticleVisible = bigFlag
  ? bigFlag.tagMarkTableUseColumnParticleVisible === 'true'
  : true;

// big config
export const bigConfigHelpUrl = bigConfig ? bigConfig.helpUrl : '';
export const bigConfigSupportText =
  bigConfig?.supportText || t.home.supportText;

export const bigConfig_login_logo_img =
  bigConfig?.login_logo_img || default_login_logo_img;
export const bigConfig_login_logo_desc =
  bigConfig?.login_logo_desc || t.home.tipWord;
export const bigConfig_login_product_img =
  bigConfig?.login_product_img || default_login_product_img;
export const bigConfig_login_product_desc =
  bigConfig?.login_product_desc === ''
    ? ''
    : bigConfig?.login_product_desc || '用数据更敏捷，管数据更智能。'; // 不显示的话要传空格
export const bigConfig_login_product_desc_sub =
  bigConfig?.login_product_desc_sub === ''
    ? ''
    : bigConfig?.login_product_desc_sub ||
      '让企业每一个人都能开启轻松愉悦的数据之旅。'; // 不显示的话要传空格
export const bigConfig_home_logo_img =
  bigConfig?.home_logo_img || default_login_logo_img;
export const bigConfig_home_main_desc =
  bigConfig?.home_main_desc === ''
    ? ''
    : bigConfig?.home_main_desc || t.home.tipWord;
