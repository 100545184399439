import { RectStyleProps } from '@aloudata/ink';

export interface ICommonRectStyle {
  style: RectStyleProps;
}

// export interface ICommonRectStyle {
//   shape: Partial<RectStyleProps>;
//   style: PathStyleProps;
// }

export enum EEntityType {
  // Database
  DATABASE,
  // CustomEntity
  CUSTOM_ENTITY,
  // Table
  TABLE,
  // Hive
  HIVE,
  // Hive
  // 列算子
  OPERATOR,
  // 虚拟表
  VIRTUAL,
}
