import TableView from '../view/TableView';
import { TABLE_COL_WIDTH } from '../constants';
import { ITableModelProps, IUserTable } from '../typings';
import BaseNode from './BaseNode';
import Column from './Column';
import SQLGraph from './SQLGraph';
import { EDataSourceType, EDirection } from '@/typings';
import { getParamsFromHash } from '@/utils';

export default class Table extends BaseNode<TableView, ITableModelProps> {
  children: Column[];

  isOpen: boolean = false; // 是否展开

  isShowAll: boolean = false; // 是否显示全部列

  columnCount: number;

  isStartNode: boolean;

  fold: boolean; // 是否可折叠

  assetPath: string;

  datasourceType: EDataSourceType;

  direction: EDirection;

  constructor(data: IUserTable, context: SQLGraph) {
    super({
      context,
      name: data.name,
      index: data.guid,
      typeCode: 'Table',
      width: TABLE_COL_WIDTH,
      height: 0,
      x: 0,
      y: 0,
    });
    // eslint-disable-next-line no-unused-vars
    const { children, ...others } = data;
    this._data_ = others as ITableModelProps;
    this.columnCount = others.columnCount;
    const originTableGuid = getParamsFromHash(
      window.location.hash,
      'originTableGuid',
    );
    const originColumnGuid = getParamsFromHash(
      window.location.hash,
      'originColumnGuid',
    );

    if (
      originColumnGuid &&
      originTableGuid &&
      originColumnGuid !== 'undefined' &&
      originTableGuid !== 'undefined'
    ) {
      this.isOpen = data.guid === originTableGuid;
      this.isShowAll = data.guid === originTableGuid;
    }

    // this.isOpen = data.isStartNode;
    // this.isShowAll = data.isStartNode;
    this.isStartNode = data.isStartNode;
    this.assetPath = data.assetPath;
    this.direction = data.direction;
    this.datasourceType = data.datasourceType;
    this.children = data.children.map((item) => {
      return new Column(item, this);
    });
  }

  toggle(open: boolean) {
    this.isOpen = open;
  }

  getVisible() {
    // 输入输出表 return true
    if (
      this.direction === EDirection.INPUT ||
      this.direction === EDirection.OUTPUT ||
      this.direction === EDirection.BOTH
    ) {
      return true;
    }

    // 关闭间接血缘，但与表相关的edge均为间接关系，return false
    return true;
  }

  update(data: IUserTable) {
    const newChildren = data.children.map((item) => {
      return new Column(item, this);
    });
    // 如果newChildren里有原本的children里面没有的，需要添加
    newChildren.forEach((item) => {
      const index = this.children.findIndex(
        (child) => child.index === item.index,
      );
      if (index === -1) {
        this.children.push(item);
        this.context.viewGraph.originNodeMap.set(item.index, item);
      } else {
        if (item.highlight) {
          this.children[index].highlight = item.highlight;
          this.isOpen = true;
        }
      }
    });
  }
}
