import { DebouncedFunc } from 'lodash';
import SQLGraph from '../model/SQLGraph';
import { EEventEnum } from '../typings';

export function commonUseFunc(
  sqlGraph: SQLGraph,
  eventType: EEventEnum,
  update: DebouncedFunc<() => void>,
) {
  sqlGraph.emitter.on(eventType, update);

  return () => {
    sqlGraph.emitter.off(eventType, update);
  };
}
