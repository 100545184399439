// 所有节点和边实例的操作
import Table from '../model/Table';
import {
  TEdgeMap,
  TNodeMap,
} from '@/components/TaskLineageView/model/SQLGraph';
import {
  EDashType,
  IEdge,
  TInnerNodeItem,
  TNodeItem,
} from '@/components/TaskLineageView/typings';
import Edge from '@/components/TaskLineageView/model/Edge';
import { isEdgeEffectiveInFilter } from '@/components/TaskLineageView/utils/edge';
import { EDirection } from '@/typings';

export function hasInput(index: string, edgeMap: TEdgeMap): boolean {
  // todo 未处理重名情况
  let visible = false;

  for (const edge of edgeMap.values()) {
    const dst = edge.dstId;
    if (index === dst) {
      visible = true;
      break;
    }
  }

  return visible;
}

export function hasOutput(index: string, edgeMap: TEdgeMap): boolean {
  // todo 未处理重名情况
  let visible = false;

  for (const edge of edgeMap.values()) {
    const src = edge.srcId;
    if (index === src) {
      visible = true;
      break;
    }
  }

  return visible;
}

export function hasDirectInput(index: string, edgeMap: TEdgeMap): boolean {
  // todo 未处理重名情况
  let visible = false;

  edgeMap.forEach((edge) => {
    // const [, dst] = key.split('-');
    const dst = edge.dstId;
    if (index === dst) {
      visible = visible || mapDashType(edge, EDashType.DIRECT);
    }
  });
  return visible;
}

export interface IFilter {
  edgeType: EDashType;
  isOnlyShowRelatedTable?: boolean;
  withoutOperator?: boolean;
  operatorId?: string;
}

export function mapDashType(edge: Edge, edgeType: EDashType) {
  switch (edgeType) {
    case EDashType.DIRECT:
      return edge.type === EDashType.DIRECT || edge.type === EDashType.ALL;
    default:
      return true;
  }
}

export function getDirectionTablesByTableId(
  tableIndex: string,
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  filter: IFilter,
  direction: EDirection,
): TInnerNodeItem[] {
  const edgeIndexes = getEdgeIndexesFromDirection(
    edgeMap,
    nodeMap,
    tableIndex,
    filter,
    direction,
  );
  const tables: TInnerNodeItem[] = [];
  if (edgeIndexes.length) {
    edgeIndexes.forEach((edge) => {
      const item =
        direction === EDirection.INPUT
          ? nodeMap.get(edge.srcId)
          : nodeMap.get(edge.dstId);
      if (item instanceof Table) {
        tables.push(item);
      }
    });
  }
  return tables;
}

export function getEdgeIndexesFromDirection(
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  index: string,
  filter: IFilter,
  direction: EDirection,
): IEdge[] {
  if (direction === EDirection.BOTH) {
    return [
      ...getEdgeIndexesFromDirection(
        edgeMap,
        nodeMap,
        index,
        filter,
        EDirection.INPUT,
      ),
      ...getEdgeIndexesFromDirection(
        edgeMap,
        nodeMap,
        index,
        filter,
        EDirection.OUTPUT,
      ),
    ];
  }
  const edgeIndexes: IEdge[] = [];
  edgeMap.forEach((edge, key) => {
    // const [srcId, dstId] = key.split('-');
    const srcId = edge.srcId;
    const dstId = edge.dstId;
    if (direction === EDirection.OUTPUT) {
      if (srcId === index && isEdgeEffectiveInFilter(filter, edge, nodeMap)) {
        edgeIndexes.push({
          index: key,
          srcId,
          dstId,
        });
      }
    } else if (direction === EDirection.INPUT) {
      if (index === dstId && isEdgeEffectiveInFilter(filter, edge, nodeMap)) {
        edgeIndexes.push({
          index: key,
          srcId,
          dstId,
        });
      }
    }
  });
  return edgeIndexes;
}

export function hasLineage(
  index: string,
  edgeMap: TEdgeMap,
  direction: EDirection,
) {
  if (direction === EDirection.INPUT) {
    return hasInput(index, edgeMap);
  } else if (direction === EDirection.OUTPUT) {
    return hasOutput(index, edgeMap);
  } else {
    return hasInput(index, edgeMap) || hasOutput(index, edgeMap);
  }
}

export function getDirectionTablesByTable(
  column: Table,
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  filter: IFilter,
  direction: EDirection,
): {
  nodes: Table[];
  edges: Edge[];
} {
  if (direction === EDirection.BOTH) {
    const left = getDirectionTablesByTable(
      column,
      edgeMap,
      nodeMap,
      filter,
      EDirection.INPUT,
    );
    const right = getDirectionTablesByTable(
      column,
      edgeMap,
      nodeMap,
      filter,
      EDirection.INPUT,
    );
    return {
      nodes: [...left.nodes, ...right.nodes],
      edges: [...left.edges, ...right.edges],
    };
  }
  const edgeIndexes = getEdgeIndexesFromDirection(
    edgeMap,
    nodeMap,
    column.index,
    filter,
    direction,
  );
  const nodes: Table[] = [];
  const edges: Edge[] = [];

  if (edgeIndexes.length) {
    edgeIndexes.forEach((edge) => {
      const data =
        direction === EDirection.INPUT
          ? nodeMap.get(edge.srcId)
          : nodeMap.get(edge.dstId);
      if (data instanceof Table) {
        nodes.push(data);
        edges.push(edgeMap.get(edge.index));
      }
    });
  }
  return {
    nodes,
    edges,
  };
}

export function getDirection<T extends TNodeItem>(
  node: T,
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  filter: IFilter,
  direction: EDirection,
): {
  nodes: T[];
  edges: Edge[];
} {
  if (direction === EDirection.BOTH) {
    const left = getDirection(node, edgeMap, nodeMap, filter, EDirection.INPUT);
    const right = getDirection(
      node,
      edgeMap,
      nodeMap,
      filter,
      EDirection.OUTPUT,
    );
    return {
      nodes: [...left.nodes, ...right.nodes],
      edges: [...left.edges, ...right.edges],
    };
  }

  const edgeIndexes = getEdgeIndexesFromDirection(
    edgeMap,
    nodeMap,
    node.index,
    filter,
    direction,
  );

  const nodes: T[] = [];
  const edges: Edge[] = [];

  if (edgeIndexes.length) {
    edgeIndexes.forEach((edge) => {
      const data =
        direction === EDirection.INPUT
          ? nodeMap.get(edge.srcId)
          : nodeMap.get(edge.dstId);
      if (data) {
        nodes.push(data as T);
      }
      edges.push(edgeMap.get(edge.index));
    });
  }
  return {
    nodes,
    edges,
  };
}
