import { IHeader, IResponse } from '@/typings';
import { notification } from '@aloudata/aloudata-design';
import axios, { AxiosResponse, Canceler, Method } from 'axios';
import _ from 'lodash';
import qs from 'query-string';
import { history } from 'umi';
import { getQueryParam, saveNoAuthUrl } from '.';
// import styles from './index.less';
import { cookie } from '@/constants/cookie';
import { isIframeEnv } from '@/constants/global';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const getFileName = (
  str: string,
  fileTypeNumber?: number,
  fileType?: string,
) => {
  const INDEX = 3;
  const startIndex = str.indexOf('filename');
  const lasetIndex = str.indexOf(fileType || 'csv') + (fileTypeNumber || INDEX);
  return str.substring(startIndex, lasetIndex).split('=')[1];
};

export const workspaceInfo = {
  // TODO: 将workspaceId放入header参数中发给服务端
  'Workspace-Id': '',
};

const queryObj: { [key: string]: number } = {};

let showTimeoutModal = false; // 是否弹出登录超时的弹框

// 需要过滤提示的code
const ignoreMsgCode = ['410003'];
// 取消请求
const allPendingRequests: Canceler[] = [];
export const cancelAllPendingRequests = (message = '', callback = () => {}) => {
  allPendingRequests.forEach((cancel) => {
    cancel(message);
  });
  allPendingRequests.length = 0;
  callback();
};

axios.interceptors.request.use((config) => {
  // 设置cancelToken
  config.cancelToken = new axios.CancelToken((cancel) => {
    allPendingRequests.push(cancel);
  });
  return config;
});

function ajax<T>(
  url: string,
  opt: IRequestOpt,
  firstFetch: boolean = false,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const { headers, silent = false } = opt;
    // 处理文件上传的header
    let newHeader: { [key: string]: string } = {};
    if (headers) {
      newHeader = { ...headers, ...workspaceInfo };
    } else {
      newHeader = { ...defaultHeaders, ...workspaceInfo };
    }
    // 临时从本地获取token，登录状态需要
    if (isIframeEnv) {
      const currentURL = window.location.href;
      const token = getQueryParam(currentURL, 'token');
      newHeader['Integration-token'] = token || 'fangzhou-test';
    } else {
      const token = localStorage.getItem(cookie.TOKEN);
      newHeader['aloudata-token'] = token || '';
    }

    axios({
      url: `${url}`,
      headers: newHeader,
      method: opt.method,
      // data: removeUndefinedDeeplyForObject(opt.data || {}),
      data: opt.data || {},
    }).then(
      (res: AxiosResponse<IResponse<T>>) => {
        // 处理文件下载
        const { data: resBody } = res;

        const resHeaders = res.headers as IHeader;
        const contentType = 'content-type';
        const contentDisposition = 'content-disposition';
        if (resHeaders[contentType]?.includes('application/octet-stream')) {
          const fileName = getFileName(resHeaders[contentDisposition]);
          const aUrl = window.URL.createObjectURL(
            // eslint-disable-next-line prefer-template
            new Blob(['\uFEFF' + resBody]),
          );
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = aUrl;
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click(); // 执行下载
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        } else {
          const { success, message, data, code } = resBody;

          const SESSION_TIME_OUT = 'CIP01001'; // 登录超时的错误码

          // 处理登录超时
          if (code === SESSION_TIME_OUT) {
            if (!showTimeoutModal) {
              showTimeoutModal = true;
              // TODO: 弹出登录超时的弹框
            }
            return;
          }

          // 许可证到期
          if (['LICENCE_EXPIRED', 'LICENCE_ERROR'].includes(code)) {
            notification.error({ message });
            if (!url.includes('login')) {
              history.push('/login');
            }
            return;
          }

          // 状态码过滤
          switch (code) {
            case '401':
            case '403':
            case '406':
              if (isIframeEnv) {
                return window.parent.postMessage(
                  JSON.stringify({
                    type: 'big_token_expired',
                  }),
                  '*',
                );
              }
              const preHash = localStorage.getItem(cookie.REDIRECT_URL);
              if (!preHash) {
                notification.error({ message });
                // 跳转登录前，先把当前的url存在localStorage中
                saveNoAuthUrl();
              }
              history.push('/login');
              return;
            default:
              break;
          }

          const isRelationFirstFetch =
            firstFetch && ignoreMsgCode.includes(code);

          // 处理请求是否成功
          if (!success && !url.includes('login') && !isRelationFirstFetch) {
            // 血缘没有找到的情况
            if (ignoreMsgCode.includes(code)) {
              notification.info({ message: '该节点无更多血缘' });
              // notification.open({
              //   duration: 1.5,
              //   message: '该节点无更多血缘',
              //   className: styles.notification,
              //   placement: 'bottomRight',
              // });

              reject(resBody);
              return;
            }

            if (!silent) {
              // 静默错误模式下，不显示错误信息
              notification.error({ message: `${message || '系统错误'}` });
              // notification.error({
              //   message: resBody.errorMsg || '请求失败',
              //   description: message || '系统错误',
              //   style: {
              //     maxHeight: 500,
              //     overflow: 'auto',
              //   },
              // });
            }
            reject(resBody);
            return;
          }
          resolve(data);
        }
      },
      (err) => {
        if (!silent) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const noAuthCode = 401;
          if (err?.response?.status === noAuthCode) {
            if (isIframeEnv) {
              return window.parent.postMessage(
                JSON.stringify({
                  type: 'big_token_expired',
                }),
                '*',
              );
            }

            const preHash = localStorage.getItem(cookie.REDIRECT_URL);
            const { hash } = window.location;
            if (!preHash && !hash.includes('/login')) {
              notification.error({ message: '未登录' });

              // 跳转登录前，先把当前的url存在localStorage中
              saveNoAuthUrl();
            }
            history.push('/login');
          } else if (axios.isCancel(err)) {
            console.error('Request canceled', err);
          } else {
            notification.error({ message: '请求异常' });
            // notification.error({
            //   message: '请求异常',
            // });
          }
        }
        reject(err);
      },
    );
  });
}

export function post<T>(
  url: string,
  data: TReqData = {},
  opt: Partial<IRequestOpt> = {},
  firstFetch: boolean = false,
): Promise<T> {
  return ajax(
    url,
    {
      ...opt,
      data,
      method: 'POST',
    },
    firstFetch,
  );
}

export function put<T>(
  url: string,
  data: TReqData = {},
  opt: Partial<IRequestOpt> = {},
): Promise<T> {
  return ajax(url, {
    ...opt,
    data,
    method: 'PUT',
  });
}

export function get<T>(
  url: string,
  data: TReqData = {},
  opt: Partial<IRequestOpt> = {},
): Promise<T> {
  let finalUrl = url;
  if (url.includes('?')) {
    throw new Error('参数请在data中传入');
  }
  const { type, queryKey } = opt;
  // 对于所有的代码添加workspaceId
  const newData = data || {};
  if (newData && _.size(newData)) {
    // const reqData = parseReqData(newData);
    finalUrl = `${url}?${qs.stringify(newData)}`;
  }
  if (!type) {
    return ajax(finalUrl, { ...opt, method: 'GET' });
  }

  if (!queryKey) {
    throw new Error(`请求${url}获取latest数据时，未传入queryKey`);
  }

  const currentQueryId = new Date().getTime();
  queryObj[queryKey] = currentQueryId;
  return new Promise((resolve, reject) => {
    ajax(finalUrl, { ...opt, method: 'GET' })
      .then((resultData) => {
        if (currentQueryId !== queryObj[queryKey]) {
          return;
        }
        resolve(resultData as T);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

type TReqData = object;

export interface IRequestOpt {
  method: Method;
  data?: object;
  headers?: { [key: string]: string };
  type?: TRequestTypes;
  queryKey?: string;
  silent?: boolean; // 接受到错误信息后不展示
}

type TRequestTypes = 'latest';

/**
 * 去除值为undefined的key，深度遍历
 * @param data object数据
 * @returns object数据
 */
export function removeUndefinedDeeplyForObject(data: object): object {
  const res: { [key: string]: unknown } = {};
  _.forIn(data, (val, key) => {
    if (val === undefined) {
      return;
    }
    if (typeof val === 'object' && val !== null) {
      res[key] = removeUndefinedDeeplyForObject(val);
    } else {
      res[key] = val;
    }
  });
  return res;
}
