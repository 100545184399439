export const common = {
  owner: '负责人',
  ownerEmpty: '暂无负责人',
  updateTime: '编辑时间',
  createTime: '创建时间',
  finishTime: '完成时间',
  editText: '编辑基础信息',
  view: '查看',
  viewText: '查看基础信息',
  delete: '删除',
  remove: '移除',
  searchPlaceholder: '搜索',
  inputPlaceholder: '请输入',
  selectPlaceholder: '请选择',
  name: '名称',
  description: '描述',
  descriptionEmpty: '暂无描述',
  allText: '全部',
  statusTextSuccess: '已完成',
  statusTextFail: '失败',
  statusTextPending: '运行中',
  okText: '确定',
  cancelText: '取消',
  assets: '资产',
  closeText: '关闭',
  dataEmptyText: '暂无数据',
  operate: '操作',
  successMessage: '操作成功',
  failMessage: '操作失败',
  table: '表',
  column: '列',
  field: '字段',
  copy: '复制',

  lineageBoth: '上下游',
  lineageUpstream: '上游',
  lineageDownstream: '下游',

  equalText: '等于',
  notEqualText: '不等于',
  containText: '包含',
  notContainText: '不包含',
  prefixContainText: '前缀包含',
  postfixContainText: '后缀包含',

  tableName: '表名称',
  schemaName: 'schema名称',
  database: '数据库',
  databaseName: '数据库名称',

  requestError: '接口异常',
  //资产类型
  assetType: '资产类型',
  tag: '标签',

  order: '序号',
  dataSource: '数据源',
  edit: '修改',
  add: '添加',

  baseInfo: '基础信息',

  generatedByAI: '以下信息由大模型生成',

  listNoMore: '暂时没有更多了',
};
