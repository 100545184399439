import { Button, Input } from '@aloudata/aloudata-design';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';
import SQLGraph from '@/components/TaskLineageView/model/SQLGraph';
import { SQLGraphAtom } from '@/components/TaskLineageView/atoms/sqlGraphAtom';
import { useMount } from 'ahooks';
import { SearchLine } from '@aloudata/icons-react';
import { ReactComponent as ChevronLeftLine } from '@/assets/chevron-left-line.svg';
import { ReactComponent as ChevronRightLine } from '@/assets/chevron-right-line.svg';

const ToolbarSearch = () => {
  const sqlGraph: SQLGraph = useRecoilValue(SQLGraphAtom);

  const [total, setTotal] = useState(0);
  const [curr, setCurr] = useState(1);

  useMount(() => {
    const handleResize = () => {
      // 重新设置画布大小
      sqlGraph.svgWrapperResize();
    };

    document.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      document.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  });

  // const onShowRelatedTable = (e: CheckboxChangeEvent) => {
  //   sqlGraph.updateShowRelatedTable(e.target.checked);
  // };
  //
  // const showFullName = (e: CheckboxChangeEvent) => {
  //   if (e.target.checked) {
  //     sqlGraph.updateShowFullName(true);
  //   } else {
  //     sqlGraph.updateShowFullName(false);
  //   }
  // };

  const search = (e: any) => {
    const ipt = e.target.value as string;
    const columnsTotal = sqlGraph.searchColumn(ipt.toLowerCase()) || 0;
    if (columnsTotal > 0) {
      setCurr(1); // 如果搜出来的总数大于0 则从第一个开始定位
    }

    setTotal(columnsTotal);
  };

  const highLightToNext = () => {
    const currIndex = sqlGraph.subViewGraph.highlightToNext();
    setCurr(currIndex + 1);
  };

  const highLightToPrev = () => {
    const currIndex = sqlGraph.subViewGraph.highLightToPrev();
    setCurr(currIndex + 1);
  };

  return (
    <>
      <div
        data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-div-1"
        className={styles.searchColumn}
      >
        <Input
          prefix={
            <SearchLine data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-SearchLine-2" />
          }
          placeholder={'输入表列名称定位'}
          className={styles.searchInput}
          onPressEnter={search}
          allowClear
        />
        <span
          data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-span-3"
          style={{ display: total > 0 ? 'inline-block' : 'none' }}
        >
          <span
            data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-span-4"
            style={{ margin: '0 10px' }}
          >
            {curr} / {total}
          </span>
          <Button
            data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-Button-6"
            type="secondary"
            onClick={highLightToPrev}
            disabled={curr === 1}
            size="small"
            icon={<ChevronLeftLine />}
          ></Button>
          <Button
            data-testid="components-TaskLineageView2-components-Toolbar-ToolbarRight-index-Button-7"
            type="secondary"
            onClick={highLightToNext}
            className={styles.nextBtn}
            disabled={curr === total}
            size="small"
            icon={<ChevronRightLine />}
          ></Button>
        </span>
      </div>
    </>
  );
};

export default ToolbarSearch;
