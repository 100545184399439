import { ReactComponent as TableDuotone } from '@/assets/table-24px.svg';
import { ReactComponent as SqlFileLine } from '@/assets/sql-file-line.svg';
import { ReactComponent as ColumnLine } from '@/assets/structure-column-line.svg';
import { EOverviewType } from '@/typings/overview';

const OverviewTypeIcon = ({
  type,
  ...rest
}: {
  type: EOverviewType;
  color?: string;
}) => {
  switch (type) {
    case EOverviewType.TABLE:
      return <TableDuotone {...rest} />;
    case EOverviewType.COLUMN:
      return <ColumnLine {...rest} />;
    case EOverviewType.TASK:
      return <SqlFileLine color={'#fff'} {...rest} />;
    case EOverviewType.TEMP_TABLE:
      return <TableDuotone color={'#fff'} {...rest} />;
    case EOverviewType.TEMP_COLUMN:
      return <ColumnLine {...rest} />;
    default:
      return null;
  }
};

export default OverviewTypeIcon;
