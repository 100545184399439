import { EDirection } from '@/typings';
import ColumnView from '../view/ColumnView';
import TableView from '../view/TableView';

export enum EGraphMode {
  EXPLORE = 'EXPLORE', // 探索模
  NORMAL = 'NORMAL', // 普通模式
}

export interface ITaskSqlListItem {
  inputEntities: string[];
  outputEntities: string[];
  sql: string;
  copySql: string;
  outputEntityType: string;
  inputEntityType: string;
  sqlId: string;
  sqlOrder: number;
  relationType: string;
}

export interface IOverviewState {
  mode: EGraphMode;
  taskSqlList: ITaskSqlListItem[];
  activeSqlIds: string[];
  exploreDirection: EDirection | null;
  exploreAssetId?: string;
  type?: string; // 扩展类型 new
  nodeView?: TableView | ColumnView; // new
}
