import styles from './index.less';
import errImg from '@/assets/error.svg';

const ErrorBoundaryContent = () => {
  return (
    <div className={styles.errorWrap}>
      <img src={errImg} alt="" />
      <div>页面出错了</div>
    </div>
  );
};

export default ErrorBoundaryContent;
