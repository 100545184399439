import { ITaskListVersion, ITaskOverviewRes } from '@/typings/task';
import { atom } from 'recoil';

// table detail
export const taskOverviewInitialState: ITaskOverviewRes = {
  taskName: '', // 任务名
  taskType: '', // 任务类型
  assetPath: '', // 路径
  taskId: '', // 任务id
  taskGuid: '', // 任务guid
  updateTime: -1, // 数据更新时间
  content: '', // 脚本内容
  owners: [
    {
      userId: '',
      userNick: '',
      userAvatar: '',
    },
  ], // 负责人
  status: '', // 资产状态
  description: '', // 任务描述
  attributes: [], // 自定义属性
};
export const taskOverviewAtom = atom<ITaskOverviewRes>({
  key: 'taskOverviewAtom',
  default: taskOverviewInitialState,
});

// task history
const taskListVersionInitialState: ITaskListVersion[] = [];
export const taskListVersionAtom = atom<ITaskListVersion[]>({
  key: 'taskListVersionAtom',
  default: taskListVersionInitialState,
});
