import { vec2 } from 'gl-matrix';
import { IProperties } from '../typings';
import { EEntityType } from '@/components/TaskLineageView/components/type';
import SQLGraph from './SQLGraph';

interface ISize {
  width: number;
  height: number;
}

interface IProps {
  context: SQLGraph;
  name: string;
  typeCode: string;
  index: string;
  width: number;
  height: number;
  x: number;
  y: number;
}
export default abstract class BaseNode<T = null, P = IProperties> {
  context: SQLGraph;

  index!: string;

  name: string;

  typeCode: string;

  entityType: EEntityType;

  private width?: number;

  private height?: number;

  private x?: number;

  private y?: number;

  visible: boolean = true;

  isCorrelation: boolean = false;

  highlight?: boolean;

  expandFromOperator: boolean = false;

  // 用于存储节点的属性
  _data_?: P;

  // 用于存储节点的视图
  view!: T;

  protected constructor(props: IProps) {
    this.context = props.context;
    this.typeCode = props.typeCode;
    this.name = props.name;
    this.index = props.index;
    this.setSize({ width: props.width, height: props.height });
    this.setPos([props.x, props.y]);
  }

  getSize() {
    return [this.width, this.height];
  }

  setSize(size: Partial<ISize>) {
    if (size.width !== undefined) {
      this.width = size.width;
    }
    if (size.height !== undefined) {
      this.height = size.height;
    }
  }

  setWidth(width: number) {
    this.width = width;
  }

  getWidth() {
    return this.width;
  }

  setHeight(height: number) {
    this.height = height;
  }

  getHeight() {
    return this.height;
  }

  getPos() {
    return [this.x, this.y];
  }

  setPos(pos: Partial<vec2>) {
    if (pos[0] !== undefined) {
      this.x = pos[0];
    }
    if (pos[1] !== undefined) {
      this.y = pos[1];
    }
  }

  refresh(data: this) {
    Object.assign(this, data);
  }

  bindView(view: T) {
    this.view = view;
  }
}
