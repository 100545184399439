import { post } from '@/utils/request';
import { API_SERVER } from '@/services';

interface IReportData {
  clickId: string;
  clickEvent: string;
  clickTime: number;
  traceId: string;
  userUuid: string;
  extData?: object;
}

// pv/uv上报
export const reportData = (data: IReportData) => {
  return post(`${API_SERVER}/click/eventReport`, data, {
    silent: true,
  });
};
