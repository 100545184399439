import { EReportTag } from '@/constants/report';
import { getQueryParam, getUniqId } from '@/utils/index';
import { reportData } from '@/services/assetAnalysis';

/**
 * 上报
 * @param tag 唯一标识
 * @param data 其他附加数据
 */
export const userUuidKey = 'bigmeta_userUuid';
export const userNameKey = 'bigmeta_userName';
export const traceIdKey = 'bigmeta_traceId';

export function parseUserInfoFromUrl() {
  // 解析 url 中是否包含 user_code 和 user_name 信息
  const currentURL = window.location.href;
  const user_code = getQueryParam(currentURL, 'user_code');
  const user_name = getQueryParam(currentURL, 'user_name');

  // url 中包含 user_code， 则将 user_code 存入 localStorage，不管之前是否存过
  if (user_code) {
    localStorage.setItem(userUuidKey, user_code);
  }
  // 同理
  if (user_name) {
    localStorage.setItem(userNameKey, user_name);
  }
}

export function logReport(tag: EReportTag, data: Record<string, any> = {}) {
  let userUuid = localStorage.getItem(userUuidKey);
  const traceData = localStorage.getItem(traceIdKey);
  let traceId = getUniqId();
  if (!userUuid) {
    userUuid = getUniqId();
    localStorage.setItem(userUuidKey, userUuid);
  }
  if (traceData) {
    try {
      const parseData: { time: number; id: string } = JSON.parse(traceData);
      // 如果距离上次上报超过 10 分钟，重新生成 traceId
      const TEN_MINUTES = 6000000;
      if (Date.now() - parseData.time < TEN_MINUTES) {
        traceId = parseData.id || getUniqId();
      }
    } catch (e) {
      traceId = getUniqId();
    }
  }
  localStorage.setItem(
    traceIdKey,
    JSON.stringify({
      id: traceId,
      time: Date.now(),
    }),
  );

  const currentURL = window.location.href;
  const from = getQueryParam(currentURL, 'from');
  const source_event = getQueryParam(currentURL, 'source_event');
  if (from) data.from = from;
  if (source_event) data.source_event = source_event;

  reportData({
    clickEvent: tag,
    clickId: getUniqId(),
    clickTime: Date.now(),
    traceId,
    userUuid,
    extData: {
      ...data,
      // 从 url 中解析出来的信息
      user_code: localStorage.getItem(userUuidKey),
      user_name: localStorage.getItem(userNameKey),
    },
  });
}
