import { IFilter, mapDashType } from '@/components/TaskLineageView/utils/map';
import SQLGraph, {
  TNodeMap,
} from '@/components/TaskLineageView/model/SQLGraph';
import Edge from '@/components/TaskLineageView/model/Edge';
import { EEdgeType } from '@/services/lineage/type';

function checkCorrelation(
  edge: Edge,
  isOnlyShowRelatedTable: boolean,
  nodeMap: TNodeMap,
) {
  const src = nodeMap.get(edge.srcId);
  const dst = nodeMap.get(edge.dstId);
  let srcVisible = true;
  let dstVisible = true;
  if (isOnlyShowRelatedTable) {
    srcVisible = srcVisible && src?.isCorrelation;
    dstVisible = dstVisible && dst?.isCorrelation;
    return srcVisible && dstVisible;
  } else {
    return srcVisible && dstVisible;
  }
}

export function getEdgeVisible(context: SQLGraph, edge: Edge) {
  const { dstId, srcId } = edge;

  const src = context.viewGraph.currentNodeMap.get(srcId);
  const dst = context.viewGraph.currentNodeMap.get(dstId);
  if (!src || !dst) return false;

  // 当前状态为收起 且边的起始点和终点都是可收起的表时，不渲染该边
  // if (src.fold && dst.fold && context.viewGraph.getFoldStatus(foldTableId)) {
  //   return false;
  // }

  let visible = mapDashType(edge, context.viewGraph.edgeDashVisible);

  // 起点终点都是相关项
  visible =
    visible &&
    checkCorrelation(
      edge,
      context.viewGraph.isOnlyShowRelatedTable,
      context.viewGraph.currentNodeMap,
    );

  return visible;
}

// 判断edge在布局中是否生效，此处主要是排除被规则隐藏掉的edge
export function isEdgeEffectiveInFilter(
  filter: IFilter,
  edge: Edge,
  nodeMap: TNodeMap,
) {
  const { edgeType, isOnlyShowRelatedTable } = filter;
  let visible = mapDashType(edge, edgeType);
  // 起点终点都是相关项
  visible = visible && checkCorrelation(edge, isOnlyShowRelatedTable, nodeMap);
  return visible;
}

export function isTableEdge(edgeType: EEdgeType) {
  return edgeType === EEdgeType.T_T;
}

export function mapColumnEdge(edgeType: EEdgeType) {
  return edgeType === EEdgeType.C_C;
}

// export function isOperatorEdge(edgeType: EEdgeType) {
//   return (
//     edgeType === EEdgeType.T_OPP ||
//     edgeType === EEdgeType.C_OP ||
//     edgeType === EEdgeType.OP_C ||
//     edgeType === EEdgeType.VC_OP ||
//     edgeType === EEdgeType.OP_VC ||
//     edgeType === EEdgeType.OPP_T ||
//     edgeType === EEdgeType.OPP_VCP ||
//     edgeType === EEdgeType.VCP_OPP
//   );
// }
